import React, { useEffect, useState } from 'react';
import Squares from './Squares';

const BackgroundEffect = ({ children, direction = "diagonal", speed = 0.5, pattern = "diagonal" }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  // 檢測當前主題模式
  useEffect(() => {
    const checkDarkMode = () => {
      const isDark = document.documentElement.classList.contains('dark');
      setIsDarkMode(isDark);
    };
    
    // 初始檢查
    checkDarkMode();
    
    // 監聽主題變化
    const observer = new MutationObserver(checkDarkMode);
    observer.observe(document.documentElement, { 
      attributes: true, 
      attributeFilter: ['class'] 
    });
    
    return () => observer.disconnect();
  }, []);

  return (
    <div className="relative min-h-screen w-full overflow-hidden">
      {/* 背景方格效果 */}
      <div className="fixed inset-0 z-0">
        <Squares 
          direction={direction} 
          speed={speed} 
          pattern={pattern}
          borderColor="rgba(255, 255, 255, 0.1)" 
          hoverFillColor="rgba(255, 255, 255, 0.05)"
          squareSize={50}
        />
      </div>
      
      {/* 淺色主題優化遮罩 - 只在非暗黑模式下顯示 */}
      {!isDarkMode && (
        <div className="fixed inset-0 z-0 bg-gradient-to-b from-amber-50/30 to-zinc-100/50 backdrop-brightness-95 backdrop-contrast-[0.96] pointer-events-none"></div>
      )}
      
      {/* 内容区域 */}
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default BackgroundEffect; 