import React, { useEffect, useState, useCallback, useRef } from 'react';
import styles from './ClickSpark.module.css';

// 火花粒子组件
const SparkItem = ({ size, color, position, angle, distance }) => {
  // 计算终点位置
  const translateX = Math.cos(angle) * distance;
  const translateY = Math.sin(angle) * distance;

  return (
    <div
      className={styles.sparkItem}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: color,
        left: position.x,
        top: position.y,
        '--translate-x': `${translateX}px`,
        '--translate-y': `${translateY}px`,
      }}
    />
  );
};

// 点击火花效果组件
const ClickSpark = () => {
  const [sparks, setSparks] = useState([]);
  const [sparkId, setSparkId] = useState(0);

  // 使用 useRef 來存儲 DOM 元素
  const sparkContainerRef = useRef(null);
  
  // 創建火花效果 - 使用useCallback包装以避免在每次渲染時重新創建
  const createSparks = useCallback((e) => {
    console.log('創建火花效果', e.clientX, e.clientY); // 調試日誌
    const container = sparkContainerRef.current;
    if (!container) return;
    
    // 創建8-12個隨機火花
    const sparkCount = Math.floor(Math.random() * 5) + 8;
    
    const position = { x: e.clientX, y: e.clientY };
    const newSparks = [];
    
    for (let i = 0; i < sparkCount; i++) {
      const size = Math.floor(Math.random() * 4) + 2; // 2-6px
      const angle = Math.random() * Math.PI * 2; // 随机角度
      const distance = Math.random() * 60 + 20; // 20-80px
      
      // 随机选择基础颜色
      const baseColors = [
        'rgb(255, 180, 0)', // 金色
        'rgb(255, 120, 0)', // 橙色
        'rgb(255, 230, 0)', // 黄色
        'rgb(255, 255, 180)', // 淡黄色
      ];
      
      const color = baseColors[Math.floor(Math.random() * baseColors.length)];
      
      newSparks.push({
        id: sparkId + i,
        position,
        size,
        color,
        angle,
        distance,
      });
    }
    
    setSparks((prevSparks) => [...prevSparks, ...newSparks]);
    setSparkId((prevId) => prevId + sparkCount);
    
    // 清理旧的火花
    setTimeout(() => {
      setSparks((prevSparks) => prevSparks.slice(sparkCount));
    }, 600);
  }, [sparkId]); // 添加sparkId作为依赖项

  useEffect(() => {
    // 添加全局点击事件监听器
    document.addEventListener('click', createSparks);
    
    // 清理函数
    return () => {
      document.removeEventListener('click', createSparks);
    };
  }, [createSparks]); // 使用createSparks作为依赖项

  return (
    <div className={styles.sparkContainer} ref={sparkContainerRef}>
      {sparks.map((spark) => (
        <SparkItem key={spark.id} {...spark} />
      ))}
    </div>
  );
};

export default ClickSpark; 