import React, { useState, useEffect, useCallback } from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import { toast } from 'react-hot-toast';
import { adminApi } from '../../utils/api';

function ProjectManagement() {
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    nationality: '',
    height: '',
    weight: '',
    age: '',
    cupSize: '',
    breastSize: '',
    price: '',
    imageUrl: '',
    area: '',
    city: '',
    serviceType: '',
    status: '可預約',
    isHot: false,
    servicePackages: '',
    serviceItems: '',
    additionalServices: '',
    packageDeals: '',
    notes: '',
    imageUrls: [],
    videoUrls: []
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    cupSize: '',
    breastSize: '',
    nationality: '',
    minPrice: '',
    maxPrice: '',
    area: '',
    city: '',
    serviceType: '',
    status: ''
  });
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [failedImages, setFailedImages] = useState(new Set());
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deletingProjectId, setDeletingProjectId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, filters]);

  const fetchProjects = async () => {
    try {
      console.log('正在獲取項目列表...');
      const { data } = await adminApi.get('/projects');
      console.log('獲取的項目列表:', data);
      
      // 按國籍分組排序
      const groupedByNationality = {};
      const nationalityOrder = [
        '台灣', '港澳', '越南', '印尼', '日本', '韓國', 
        '馬來', '泰國', '馬泰', '美國', '菲律賓', '柬埔寨'
      ];
      
      // 初始化分組
      nationalityOrder.forEach(nat => {
        groupedByNationality[nat] = [];
      });
      
      // 將項目按國籍分組
      data.forEach(project => {
        const nationality = project.nationality || '其他';
        if (!groupedByNationality[nationality]) {
          groupedByNationality[nationality] = [];
        }
        groupedByNationality[nationality].push(project);
      });
      
      // 將分組後的項目合併為一個數組
      const sortedProjects = [];
      nationalityOrder.forEach(nationality => {
        if (groupedByNationality[nationality] && groupedByNationality[nationality].length > 0) {
          sortedProjects.push(...groupedByNationality[nationality]);
        }
      });
      
      // 處理可能不在預定義順序中的國籍
      Object.keys(groupedByNationality).forEach(nationality => {
        if (!nationalityOrder.includes(nationality) && groupedByNationality[nationality].length > 0) {
          sortedProjects.push(...groupedByNationality[nationality]);
        }
      });
      
      setProjects(sortedProjects);
    } catch (error) {
      console.error('獲取項目失敗:', error);
      setError(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loadingToast = toast.loading('正在保存...');
    try {
      setError('');
      setSuccessMessage('');

      // 确保所有字段都有正确的类型和默认值
      const submitData = {
        ...formData,
        height: Number(formData.height),
        weight: Number(formData.weight),
        age: Number(formData.age),
        price: Number(formData.price),
        isHot: Boolean(formData.isHot),
        city: formData.city || '', // 确保城市字段被包含，即使是空字符串
        imageUrls: formData.imageUrls.filter(url => url.trim() !== ''),
        videoUrls: formData.videoUrls.filter(url => url.trim() !== '')
      };

      delete submitData.imageUrl;

      console.log('提交的完整數據:', submitData);

      let response;
      if (editingProject) {
        response = await adminApi.put(`/projects/${editingProject._id}`, submitData);
      } else {
        response = await adminApi.post('/projects', submitData);
      }

      console.log('服務器返回的數據:', response.data);

      toast.success('保存成功！', { id: loadingToast });
      await fetchProjects();
      
      setTimeout(() => {
        setIsModalOpen(false);
        setEditingProject(null);
        setSuccessMessage('');
        setFormData({
          name: '',
          nationality: '',
          height: '',
          weight: '',
          age: '',
          cupSize: '',
          breastSize: '',
          price: '',
          area: '',
          city: '', // 重置城市字段
          serviceType: '',
          status: '可預約',
          isHot: false,
          servicePackages: '',
          serviceItems: '',
          additionalServices: '',
          packageDeals: '',
          notes: '',
          imageUrls: [],
          videoUrls: []
        });
      }, 1500);

    } catch (error) {
      console.error('保存失敗:', error);
      toast.error(error.message || '保存失敗', { id: loadingToast });
      setError(error.message);
    }
  };

  const handleDeleteClick = useCallback((projectId) => {
    setDeletingProjectId(projectId);
    setIsConfirmOpen(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!deletingProjectId) return;

    const loadingToast = toast.loading('正在刪除...');
    try {
      await adminApi.delete(`/projects/${deletingProjectId}`);
      toast.success('刪除成功！', { id: loadingToast });
      await fetchProjects();
    } catch (error) {
      toast.error(error.message || '刪除失敗', { id: loadingToast });
      setError(error.message);
    } finally {
      setIsConfirmOpen(false);
      setDeletingProjectId(null);
    }
  }, [deletingProjectId, fetchProjects]);

  // 添加鍵盤事件監聽
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (isConfirmOpen && e.key === 'Enter') {
        handleDelete();
      }
    };

    if (isConfirmOpen) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isConfirmOpen, handleDelete]);

  const handleEditDetail = (project) => {
    console.log('正在編輯的項目:', project);
    setEditingProject(project);
    // 確保設置所有必要的表單字段
    setFormData({
      // 基本信息
      name: project.name || '',
      nationality: project.nationality || '',
      height: project.height || '',
      weight: project.weight || '',
      age: project.age || '',
      cupSize: project.cupSize || '',
      breastSize: project.breastSize || '',
      price: project.price || '',
      status: project.status || '可預約',
      
      // 地理信息
      area: project.area || '',
      city: project.city || '',
      serviceType: project.serviceType || '',
      
      // 详细信息
      servicePackages: project.servicePackages || '',
      serviceItems: project.serviceItems || '',
      additionalServices: project.additionalServices || '',
      packageDeals: project.packageDeals || '',
      notes: project.notes || '',
      isHot: project.isHot || false,
      
      // 媒体信息
      imageUrls: project.imageUrls || [],
      videoUrls: project.videoUrls || []
    });
    
    console.log('設置的完整表單數據:', {
      name: project.name,
      nationality: project.nationality,
      height: project.height,
      weight: project.weight,
      age: project.age,
      cupSize: project.cupSize,
      breastSize: project.breastSize,
      price: project.price,
      status: project.status,
      area: project.area,
      city: project.city,
      serviceType: project.serviceType,
      servicePackages: project.servicePackages,
      serviceItems: project.serviceItems,
      additionalServices: project.additionalServices,
      packageDeals: project.packageDeals,
      notes: project.notes,
      isHot: project.isHot,
      imageUrls: project.imageUrls,
      videoUrls: project.videoUrls
    });
    
    setIsDetailModalOpen(true);
  };

  const handleDetailSubmit = async (e) => {
    e.preventDefault();
    const loadingToast = toast.loading('正在保存...');
    try {
      setError('');
      setSuccessMessage('');

      // 获取当前完整的项目数据
      const currentProject = projects.find(p => p._id === editingProject._id);
      if (!currentProject) {
        throw new Error('找不到项目数据');
      }

      // 创建一个包含所有必填字段的更新对象
      const updateData = {
        // 保留原有必填字段数据
        name: currentProject.name,
        nationality: currentProject.nationality,
        height: currentProject.height,
        weight: currentProject.weight,
        age: currentProject.age,
        cupSize: currentProject.cupSize,
        breastSize: currentProject.breastSize,
        price: currentProject.price,
        status: currentProject.status,
        isHot: currentProject.isHot,
        
        // 更新的字段
        servicePackages: formData.servicePackages || '',
        serviceItems: formData.serviceItems || '',
        additionalServices: formData.additionalServices || '',
        packageDeals: formData.packageDeals || '',
        notes: formData.notes || '',
        city: formData.city || currentProject.city || '',
        area: formData.area || currentProject.area || '',
        serviceType: formData.serviceType || currentProject.serviceType || '',
        imageUrls: formData.imageUrls || currentProject.imageUrls || [],
        videoUrls: formData.videoUrls || currentProject.videoUrls || []
      };

      console.log('發送到服務器的完整數據:', updateData);

      const response = await adminApi.put(`/projects/${editingProject._id}`, updateData);

      // 直接使用 response.data，不需要再次 await
      const data = response.data;
      console.log('服務器返回的數據:', data);
      
      toast.success('更新成功！', { id: loadingToast });
      await fetchProjects(); // 重新獲取列表
      
      setTimeout(() => {
        setIsDetailModalOpen(false);
        setEditingProject(null);
        setSuccessMessage('');
      }, 1500);
    } catch (error) {
      console.error('更新失敗:', error);
      toast.error(error.message || '更新失敗', { id: loadingToast });
      setError(error.message);
    }
  };

  const filteredProjects = projects.filter(project => {
    const matchesSearch = project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.nationality.toLowerCase().includes(searchTerm.toLowerCase());
      
    const matchesCupSize = !filters.cupSize || project.cupSize === filters.cupSize;
    const matchesBreastSize = !filters.breastSize || project.breastSize === filters.breastSize;
    const matchesNationality = !filters.nationality || project.nationality === filters.nationality;
    const matchesPrice = (!filters.minPrice || project.price >= Number(filters.minPrice)) &&
      (!filters.maxPrice || project.price <= Number(filters.maxPrice));
    const matchesArea = !filters.area || project.area === filters.area;
    const matchesCity = !filters.city || project.city === filters.city;
    const matchesServiceType = !filters.serviceType || project.serviceType === filters.serviceType;
    const matchesStatus = !filters.status || project.status === filters.status;

    return matchesSearch && matchesCupSize && matchesBreastSize && 
      matchesNationality && matchesPrice && matchesArea && matchesCity && matchesServiceType && matchesStatus;
  });

  // 計算總頁數
  const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);
  
  // 獲取當前頁面的項目
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstItem, indexOfLastItem);
  
  // 處理頁面變更
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // 滾動到頁面頂部
    window.scrollTo(0, 0);
  };

  // 獲取要顯示的頁碼
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // 最多顯示的頁碼數量
    
    if (totalPages <= maxPagesToShow) {
      // 如果總頁數小於等於最大顯示數量，顯示所有頁碼
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // 否則，顯示當前頁附近的頁碼
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      let endPage = startPage + maxPagesToShow - 1;
      
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }
      
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      
      // 添加省略號
      if (startPage > 1) {
        pageNumbers.unshift('...');
        pageNumbers.unshift(1);
      }
      
      if (endPage < totalPages) {
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }
    
    return pageNumbers;
  };

  return (
    <AdminLayout>
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">作品管理</h1>
            <button 
              onClick={() => setIsModalOpen(true)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              新增作品
            </button>
          </div>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
            </div>
          )}

          <div className="mb-6">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <input
                type="text"
                placeholder="搜索名字或國籍..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="px-4 py-2 border rounded-lg"
              />
              <div className="md:col-span-2 flex justify-end">
                <button
                  onClick={() => setIsFilterExpanded(!isFilterExpanded)}
                  className="px-4 py-2 bg-blue-100 text-blue-700 rounded-lg hover:bg-blue-200 transition-colors flex items-center"
                >
                  {isFilterExpanded ? '收起篩選' : '進階搜尋'} 
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className={`h-5 w-5 ml-1 transition-transform ${isFilterExpanded ? 'rotate-180' : ''}`} 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </div>
            </div>
            
            {isFilterExpanded && (
              <>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <select
                    value={filters.cupSize}
                    onChange={(e) => setFilters({...filters, cupSize: e.target.value})}
                    className="px-4 py-2 border rounded-lg"
                  >
                    <option value="">胸型 (全部)</option>
                    <option value="真奶">真奶</option>
                    <option value="人工">人工</option>
                    <option value="粉嫩">粉嫩</option>
                    <option value="雪白">雪白</option>
                    <option value="極品">極品</option>
                    <option value="柔軟">柔軟</option>
                    <option value="粉色">粉色</option>
                    <option value="超胸">超胸</option>
                    <option value="Q彈">Q彈</option>
                  </select>
                  <select
                    value={filters.breastSize}
                    onChange={(e) => setFilters({...filters, breastSize: e.target.value})}
                    className="px-4 py-2 border rounded-lg"
                  >
                    <option value="">罩杯 (全部)</option>
                    {['A', 'B', 'C', 'D', 'E', 'F'].map(size => (
                      <option key={size} value={size}>{size} Cup</option>
                    ))}
                  </select>
                  <div className="flex gap-2">
                    <input
                      type="number"
                      placeholder="最低價格"
                      value={filters.minPrice}
                      onChange={(e) => setFilters({...filters, minPrice: e.target.value})}
                      className="px-4 py-2 border rounded-lg w-full"
                    />
                    <input
                      type="number"
                      placeholder="最高價格"
                      value={filters.maxPrice}
                      onChange={(e) => setFilters({...filters, maxPrice: e.target.value})}
                      className="px-4 py-2 border rounded-lg w-full"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <select
                    value={filters.nationality}
                    onChange={(e) => setFilters({...filters, nationality: e.target.value})}
                    className="px-4 py-2 border rounded-lg"
                  >
                    <option value="">國籍 (全部)</option>
                    <option value="台灣">台灣</option>
                    <option value="港澳">港澳</option>
                    <option value="越南">越南</option>
                    <option value="印尼">印尼</option>
                    <option value="日本">日本</option>
                    <option value="韓國">韓國</option>
                    <option value="馬來">馬來</option>
                    <option value="泰國">泰國</option>
                    <option value="馬泰">馬泰</option>
                    <option value="美國">美國</option>
                    <option value="菲律賓">菲律賓</option>
                    <option value="柬埔寨">柬埔寨</option>
                  </select>
                  <select
                    value={filters.status}
                    onChange={(e) => setFilters({...filters, status: e.target.value})}
                    className="px-4 py-2 border rounded-lg"
                  >
                    <option value="">狀態 (全部)</option>
                    <option value="可預約">可預約</option>
                    <option value="今日約滿">今日約滿</option>
                    <option value="今日休息">今日休息</option>
                    <option value="隱藏">隱藏</option>
                  </select>
                  <select
                    value={filters.area}
                    onChange={(e) => setFilters({...filters, area: e.target.value})}
                    className="px-4 py-2 border rounded-lg"
                  >
                    <option value="">地區 (全部)</option>
                    {[...new Set(projects.map(p => p.area))].sort().map(area => (
                      <option key={area} value={area}>{area}</option>
                    ))}
                  </select>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                  <select
                    value={filters.city}
                    onChange={(e) => setFilters({...filters, city: e.target.value})}
                    className="px-4 py-2 border rounded-lg"
                  >
                    <option value="">城市 (全部)</option>
                    {[...new Set(projects.map(p => p.city).filter(Boolean))].sort().map(city => (
                      <option key={city} value={city}>{city}</option>
                    ))}
                  </select>
                  <select
                    value={filters.serviceType}
                    onChange={(e) => setFilters({...filters, serviceType: e.target.value})}
                    className="px-4 py-2 border rounded-lg"
                  >
                    <option value="">類型 (全部)</option>
                    <option value="定點套房">定點套房</option>
                    <option value="汽旅外約">汽旅外約</option>
                    <option value="定點個工">定點個工</option>
                  </select>
                  <div className="flex justify-end">
                    <button
                      onClick={() => {
                        setSearchTerm('');
                        setFilters({
                          cupSize: '',
                          breastSize: '',
                          nationality: '',
                          minPrice: '',
                          maxPrice: '',
                          area: '',
                          city: '',
                          serviceType: '',
                          status: ''
                        });
                      }}
                      className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors w-full"
                    >
                      重置篩選
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full table-fixed">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-24">照片</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-32">名字/胸型</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-20">國籍</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-28">身高/體重</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-20">年紀</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-28">城市/地區</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-20">金額</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-24">狀態</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-32">操作</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentProjects.length > 0 ? (
                  <>
                    {currentProjects.map((project, index) => {
                      // 檢查是否需要顯示國籍標題
                      const showNationalityHeader = index === 0 || 
                        (index > 0 && project.nationality !== currentProjects[index - 1].nationality);
                      
                      // 計算該國籍在過濾後的項目中的數量
                      const nationalityCount = filteredProjects.filter(p => p.nationality === project.nationality).length;
                      
                      return (
                        <React.Fragment key={project._id}>
                          {showNationalityHeader && (
                            <tr className="bg-gray-100">
                              <td colSpan="9" className="px-6 py-3 text-left text-sm font-medium text-gray-700">
                                {project.nationality} ({nationalityCount} 位)
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {project.imageUrls && project.imageUrls.length > 0 ? (
                                <img 
                                  src={!failedImages.has(project.imageUrls[0]) ? project.imageUrls[0] : '/placeholder.png'} 
                                  alt={project.name}
                                  className="w-20 h-20 object-cover rounded"
                                  onError={(e) => {
                                    console.error('圖片載入失敗:', project.imageUrls[0]);
                                    setFailedImages(prev => new Set([...prev, project.imageUrls[0]]));
                                    e.preventDefault();
                                  }}
                                />
                              ) : (
                                <div className="w-20 h-20 bg-gray-200 rounded flex items-center justify-center">
                                  <span className="text-gray-500">無圖片</span>
                                </div>
                              )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="font-medium text-sm truncate max-w-[120px]">{project.name}</div>
                              <div className="flex flex-wrap gap-1 mt-1">
                                <span className={`px-2 py-1 rounded-full text-xs ${
                                  project.cupSize === '假奶' 
                                    ? 'bg-pink-100 text-pink-800' 
                                    : 'bg-green-100 text-green-800'
                                }`}>
                                  {project.cupSize}
                                </span>
                                <span className="px-2 py-1 bg-purple-100 text-purple-800 rounded-full text-xs">
                                  {project.breastSize} Cup
                                </span>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">{project.nationality}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              {project.height}cm / {project.weight}kg
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              {project.age > 0 ? `${project.age}歲` : '暫無'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">
                              {project.city ? `${project.city} / ` : ''}{project.area}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm">${project.price}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className={`px-2 py-1 rounded-full text-xs ${
                                project.status === '可預約' || project.status === '尚可預約' 
                                  ? 'bg-green-100 text-green-800'
                                  : project.status === '今日約滿'
                                  ? 'bg-yellow-100 text-yellow-800'
                                  : 'bg-red-100 text-red-800'
                              }`}>
                                {project.status}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex flex-wrap gap-2">
                                <button 
                                  onClick={() => {
                                    console.log('編輯的項目數據:', project);
                                    setEditingProject(project);
                                    setFormData({
                                      name: project.name || '',
                                      nationality: project.nationality || '',
                                      height: project.height || '',
                                      weight: project.weight || '',
                                      age: project.age || '',
                                      cupSize: project.cupSize || '',
                                      breastSize: project.breastSize || '',
                                      price: project.price || '',
                                      imageUrl: project.imageUrl || '',
                                      area: project.area || '',
                                      city: project.city || '',
                                      serviceType: project.serviceType || '',
                                      status: project.status || '可預約',
                                      isHot: project.isHot || false,
                                      imageUrls: project.imageUrls || [],
                                      videoUrls: project.videoUrls || []
                                    });
                                    setIsModalOpen(true);
                                  }}
                                  className="text-blue-600 hover:text-blue-900 text-sm"
                                >
                                  編輯
                                </button>
                                <button 
                                  onClick={() => handleEditDetail(project)}
                                  className="text-green-600 hover:text-green-900 text-sm"
                                >
                                  編輯詳情
                                </button>
                                <button 
                                  onClick={() => handleDeleteClick(project._id)}
                                  className="text-red-600 hover:text-red-900 text-sm"
                                >
                                  刪除
                                </button>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan="9" className="px-6 py-4 text-center text-sm text-gray-500">
                      沒有找到符合條件的作品
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* 分頁控制 */}
          {filteredProjects.length > itemsPerPage && (
            <div className="flex justify-center mt-6">
              <nav className="flex items-center space-x-1">
                <button 
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded-md ${
                    currentPage === 1 
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  上一頁
                </button>
                
                {getPageNumbers().map((number, index) => (
                  number === '...' ? (
                    <span key={`ellipsis-${index}`} className="px-3 py-1 text-gray-700">
                      ...
                    </span>
                  ) : (
                    <button
                      key={number}
                      onClick={() => handlePageChange(number)}
                      className={`px-3 py-1 rounded-md ${
                        currentPage === number
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                      }`}
                    >
                      {number}
                    </button>
                  )
                ))}
                
                <button 
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded-md ${
                    currentPage === totalPages 
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  下一頁
                </button>
              </nav>
            </div>
          )}
          
          {/* 顯示分頁信息 */}
          {filteredProjects.length > 0 && (
            <div className="text-center text-sm text-gray-500 mt-2">
              顯示 {indexOfFirstItem + 1} - {Math.min(indexOfLastItem, filteredProjects.length)} 筆，共 {filteredProjects.length} 筆
            </div>
          )}

          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <div className="p-6">
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 border-b pb-2">
                    {editingProject ? '編輯作品' : '新增作品'}
                  </h2>
                  
                  {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                      {error}
                    </div>
                  )}
                  
                  {successMessage && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
                      {successMessage}
                    </div>
                  )}

                  <form onSubmit={handleSubmit} id="projectForm" className="space-y-6">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h3 className="text-lg font-semibold mb-3 text-gray-700">基本資料</h3>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          名字
                        </label>
                        <input
                          type="text"
                          value={formData.name}
                          onChange={(e) => setFormData({...formData, name: e.target.value})}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                          required
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          國籍
                        </label>
                        <select
                          value={formData.nationality}
                          onChange={(e) => setFormData({...formData, nationality: e.target.value})}
                          className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                          required
                        >
                          <option value="">請選擇國籍</option>
                          <option value="台灣">台灣</option>
                          <option value="港澳">港澳</option>
                          <option value="越南">越南</option>
                          <option value="印尼">印尼</option>
                          <option value="日本">日本</option>
                          <option value="韓國">韓國</option>
                          <option value="馬來">馬來</option>
                          <option value="泰國">泰國</option>
                          <option value="馬泰">馬泰</option>
                          <option value="美國">美國</option>
                          <option value="菲律賓">菲律賓</option>
                          <option value="柬埔寨">柬埔寨</option>
                        </select>
                      </div>
                    </div>

                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h3 className="text-lg font-semibold mb-3 text-gray-700">身體資料</h3>
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            身高 (cm)
                          </label>
                          <input
                            type="number"
                            value={formData.height}
                            onChange={(e) => setFormData({...formData, height: e.target.value})}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                            required
                          />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            體重 (kg)
                          </label>
                          <input
                            type="number"
                            value={formData.weight}
                            onChange={(e) => setFormData({...formData, weight: e.target.value})}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                            required
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-3 gap-4 mb-4">
                        <div>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            年紀
                          </label>
                          <input
                            type="number"
                            value={formData.age}
                            onChange={(e) => setFormData({...formData, age: e.target.value})}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                            required
                          />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            胸型
                          </label>
                          <select
                            value={formData.cupSize}
                            onChange={(e) => setFormData({...formData, cupSize: e.target.value})}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                          >
                            <option value="">請選擇</option>
                            <option value="真奶">真奶</option>
                            <option value="人工">人工</option>
                            <option value="粉嫩">粉嫩</option>
                            <option value="雪白">雪白</option>
                            <option value="極品">極品</option>
                            <option value="柔軟">柔軟</option>
                            <option value="粉色">粉色</option>
                            <option value="超胸">超胸</option>
                            <option value="Q彈">Q彈</option> 
                          </select>
                        </div>
                        <div>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            罩杯
                          </label>
                          <select
                            value={formData.breastSize}
                            onChange={(e) => setFormData({...formData, breastSize: e.target.value})}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                          >
                            <option value="">請選擇</option>
                            <option value="A">A Cup</option>
                            <option value="B">B Cup</option>
                            <option value="C">C Cup</option>
                            <option value="D">D Cup</option>
                            <option value="E">E Cup</option>
                            <option value="F">F Cup</option>
                            <option value="G">G Cup</option>
                            <option value="H">H Cup</option>
                            <option value="I">I Cup</option>
                            <option value="J">J Cup</option>
                            <option value="K">K Cup</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h3 className="text-lg font-semibold mb-3 text-gray-700">服務資料</h3>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          金額
                        </label>
                        <input
                          type="number"
                          value={formData.price}
                          onChange={(e) => setFormData({...formData, price: e.target.value})}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                          required
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            城市
                          </label>
                          <input
                            type="text"
                            value={formData.city || ''}
                            onChange={(e) => {
                              console.log('城市輸入變更:', e.target.value);
                              setFormData({...formData, city: e.target.value});
                            }}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                            placeholder="請輸入城市"
                          />
                        </div>
                        <div>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            地區
                          </label>
                          <input
                            type="text"
                            value={formData.area}
                            onChange={(e) => setFormData({...formData, area: e.target.value})}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                            placeholder="請輸入地區"
                            required
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            服務類型
                          </label>
                          <select
                            value={formData.serviceType}
                            onChange={(e) => setFormData({...formData, serviceType: e.target.value})}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                            required
                          >
                            <option value="">請選擇類型</option>
                            <option value="定點套房">定點套房</option>
                            <option value="汽旅外約">汽旅外約</option>
                            <option value="定點個工">定點個工</option>
                          </select>
                        </div>
                        <div>
                          <label className="block text-gray-700 text-sm font-bold mb-2">
                            狀態
                          </label>
                          <select
                            value={formData.status}
                            onChange={(e) => setFormData({...formData, status: e.target.value})}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                            required
                          >
                            <option value="可預約">可預約</option>
                            <option value="今日約滿">今日約滿</option>
                            <option value="今日休息">今日休息</option>
                            <option value="隱藏">隱藏</option>
                          </select>
                        </div>
                      </div>

                      <div className="mb-4">
                        <label className="flex items-center text-gray-700 text-sm font-bold">
                          <input
                            type="checkbox"
                            checked={formData.isHot}
                            onChange={(e) => setFormData({...formData, isHot: e.target.checked})}
                            className="mr-2 h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                          設為熱門推薦
                        </label>
                        <p className="text-sm text-gray-500 mt-1">
                          勾選後，該項目將在列表中顯示"熱門"標籤
                        </p>
                      </div>
                    </div>

                    <div className="bg-gray-50 p-4 rounded-lg">
                      <h3 className="text-lg font-semibold mb-3 text-gray-700">媒體資料</h3>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          圖片 URLs
                        </label>
                        <div className="space-y-2">
                          {formData.imageUrls?.map((url, index) => (
                            <div key={index} className="flex gap-2">
                              <input
                                type="text"
                                value={url}
                                onChange={(e) => {
                                  const newUrls = [...formData.imageUrls];
                                  newUrls[index] = e.target.value.trim();
                                  setFormData(prev => ({
                                    ...prev,
                                    imageUrls: newUrls
                                  }));
                                }}
                                className="shadow appearance-none border rounded flex-1 py-2 px-3 
                                  text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                                placeholder="請輸入 Cloudinary 圖片 URL"
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  const newUrls = formData.imageUrls.filter((_, i) => i !== index);
                                  setFormData(prev => ({
                                    ...prev,
                                    imageUrls: newUrls
                                  }));
                                }}
                                className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600
                                  transition-colors duration-300"
                              >
                                刪除
                              </button>
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={() => {
                              setFormData(prev => ({
                                ...prev,
                                imageUrls: [...(prev.imageUrls || []), '']
                              }));
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600
                              transition-colors duration-300 w-full"
                          >
                            新增圖片
                          </button>
                        </div>
                        
                        {/* 圖片預覽區 */}
                        {formData.imageUrls?.length > 0 && (
                          <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4">
                            {formData.imageUrls.map((url, index) => (
                              url && (
                                <div key={index} className="relative aspect-[3/4] rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow">
                                  <img
                                    src={!failedImages.has(url) ? url : '/placeholder.png'}
                                    alt={`預覽圖片 ${index + 1}`}
                                    className="w-full h-full object-cover"
                                    onError={(e) => {
                                      console.error('圖片載入失敗:', url);
                                      setFailedImages(prev => new Set([...prev, url]));
                                      e.preventDefault();
                                    }}
                                  />
                                </div>
                              )
                            ))}
                          </div>
                        )}
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          影片 URLs
                        </label>
                        <div className="space-y-2">
                          {formData.videoUrls?.map((url, index) => (
                            <div key={index} className="flex gap-2">
                              <input
                                type="text"
                                value={url}
                                onChange={(e) => {
                                  const newUrls = [...formData.videoUrls];
                                  newUrls[index] = e.target.value.trim();
                                  setFormData(prev => ({
                                    ...prev,
                                    videoUrls: newUrls
                                  }));
                                }}
                                className="shadow appearance-none border rounded flex-1 py-2 px-3 
                                  text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-blue-500 transition-colors"
                                placeholder="請輸入影片 URL"
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  const newUrls = formData.videoUrls.filter((_, i) => i !== index);
                                  setFormData(prev => ({
                                    ...prev,
                                    videoUrls: newUrls
                                  }));
                                }}
                                className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600
                                  transition-colors duration-300"
                              >
                                刪除
                              </button>
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={() => {
                              setFormData(prev => ({
                                ...prev,
                                videoUrls: [...(prev.videoUrls || []), '']
                              }));
                            }}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600
                              transition-colors duration-300 w-full"
                          >
                            新增影片
                          </button>
                        </div>
                        
                        {/* 影片預覽區 */}
                        {formData.videoUrls?.length > 0 && (
                          <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4">
                            {formData.videoUrls.map((url, index) => (
                              url && (
                                <div key={index} className="relative aspect-video rounded-lg overflow-hidden bg-zinc-100 shadow-md hover:shadow-lg transition-shadow">
                                  <video
                                    src={url}
                                    className="w-full h-full object-cover"
                                    controls
                                    controlsList="nodownload"
                                    playsInline
                                  >
                                    您的瀏覽器不支持影片播放
                                  </video>
                                </div>
                              )
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>

                  <div className="sticky bottom-0 bg-white pt-4 mt-4 border-t flex justify-end">
                    <button
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                      className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2 transition-colors"
                    >
                      取消
                    </button>
                    <button
                      form="projectForm"
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                    >
                      保存
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isDetailModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <div className="p-6">
                  <h2 className="text-2xl font-bold mb-4">編輯詳細資訊</h2>
                  
                  {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                      {error}
                    </div>
                  )}
                  
                  {successMessage && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
                      {successMessage}
                    </div>
                  )}

                  <form onSubmit={handleDetailSubmit}>
                    <div className="space-y-4">
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          服務方案
                        </label>
                        <textarea
                          value={formData.servicePackages || ''}
                          onChange={(e) => setFormData({...formData, servicePackages: e.target.value})}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          rows="4"
                          placeholder="請輸入服務方案內容..."
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          服務項目
                        </label>
                        <textarea
                          value={formData.serviceItems || ''}
                          onChange={(e) => setFormData({...formData, serviceItems: e.target.value})}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          rows="4"
                          placeholder="請輸入服務項目..."
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          加值服務
                        </label>
                        <textarea
                          value={formData.additionalServices || ''}
                          onChange={(e) => setFormData({...formData, additionalServices: e.target.value})}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          rows="4"
                          placeholder="請輸入加值服務內容..."
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          方案優惠
                        </label>
                        <textarea
                          value={formData.packageDeals || ''}
                          onChange={(e) => setFormData({...formData, packageDeals: e.target.value})}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          rows="4"
                          placeholder="請輸入方案優惠內容..."
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          備註
                        </label>
                        <textarea
                          value={formData.notes || ''}
                          onChange={(e) => setFormData({...formData, notes: e.target.value})}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          rows="4"
                          placeholder="請輸入備註內容..."
                        />
                      </div>
                    </div>

                    <div className="sticky bottom-0 bg-white pt-4 mt-4 border-t flex justify-end">
                      <button
                        type="button"
                        onClick={() => {
                          setIsDetailModalOpen(false);
                          setError('');
                          setSuccessMessage('');
                        }}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2"
                      >
                        取消
                      </button>
                      <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      >
                        保存
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {/* 確認對話框 */}
          {isConfirmOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 confirm-dialog-backdrop">
              <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 confirm-dialog">
                <h3 className="text-xl font-bold mb-4">確認刪除</h3>
                <p className="text-gray-600 mb-6">
                  確定要刪除此作品嗎？此操作無法撤銷。
                  <br />
                  <span className="text-sm text-gray-500">（按下 Enter 鍵確認刪除）</span>
                </p>
                <div className="flex justify-end space-x-4 confirm-dialog-buttons">
                  <button
                    onClick={() => {
                      setIsConfirmOpen(false);
                      setDeletingProjectId(null);
                    }}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors duration-200"
                  >
                    取消
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200 confirm-btn"
                  >
                    確定刪除
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AdminLayout>
  );
}

export default ProjectManagement; 