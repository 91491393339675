import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import BackgroundEffect from '../components/BackgroundEffect';
import { memberApi, api } from '../utils/api';

function Login() {
  const [isLogin, setIsLogin] = useState(true);
  const [allowRegister, setAllowRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    Useraccount: '',
    password: '',
    confirmPassword: ''
  });

  // 從URL中獲取重定向路徑
  const location = useLocation();
  const navigate = useNavigate();
  const redirectPath = location.state?.from || '/';

  // 獲取註冊設置
  useEffect(() => {
    const fetchRegisterSettings = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/settings/register');
        setAllowRegister(response.data.allowRegister);
      } catch (error) {
        console.error('獲取註冊設置失敗:', error);
        setAllowRegister(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRegisterSettings();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loadingToast = toast.loading(isLogin ? '正在登入...' : '正在註冊...');

    try {
      // 如果是註冊，先檢查密碼是否匹配
      if (!isLogin && formData.password !== formData.confirmPassword) {
        throw new Error('兩次輸入的密碼不一致');
      }

      if (isLogin) {
        // 登入請求
        const response = await memberApi.post('/auth/member/login', {
          Useraccount: formData.Useraccount,
          password: formData.password
        });
        
        // 登入成功，保存 token
        localStorage.setItem('memberToken', response.data.token);
        toast.success('登入成功！', { id: loadingToast });
        
        // 輸出重定向路徑以便調試
        console.log('登入成功，將跳轉到:', redirectPath);
        
        // 延遲跳轉，讓用戶看到成功提示
        setTimeout(() => {
          navigate(redirectPath); // 跳轉到重定向路徑
        }, 1500);
      } else {
        // 註冊請求
        const response = await memberApi.post('/auth/member/register', {
          Useraccount: formData.Useraccount,
          password: formData.password
        });
        
        // 註冊成功
        toast.success('註冊成功！請登入', { id: loadingToast });
        setTimeout(() => {
          setIsLogin(true);
          setFormData({
            Useraccount: '',
            password: '',
            confirmPassword: ''
          });
        }, 1000);
      }
    } catch (error) {
      console.error(isLogin ? '登入失敗:' : '註冊失敗:', error);
      toast.error(error.message || (isLogin ? '登入失敗' : '註冊失敗'), { id: loadingToast });
    }
  };

  return (
    <BackgroundEffect direction="right" speed={0.2}>
      <div className="min-h-screen flex items-center justify-center px-4 py-12">
        <div className="max-w-md w-full space-y-8 bg-white/80 dark:bg-zinc-800/80 p-8 rounded-lg shadow-card">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-extrabold text-zinc-900 dark:text-zinc-100">
              {isLogin ? '會員登入' : '會員註冊'}
            </h2>
            {!isLogin && (
              <p className="mt-2 text-center text-sm text-zinc-600 dark:text-zinc-400">
                已經有帳號了？
                <button
                  onClick={() => {
                    setIsLogin(true);
                    setFormData({
                      Useraccount: '',
                      password: '',
                      confirmPassword: ''
                    });
                  }}
                  className="ml-1 font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  立即登入
                </button>
              </p>
            )}
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white dark:bg-zinc-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="Useraccount" className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    用戶帳號
                  </label>
                  <div className="mt-1">
                    <input
                      id="Useraccount"
                      name="Useraccount"
                      type="text"
                      required
                      value={formData.Useraccount}
                      onChange={(e) => setFormData({ ...formData, Useraccount: e.target.value })}
                      className="appearance-none block w-full px-3 py-2 border border-zinc-300 dark:border-zinc-600 rounded-md shadow-sm placeholder-zinc-400 dark:placeholder-zinc-500 bg-white dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="請輸入用戶帳號"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    密碼
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      value={formData.password}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                      className="appearance-none block w-full px-3 py-2 border border-zinc-300 dark:border-zinc-600 rounded-md shadow-sm placeholder-zinc-400 dark:placeholder-zinc-500 bg-white dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="請輸入密碼"
                    />
                  </div>
                </div>

                {!isLogin && (
                  <div>
                    <label htmlFor="confirmPassword" className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
                      確認密碼
                    </label>
                    <div className="mt-1">
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        required
                        value={formData.confirmPassword}
                        onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                        className="appearance-none block w-full px-3 py-2 border border-zinc-300 dark:border-zinc-600 rounded-md shadow-sm placeholder-zinc-400 dark:placeholder-zinc-500 bg-white dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="請再次輸入密碼"
                      />
                    </div>
                  </div>
                )}

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    {isLogin ? '登入' : '註冊'}
                  </button>
                </div>
                
                {isLogin && !isLoading && (
                  <div className="mt-4 text-center text-sm text-zinc-600 dark:text-zinc-400">
                    還沒有帳號？
                    {allowRegister ? (
                      <button
                        type="button"
                        onClick={() => {
                          setIsLogin(false);
                          setFormData({
                            Useraccount: '',
                            password: '',
                            confirmPassword: ''
                          });
                        }}
                        className="ml-1 font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                      >
                        立即註冊
                      </button>
                    ) : (
                      <span className="ml-1 font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300">
                        聯絡客服領取
                      </span>
                    )}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </BackgroundEffect>
  );
}

export default Login; 