import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { toast } from 'react-hot-toast';

import { adminApi, API_DOMAIN } from '../../utils/api';



function Login() {

  const [Useraccount, setUseraccount] = useState('');

  const [password, setPassword] = useState('');

  const [error, setError] = useState('');

  const [showSortOptions, setShowSortOptions] = useState(false);

  const [sortOption, setSortOption] = useState('latest');

  const navigate = useNavigate();



  // 確保登錄頁面使用淺色主題

  useEffect(() => {

    // 移除深色主題類

    document.documentElement.classList.remove('dark');

    

    // 清理函數，當組件卸載時執行

    return () => {

      // 恢復用戶之前的主題設置

      const theme = localStorage.getItem('theme');

      if (theme === 'dark') {

        document.documentElement.classList.add('dark');

      }

    };

  }, []);



  const handleSubmit = async (e) => {

    e.preventDefault();

    setError('');



    try {

      console.log('嘗試登入:', { Useraccount });

      

      const response = await fetch(`${API_DOMAIN}/api/auth/admin/login`, {

        method: 'POST',

        headers: {

          'Content-Type': 'application/json'

        },

        body: JSON.stringify({

          Useraccount,

          password

        })

      });



      const data = await response.json();

      console.log('登入響應:', data);



      if (!response.ok) {

        throw new Error(data.message || '登入失敗');

      }



      const { token, user } = data;



      if (!token || !user) {

        throw new Error('伺服器響應格式錯誤');

      }



      // 儲存管理員 token

      localStorage.setItem('adminToken', token);

      

      toast.success('登入成功！');

      navigate('/admin/dashboard');

    } catch (error) {

      console.error('登入錯誤:', error);

      setError(error.message || '登入失敗，請稍後重試');

      toast.error(error.message || '登入失敗，請稍後重試');

    }

  };



  return (

    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">

      <div className="max-w-md w-full space-y-8">

        <div>

          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">

            後台管理系統

          </h2>

          <p className="mt-2 text-center text-sm text-gray-600">

            請輸入管理員帳號密碼

          </p>

        </div>

        

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>

          {error && (

            <div className="rounded-md bg-red-50 p-4">

              <div className="text-sm text-red-700">{error}</div>

            </div>

          )}

          

          <div className="rounded-md shadow-sm -space-y-px">

            <div>

              <label htmlFor="Useraccount" className="sr-only">

                用戶帳號

              </label>

              <input

                id="Useraccount"

                name="Useraccount"

                type="text"

                required

                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"

                placeholder="用戶帳號"

                value={Useraccount}

                onChange={(e) => setUseraccount(e.target.value)}

              />

            </div>

            <div>

              <label htmlFor="password" className="sr-only">

                密碼

              </label>

              <input

                id="password"

                name="password"

                type="password"

                required

                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"

                placeholder="密碼"

                value={password}

                onChange={(e) => setPassword(e.target.value)}

              />

            </div>

          </div>



          <div>

            <button

              type="submit"

              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

            >

              登入

            </button>

          </div>

        </form>

      </div>

    </div>

  );

}



export default Login; 
