import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackgroundEffect from '../components/BackgroundEffect';
import PixelCard from '../components/PixelCard';
import { API_DOMAIN } from '../utils/api';

function Projects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [clickedProjectId, setClickedProjectId] = useState(null);
  const [failedImages, setFailedImages] = useState(new Set());
  const [searchName, setSearchName] = useState('');
  
  // 跑馬燈相關狀態
  const [marqueeMessages, setMarqueeMessages] = useState([
    '🔥 熱門消息：本站新增多名優質妹妹，歡迎預約！',
    '📢 新客驗證：新加入的哥布林必須驗證！',
    '🌟 營業時間：每週一至週日 早上10:00-晚上24:00'
  ]);
  const [marqueeSpeed, setMarqueeSpeed] = useState(20);
  const [marqueeAutoplay, setMarqueeAutoplay] = useState(true);

  // 分頁相關狀態
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(12); // 改為可動態設置的狀態
  const [totalPages, setTotalPages] = useState(1);

  // 添加篩選狀態
  const [filters, setFilters] = useState({
    areas: [],
    nationalities: [],
    serviceTypes: [],
    cities: [],
    serviceItems: []  // 新增服務內容篩選
  });
  
  // 改為從現有數據中提取可選項
  const [availableFilters, setAvailableFilters] = useState({
    areas: [],
    nationalities: [],
    serviceTypes: [],
    cities: [],
    serviceItems: []  // 新增服務內容篩選選項
  });

  // 檢測是否為移動設備
  const [isMobile, setIsMobile] = useState(false);
  
  // 添加篩選容器折疊狀態
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  // 計算每頁應顯示的項目數量
  const calculateItemsPerPage = () => {
    const screenWidth = window.innerWidth;
    // 根據屏幕寬度計算每行能顯示的卡片數
    let cardsPerRow;
    if (screenWidth >= 1536) { // 2xl
      cardsPerRow = 5;
    } else if (screenWidth >= 1280) { // xl
      cardsPerRow = 4;
    } else if (screenWidth >= 1024) { // lg
      cardsPerRow = 3;
    } else if (screenWidth >= 768) { // md
      cardsPerRow = 3;
    } else { // sm and smaller
      cardsPerRow = 2;
    }
    
    // 返回行數 * 每行卡片數，保證至少顯示4行
    return cardsPerRow * 4;
  };

  // 監聽窗口大小變化來調整每頁項目數量
  useEffect(() => {
    const handleResize = () => {
      const newItemsPerPage = calculateItemsPerPage();
      if (newItemsPerPage !== projectsPerPage) {
        setProjectsPerPage(newItemsPerPage);
      }
    };
    
    // 初始化設置
    handleResize();
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [projectsPerPage]);

  // 處理篩選變化
  const handleFilterChange = (type, value) => {
    setFilters(prev => {
      const currentFilters = prev[type];
      const newFilters = currentFilters.includes(value)
        ? currentFilters.filter(item => item !== value)
        : [...currentFilters, value];
      
      return {
        ...prev,
        [type]: newFilters
      };
    });
    
    // 重置頁碼
    setCurrentPage(1);
  };

  // 清除所有篩選條件
  const clearAllFilters = () => {
    setFilters({
      areas: [],
      nationalities: [],
      serviceTypes: [],
      cities: [],
      serviceItems: []
    });
    
    // 重置頁碼
    setCurrentPage(1);
  };

  // 處理搜索名稱變化
  const handleSearchNameChange = (e) => {
    setSearchName(e.target.value);
    // 重置頁碼
    setCurrentPage(1);
  };

  // 獲取項目數據
  useEffect(() => {
    fetchProjects();
  }, []);

  // 從後端獲取跑馬燈消息
  useEffect(() => {
    const fetchMarqueeMessages = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/api/settings/marquee`);
        
        if (!response.ok) {
          console.warn(`獲取跑馬燈消息失敗: HTTP ${response.status}`);
          // 使用默認消息，不拋出錯誤
          return;
        }
        
        // 嘗試解析 JSON 前先檢查響應內容
        const text = await response.text();
        let data;
        try {
          data = JSON.parse(text);
        } catch (e) {
          console.error('解析跑馬燈數據失敗:', e, '原始響應:', text);
          return; // 使用默認消息
        }
        
        if (data && Array.isArray(data.messages)) {
          const activeMessages = data.messages
            .filter(msg => msg.active)
            .map(msg => msg.content);
          
          if (activeMessages.length > 0) {
            setMarqueeMessages(activeMessages);
          }
          
          if (typeof data.speed === 'number') {
            setMarqueeSpeed(data.speed);
          }
          
          if (typeof data.autoplay === 'boolean') {
            setMarqueeAutoplay(data.autoplay);
          }
        }
      } catch (error) {
        console.error('獲取跑馬燈消息失敗:', error);
        // 使用默認消息，不影響用戶體驗
      }
    };
    
    fetchMarqueeMessages();
  }, []);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/api/projects`);
      
      if (!response.ok) {
        console.error(`獲取作品失敗: HTTP ${response.status}`);
        throw new Error(`獲取作品失敗: HTTP ${response.status}`);
      }
      
      // 嘗試解析 JSON 前先檢查響應內容
      const text = await response.text();
      let data;
      try {
        data = JSON.parse(text);
      } catch (e) {
        console.error('解析作品數據失敗:', e, '原始響應:', text);
        throw new Error('解析作品數據失敗');
      }
      
      if (!Array.isArray(data)) {
        console.error('獲取的作品數據格式不正確:', data);
        throw new Error('獲取的作品數據格式不正確');
      }
      
      const visibleProjects = data.filter(project => project.status !== '隱藏');
      
      // 按國籍分組排序
      const groupedByNationality = {};
      const nationalityOrder = [
        '台灣', '港澳', '越南', '印尼', '日本', '韓國', 
        '馬來', '泰國', '馬泰', '美國', '菲律賓', '柬埔寨'
      ];
      
      // 初始化分組
      nationalityOrder.forEach(nat => {
        groupedByNationality[nat] = [];
      });
      
      // 將項目按國籍分組
      visibleProjects.forEach(project => {
        const nationality = project.nationality || '其他';
        if (!groupedByNationality[nationality]) {
          groupedByNationality[nationality] = [];
        }
        groupedByNationality[nationality].push(project);
      });
      
      // 將分組後的項目合併為一個數組
      const sortedProjects = [];
      nationalityOrder.forEach(nationality => {
        if (groupedByNationality[nationality] && groupedByNationality[nationality].length > 0) {
          sortedProjects.push(...groupedByNationality[nationality]);
        }
      });
      
      // 處理可能不在預定義順序中的國籍
      Object.keys(groupedByNationality).forEach(nationality => {
        if (!nationalityOrder.includes(nationality) && groupedByNationality[nationality].length > 0) {
          sortedProjects.push(...groupedByNationality[nationality]);
        }
      });
      
      // 提取現有的唯一地區和國籍
      const uniqueAreas = [...new Set(visibleProjects.map(p => p.area))].sort();
      const uniqueNationalities = [...new Set(visibleProjects.map(p => p.nationality))].sort();
      const uniqueServiceTypes = [...new Set(visibleProjects.map(p => p.serviceType))].sort();
      const uniqueCities = [...new Set(visibleProjects.map(p => p.city).filter(Boolean))].sort();
      
      // 提取服務內容選項
      // 從所有項目的serviceItems中提取關鍵詞
      const allServiceItems = visibleProjects
        .filter(p => p.serviceItems) // 過濾掉沒有serviceItems的項目
        .map(p => p.serviceItems)
        .join('、'); // 合併所有服務項目文本
      
      // 分割並去重
      const serviceItemsSet = new Set();
      
      // 使用正則表達式匹配常見的分隔符號
      const itemsArray = allServiceItems.split(/[,，、;；.。\s]+/);
      
      // 過濾並添加有效的服務項目
      itemsArray.forEach(item => {
        const trimmedItem = item.trim();
        if (trimmedItem && trimmedItem.length >= 2) { // 只添加長度至少為2的項目
          serviceItemsSet.add(trimmedItem);
        }
      });
      
      const uniqueServiceItems = [...serviceItemsSet].sort();
      
      setAvailableFilters({
        areas: uniqueAreas,
        nationalities: uniqueNationalities,
        serviceTypes: uniqueServiceTypes,
        cities: uniqueCities,
        serviceItems: uniqueServiceItems
      });
      
      setProjects(sortedProjects);
      setLoading(false);
    } catch (error) {
      console.error('獲取作品失敗:', error);
      setError('獲取作品失敗');
      setLoading(false);
    }
  };

  // 篩選邏輯
  const filteredProjects = projects.filter(project => {
    const nameMatch = searchName === '' || project.name.toLowerCase().includes(searchName.toLowerCase());
    const areaMatch = filters.areas.length === 0 || filters.areas.includes(project.area);
    const nationalityMatch = filters.nationalities.length === 0 || filters.nationalities.includes(project.nationality);
    const serviceTypeMatch = filters.serviceTypes.length === 0 || filters.serviceTypes.includes(project.serviceType);
    const cityMatch = filters.cities.length === 0 || (project.city && filters.cities.includes(project.city));
    
    // 新增服務內容篩選邏輯
    const serviceItemsMatch = filters.serviceItems.length === 0 || 
      (project.serviceItems && filters.serviceItems.some(item => 
        project.serviceItems.toLowerCase().includes(item.toLowerCase())
      ));
    
    return nameMatch && areaMatch && nationalityMatch && serviceTypeMatch && cityMatch && serviceItemsMatch && project.status !== '隱藏';
  });

  useEffect(() => {
    // 檢查是否為桌面設備 (寬度大於 1024px)
    const checkDesktop = () => {
      setIsMobile(!window.matchMedia('(min-width: 1024px)').matches);
    };

    // 初始檢查
    checkDesktop();

    // 監聽視窗大小變化
    window.addEventListener('resize', checkDesktop);

    return () => window.removeEventListener('resize', checkDesktop);
  }, [isMobile]);

  // 處理卡片點擊
  const handleCardClick = async (projectId) => {
    setClickedProjectId(projectId);
    // 延遲 300ms 再跳轉，讓動畫效果有時間顯示
    await new Promise(resolve => setTimeout(resolve, 300));
    navigate(`/projects/${projectId}`);
  };

  // 計算當前頁面應該顯示的項目
  const getCurrentPageProjects = (allProjects) => {
    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    
    // 獲取當前頁的項目
    const currentPageItems = allProjects.slice(indexOfFirstProject, indexOfLastProject);
    
    // 如果當前頁有項目，確保每個國籍的第一個項目都顯示國籍標題
    if (currentPageItems.length > 0) {
      // 獲取當前頁面中的所有國籍
      const nationalitiesInCurrentPage = [...new Set(currentPageItems.map(p => p.nationality))];
      
      // 為每個國籍找到第一個項目的索引
      const firstItemIndices = nationalitiesInCurrentPage.map(nat => 
        currentPageItems.findIndex(p => p.nationality === nat)
      );
      
      // 標記這些項目需要顯示國籍標題
      currentPageItems.forEach((project, idx) => {
        project._showNationalityHeader = firstItemIndices.includes(idx);
      });
    }
    
    return currentPageItems;
  };

  // 更新分頁狀態
  useEffect(() => {
    if (filteredProjects && filteredProjects.length > 0) {
      setTotalPages(Math.ceil(filteredProjects.length / projectsPerPage));
      // 如果當前頁碼超過了總頁數，則重置為第一頁
      if (currentPage > Math.ceil(filteredProjects.length / projectsPerPage)) {
        setCurrentPage(1);
      }
    } else {
      setTotalPages(1);
    }
  }, [filteredProjects, projectsPerPage, currentPage]);

  // 處理頁面變更
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // 滾動到頁面頂部
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (loading) {
    return (
      <BackgroundEffect direction="diagonal" speed={0.3}>
        <div className="min-h-screen p-8">
          <div className="container mx-auto">
            {/* 骨架屏加載效果 */}
            <div className="mb-8 bg-white/80 dark:bg-zinc-800/80 p-6 rounded-lg shadow-card animate-fadeIn">
              <div className="h-8 bg-zinc-200 dark:bg-zinc-700 rounded w-1/4 mb-4 relative overflow-hidden">
                <div className="absolute inset-0 -translate-x-full bg-gradient-to-r from-transparent via-zinc-300 to-transparent animate-shimmer" />
              </div>
              <div className="flex gap-4 mb-6">
                {[1,2,3].map(i => (
                  <div key={`skeleton-filter-${i}`} className="h-4 bg-zinc-200 dark:bg-zinc-700 rounded animate-pulse" />
                ))}
              </div>
            </div>

            {/* 項目卡片骨架屏 */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {[1,2,3,4].map(i => (
                <div key={`skeleton-sort-${i}`} className="bg-white/80 dark:bg-zinc-800/80 rounded-lg shadow-card animate-fadeIn" 
                  style={{animationDelay: `${i * 0.1}s`}}>
                  <div className="aspect-[3/4] bg-zinc-200 dark:bg-zinc-700 rounded-t-lg relative overflow-hidden">
                    <div className="absolute inset-0 -translate-x-full bg-gradient-to-r from-transparent via-zinc-300 to-transparent animate-shimmer" />
                  </div>
                  <div className="p-5 space-y-4">
                    <div className="h-6 bg-zinc-200 dark:bg-zinc-700 rounded w-3/4 relative overflow-hidden">
                      <div className="absolute inset-0 -translate-x-full bg-gradient-to-r from-transparent via-zinc-300 to-transparent animate-shimmer" />
                    </div>
                    <div className="space-y-2">
                      {[1,2,3].map(j => (
                        <div key={`skeleton-project-${j}`} className="h-48 bg-zinc-200 dark:bg-zinc-700 rounded animate-pulse" />
                      ))}
                    </div>
                    <div className="h-10 bg-zinc-200 dark:bg-zinc-700 rounded relative overflow-hidden">
                      <div className="absolute inset-0 -translate-x-full bg-gradient-to-r from-transparent via-zinc-300 to-transparent animate-shimmer" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </BackgroundEffect>
    );
  }

  if (error) {
    return (
      <BackgroundEffect direction="diagonal" speed={0.3}>
        <div className="text-center py-10 text-zinc-600 dark:text-zinc-400">{error}</div>
      </BackgroundEffect>
    );
  }

  return (
    <BackgroundEffect direction="diagonal" speed={0.3}>
      <div className="relative min-h-screen w-full overflow-x-hidden">
        <div className="relative z-10 w-full">
          <div className="container mx-auto px-4 py-2">
            {/* 跑馬燈區域 */}
            <div className="mb-2 mt-0 bg-gradient-to-r from-rose-500/90 to-purple-500/90 dark:from-rose-600/90 dark:to-purple-600/90 p-2 rounded-lg shadow-card overflow-hidden border border-rose-300 dark:border-rose-700">
              <div className="flex items-center">
                <div className="flex-shrink-0 px-2 py-1 bg-white/20 rounded-md mr-3">
                  <span className="text-white font-bold text-sm">最新公告</span>
                </div>
                <div className="marquee-container overflow-hidden whitespace-nowrap flex-1">
                  {marqueeAutoplay && (
                    <>
                      <div className="inline-block text-white font-medium" style={{
                        animation: `marquee ${marqueeSpeed}s linear infinite`
                      }}>
                        {marqueeMessages.map((message, index) => (
                          <span key={index}>{message} &nbsp;&nbsp;&nbsp; </span>
                        ))}
                      </div>
                      <div className="inline-block text-white font-medium" style={{
                        animation: `marquee ${marqueeSpeed}s linear infinite`
                      }} aria-hidden="true">
                        {marqueeMessages.map((message, index) => (
                          <span key={`repeat-${index}`}>{message} &nbsp;&nbsp;&nbsp; </span>
                        ))}
                      </div>
                    </>
                  )}
                  {!marqueeAutoplay && (
                    <div className="inline-block text-white font-medium">
                      {marqueeMessages.map((message, index) => (
                        <span key={index}>{message} &nbsp;&nbsp;&nbsp; </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            {/* 名稱搜索區域 - 確保不被導航欄遮擋 */}
            <div className="mb-2 mt-0 bg-white/70 dark:bg-zinc-800/80 p-4 rounded-lg shadow-card hover:shadow-hover 
              transition-all duration-300 transform hover:-translate-y-1 border border-zinc-200 dark:border-zinc-700">
              <h2 className="text-xl font-bold mb-2 text-zinc-800 dark:text-zinc-200">妹妹名稱搜索</h2>
              <div className="relative">
                <input
                  type="text"
                  placeholder="搜尋妹妹名稱..."
                  value={searchName}
                  onChange={handleSearchNameChange}
                  className="w-full px-4 py-2 rounded-lg border border-zinc-300 dark:border-zinc-700 
                    bg-white/80 dark:bg-zinc-800/80 text-zinc-800 dark:text-zinc-200
                    focus:outline-none focus:ring-2 focus:ring-rose-500 dark:focus:ring-rose-400
                    placeholder-zinc-400 dark:placeholder-zinc-600"
                />
                <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-zinc-400 dark:text-zinc-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
              {searchName && (
                <div className="mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                  搜索: <span className="font-medium text-rose-500 dark:text-rose-400">{searchName}</span>
                  <button 
                    onClick={() => setSearchName('')}
                    className="ml-2 text-zinc-500 hover:text-rose-500 dark:text-zinc-400 dark:hover:text-rose-400 transition-colors"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                    </svg>
                    清除
                  </button>
                </div>
              )}
            </div>
            
            {/* 篩選區域 */}
            <div className="mb-8 bg-white/70 dark:bg-zinc-800/80 p-6 rounded-lg shadow-card hover:shadow-hover 
              transition-all duration-300 transform hover:-translate-y-1 border border-zinc-200 dark:border-zinc-700">
              
              {/* 篩選標題和展開/折疊按鈕 */}
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-zinc-800 dark:text-zinc-200">篩選條件</h2>
                <button 
                  onClick={() => setIsFilterExpanded(!isFilterExpanded)}
                  className="px-4 py-2 bg-rose-100 dark:bg-rose-900/30 text-rose-600 dark:text-rose-400 rounded-lg 
                    hover:bg-rose-200 dark:hover:bg-rose-800/40 transition-colors flex items-center space-x-2"
                >
                  <span>{isFilterExpanded ? '收起篩選' : '進階搜尋'}</span>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className={`h-5 w-5 transition-transform duration-300 ${isFilterExpanded ? 'rotate-180' : ''}`} 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </div>
              
              {/* 篩選選項 - 只在展開時顯示 */}
              {isFilterExpanded && (
                <>
              {/* 城市篩選 */}
              {availableFilters.cities.length > 0 && (
                <div className="mb-6">
                  <h2 className="text-xl font-bold mb-4 text-zinc-800 dark:text-zinc-200">城市選擇</h2>
                  <div className="flex flex-wrap gap-4">
                    {availableFilters.cities.map(city => (
                      <label key={city} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={filters.cities.includes(city)}
                          onChange={() => handleFilterChange('cities', city)}
                          className="form-checkbox h-5 w-5 text-zinc-600 dark:text-zinc-400 
                                bg-white/90 dark:bg-zinc-700 border-zinc-200 dark:border-zinc-600"
                        />
                        <span className="text-zinc-700 dark:text-zinc-300">{city}</span>
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {/* 地區篩選 */}
              {availableFilters.areas.length > 0 && (
                <div className="mb-6">
                  <h2 className="text-xl font-bold mb-4 text-zinc-800 dark:text-zinc-200">區域選擇</h2>
                  <div className="flex flex-wrap gap-4">
                    {availableFilters.areas.map(area => (
                      <label key={area} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={filters.areas.includes(area)}
                          onChange={() => handleFilterChange('areas', area)}
                          className="form-checkbox h-5 w-5 text-zinc-600 dark:text-zinc-400 
                                bg-white/90 dark:bg-zinc-700 border-zinc-200 dark:border-zinc-600"
                        />
                        <span className="text-zinc-700 dark:text-zinc-300">{area}</span>
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {/* 國籍篩選 */}
              {availableFilters.nationalities.length > 0 && (
                <div className="mb-6">
                  <h2 className="text-xl font-bold mb-4 text-zinc-800 dark:text-zinc-200">國籍選擇</h2>
                  <div className="flex flex-wrap gap-4">
                    {availableFilters.nationalities.map(nationality => (
                      <label key={nationality} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={filters.nationalities.includes(nationality)}
                          onChange={() => handleFilterChange('nationalities', nationality)}
                          className="form-checkbox h-5 w-5 text-zinc-600 dark:text-zinc-400 
                                bg-white/90 dark:bg-zinc-700 border-zinc-200 dark:border-zinc-600"
                        />
                        <span className="text-zinc-700 dark:text-zinc-300">{nationality}</span>
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {/* 類型篩選 */}
              {availableFilters.serviceTypes.length > 0 && (
                <div className="mb-6">
                  <h2 className="text-xl font-bold mb-4 text-zinc-800 dark:text-zinc-200">服務類型</h2>
                  <div className="flex flex-wrap gap-4">
                    {availableFilters.serviceTypes.map(serviceType => (
                      <label key={serviceType} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={filters.serviceTypes.includes(serviceType)}
                          onChange={() => handleFilterChange('serviceTypes', serviceType)}
                          className="form-checkbox h-5 w-5 text-zinc-600 dark:text-zinc-400 
                                bg-white/90 dark:bg-zinc-700 border-zinc-200 dark:border-zinc-600"
                        />
                        <span className="text-zinc-700 dark:text-zinc-300 whitespace-nowrap">{serviceType}</span>
                      </label>
                    ))}
                  </div>
                </div>
              )}

                  {/* 服務內容篩選 */}
                  {availableFilters.serviceItems.length > 0 && (
                    <div className="mb-6">
                      <h2 className="text-xl font-bold mb-4 text-zinc-800 dark:text-zinc-200">服務內容</h2>
                      <div className="flex flex-wrap gap-4">
                        {availableFilters.serviceItems.map(item => (
                          <label key={item} className="flex items-center space-x-2">
                            <input
                              type="checkbox"
                              checked={filters.serviceItems.includes(item)}
                              onChange={() => handleFilterChange('serviceItems', item)}
                              className="form-checkbox h-5 w-5 text-zinc-600 dark:text-zinc-400 
                                bg-white/90 dark:bg-zinc-700 border-zinc-200 dark:border-zinc-600"
                            />
                            <span className="text-zinc-700 dark:text-zinc-300">{item}</span>
                          </label>
                        ))}
              </div>
                    </div>
                  )}
                </>
              )}

              {/* 篩選狀態和重置按鈕 - 始終顯示 */}
              <div className="flex flex-col sm:flex-row justify-between items-center mt-4">
                {/* 結果計數 - 只在有篩選條件時顯示 */}
              {(filters.areas.length > 0 || 
                filters.nationalities.length > 0 || 
                filters.serviceTypes.length > 0 ||
                  filters.cities.length > 0 ||
                  filters.serviceItems.length > 0 || 
                  searchName.trim() !== '') && (
                  <div className="mb-4 sm:mb-0 text-zinc-700 dark:text-zinc-300 bg-zinc-100/70 dark:bg-zinc-800/80 backdrop-blur-sm p-2 rounded-lg">
                  <span className="font-medium">精選推薦：</span>
                  <span className="mx-1 text-xl font-bold text-rose-500 dark:text-rose-400 
                      inline-block transform hover:scale-110 transition-all duration-300
                      bg-rose-100 dark:bg-rose-900/30 px-2 py-1 rounded-lg shadow-sm
                      border border-rose-200 dark:border-rose-800/50 animate-pulse">
                    {filteredProjects.length}
                  </span> 
                  <span>位優質妹妹等您預約</span>
                </div>
              )}

                {/* 重置按鈕 - 只在有篩選條件時顯示 */}
                {(filters.areas.length > 0 || 
                  filters.nationalities.length > 0 || 
                  filters.serviceTypes.length > 0 ||
                  filters.cities.length > 0 ||
                  filters.serviceItems.length > 0) && (
                  <button
                    onClick={clearAllFilters}
                    className="px-6 py-2 rounded-lg flex items-center space-x-2
                      transition-all duration-300 transform hover:scale-105 hover:shadow-lg
                      bg-rose-500 hover:bg-rose-600 text-white"
                  >
                    <span>清除篩選</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                    </svg>
                  </button>
                )}
              </div>
            </div>

            {/* 作品列表 */}
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 md:gap-6 relative z-0">
              {(() => {
                // 獲取當前頁面的項目
                const currentProjects = getCurrentPageProjects(filteredProjects);
                
                return currentProjects.map((project, index) => {
                  return (
                    <React.Fragment key={project._id}>
                      {project._showNationalityHeader && (
                        <div className="col-span-full bg-white/70 dark:bg-zinc-800/80 rounded-lg p-3 mb-2 shadow-sm
                          border border-zinc-200 dark:border-zinc-700 flex justify-between items-center">
                          <h3 className="text-lg font-bold text-zinc-800 dark:text-zinc-200">
                            {project.nationality}
                          </h3>
                          <span className="text-sm text-zinc-500 dark:text-zinc-400">
                            <span className="inline-block bg-rose-100 dark:bg-rose-900/30 text-rose-600 dark:text-rose-400 
                              px-2 py-1 rounded-lg font-bold shadow-sm border border-rose-200 dark:border-rose-800/50">
                              {filteredProjects.filter(p => p.nationality === project.nationality).length}
                            </span> 位精選妹妹
                          </span>
                        </div>
                      )}
                      
                      {/* 使用PixelCard包装作品卡片，仅当状态为"今日休息"或"今日約滿"时 */}
                      {(project.status === '今日休息' || project.status === '今日約滿') ? (
                        <PixelCard 
                          variant={project.status === '今日休息' ? 'gray' : 'red'}
                          statusText={project.status}
                          className="group cursor-pointer"
                          onClick={() => handleCardClick(project._id)}
                        >
                          <div 
                            className={`bg-white/70 dark:bg-zinc-800/80 rounded-lg shadow-card hover:shadow-hover 
                              transition-all duration-500 transform hover:-translate-y-2 hover:scale-[1.02]
                              group cursor-pointer border border-zinc-200 dark:border-zinc-700
                              relative ${clickedProjectId === project._id ? 'scale-95 pointer-events-none' : ''}`}
                          >
                            {/* 載入效果遮罩 */}
                            {clickedProjectId === project._id && (
                              <div className="absolute inset-0 bg-white/80 dark:bg-zinc-800/90 
                                backdrop-blur-sm z-50 rounded-lg flex items-center justify-center
                                transition-all duration-500 animate-pulse">
                                <div className="flex flex-col items-center transform scale-110">
                                  <div className="w-10 h-10 border-4 border-rose-500 border-t-transparent 
                                    rounded-full animate-spin"></div>
                                  <span className="mt-4 text-zinc-600 dark:text-zinc-400 text-sm font-medium">
                                    正在載入...
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* 圖片容器 */}
                            <div className="relative aspect-[3/4] w-full overflow-hidden rounded-t-lg">
                              <img
                                src={!failedImages.has(project.imageUrls?.[0] || project.imageUrl) 
                                  ? (project.imageUrls?.[0] || project.imageUrl) 
                                  : '/placeholder.png'}
                                alt={project.name}
                                className={`w-full h-full object-cover transition-all duration-700 ease-in-out
                                  group-hover:scale-110 ${clickedProjectId === project._id ? 'scale-105 blur-sm' : ''}`}
                                onError={(e) => {
                                  console.error('圖片載入失敗:', project.imageUrls?.[0] || project.imageUrl);
                                  setFailedImages(prev => new Set([...prev, project.imageUrls?.[0] || project.imageUrl]));
                                  e.preventDefault(); // 防止瀏覽器默認的重試行為
                                }}
                              />
                              {/* 添加漸變遮罩 */}
                              <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 
                                group-hover:opacity-100 transition-opacity duration-500" />
                              
                              {/* 添加價格標籤 */}
                              <div className={`absolute top-3 right-3 px-3 py-1.5 rounded-full 
                                font-bold shadow-lg transform -rotate-3 group-hover:rotate-0 transition-transform duration-300
                                group-hover:scale-110 text-white
                                ${(() => {
                                  // 根據杯型大小設置不同的顏色
                                  switch(project.breastSize) {
                                    case 'A': return 'bg-sky-500/90';
                                    case 'B': return 'bg-blue-500/90';
                                    case 'C': return 'bg-indigo-500/90';
                                    case 'D': return 'bg-violet-500/90';
                                    case 'E': return 'bg-purple-500/90';
                                    case 'F': return 'bg-fuchsia-500/90';
                                    case 'G': return 'bg-pink-500/90';
                                    case 'H': return 'bg-rose-500/90';
                                    case 'I': return 'bg-red-500/90';
                                    case 'J': return 'bg-orange-500/90';
                                    case 'K': return 'bg-amber-500/90';
                                    case 'L': return 'bg-yellow-500/90';
                                    case 'M': return 'bg-lime-500/90';
                                    default: return 'bg-gray-500/90';
                                  }
                                })()}`}>
                                ${project.price}
                              </div>
                            </div>

                            {/* 作品資訊 */}
                            <div className={`p-3 md:p-5 transition-all duration-500 
                              ${clickedProjectId === project._id ? 'opacity-50' : 'group-hover:transform group-hover:scale-[0.98]'}`}>
                              <h2 className={`text-lg md:text-2xl font-bold mb-2 md:mb-3 transition-colors duration-300
                                ${(() => {
                                  // 根據杯型大小設置不同的顏色
                                  switch(project.breastSize) {
                                    case 'A': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-sky-600 dark:group-hover:text-sky-400';
                                    case 'B': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-blue-600 dark:group-hover:text-blue-400';
                                    case 'C': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-indigo-600 dark:group-hover:text-indigo-400';
                                    case 'D': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-violet-600 dark:group-hover:text-violet-400';
                                    case 'E': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-purple-600 dark:group-hover:text-purple-400';
                                    case 'F': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-fuchsia-600 dark:group-hover:text-fuchsia-400';
                                    case 'G': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-pink-600 dark:group-hover:text-pink-400';
                                    case 'H': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-rose-600 dark:group-hover:text-rose-400';
                                    case 'I': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-red-600 dark:group-hover:text-red-400';
                                    case 'J': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-orange-600 dark:group-hover:text-orange-400';
                                    case 'K': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-amber-600 dark:group-hover:text-amber-400';
                                    case 'L': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-yellow-600 dark:group-hover:text-yellow-400';
                                    case 'M': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-lime-600 dark:group-hover:text-lime-400';
                                    default: return 'text-zinc-900 dark:text-zinc-100 group-hover:text-gray-600 dark:group-hover:text-gray-400';
                                  }
                                })()}`}>
                                {project.name}
                              </h2>
                              
                              {/* 基本資料 */}
                              <div className="grid grid-cols-2 gap-2 md:gap-3 mb-3 md:mb-4 text-sm md:text-base">
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                                  group-hover:text-zinc-900 dark:group-hover:text-zinc-100">
                                  <span className="w-4 h-4 mr-1 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
                                    </svg>
                                  </span>
                                  {project.nationality}
                                </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                                  group-hover:text-zinc-900 dark:group-hover:text-zinc-100">
                                  <span className="w-4 h-4 mr-1 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                      <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
                                      <path d="M12 6a1 1 0 0 0-1 1v5a1 1 0 0 0 .5.87l4 2.5a1 1 0 0 0 1.5-.87 1 1 0 0 0-.5-.87l-3.5-2.18V7a1 1 0 0 0-1-1z"/>
                                    </svg>
                                  </span>
                                  {project.age > 0 ? `${project.age}歲` : '暫無'}
                                </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                                  group-hover:text-zinc-900 dark:group-hover:text-zinc-100">
                                  <span className="w-4 h-4 mr-1 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                      <path d="M13 5.5C13 3.57 11.43 2 9.5 2S6 3.57 6 5.5v6.25H5V5.5C5 3.01 7.01 1 9.5 1S14 3.01 14 5.5V8h-1V5.5z"/>
                                      <path d="M20 10H4c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-9c0-1.1-.9-2-2-2zm0 11H4v-9h16v9z"/>
                                    </svg>
                                  </span>
                                  {project.height > 0 ? `${project.height}cm` : '暫無'}
                                </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                                  group-hover:text-zinc-900 dark:group-hover:text-zinc-100">
                                  <span className="w-4 h-4 mr-1 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                      <path d="M12 3a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7z"/>
                                      <path d="M12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/>
                                    </svg>
                                  </span>
                                  {project.weight > 0 ? `${project.weight}kg` : '暫無'}
                                </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                                  group-hover:text-zinc-900 dark:group-hover:text-zinc-100 min-w-0 max-w-full">
                                  <span className="w-3 h-3 mr-0.5 flex-shrink-0 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
                                      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z"/>
                                    </svg>
                                  </span>
                                  <span className="whitespace-nowrap text-2xs xs:text-xs md:text-sm font-medium truncate block" style={{fontSize: '0.65rem'}}>
                                    {project.city ? `${project.city}${project.area}` : project.area}
                                  </span>
                                </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                                  group-hover:text-zinc-900 dark:group-hover:text-zinc-100 min-w-0 max-w-full">
                                  <span className="w-3 h-3 mr-0.5 flex-shrink-0 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
                                      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"/>
                                      <path d="M7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z"/>
                                    </svg>
                                  </span>
                                  <span className="whitespace-nowrap text-2xs xs:text-xs md:text-sm font-medium truncate block" style={{fontSize: '0.65rem'}}>
                                    {project.serviceType}
                                  </span>
                                </div>
                              </div>

                              {/* 標籤 */}
                              <div className="flex flex-wrap gap-2 mb-3 md:mb-4">
                                <span className={`px-2 py-1 md:px-3 md:py-1.5 rounded-full text-xs md:text-sm 
                                  transition-all duration-300 transform group-hover:scale-105 
                                  ${project.cupSize === '人工' 
                                    ? 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200 border border-rose-200 dark:border-rose-800' 
                                    : 'bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-200 border border-emerald-200 dark:border-emerald-800'
                                  } shadow-sm flex items-center`}>
                                  <span className="w-3 h-3 mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"/>
                                      <circle cx="12" cy="12" r="4"/>
                                    </svg>
                                  </span>
                                  {project.cupSize}
                                </span>
                                <span className={`px-2 py-1 md:px-3 md:py-1.5 rounded-full text-xs md:text-sm 
                                  transition-all duration-300 transform group-hover:scale-105 
                                  ${(() => {
                                    // 根據杯型大小設置不同的顏色
                                    switch(project.breastSize) {
                                      case 'A': return 'bg-sky-100 dark:bg-sky-900 text-sky-800 dark:text-sky-200 border border-sky-200 dark:border-sky-800';
                                      case 'B': return 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 border border-blue-200 dark:border-blue-800';
                                      case 'C': return 'bg-indigo-100 dark:bg-indigo-900 text-indigo-800 dark:text-indigo-200 border border-indigo-200 dark:border-indigo-800';
                                      case 'D': return 'bg-violet-100 dark:bg-violet-900 text-violet-800 dark:text-violet-200 border border-violet-200 dark:border-violet-800';
                                      case 'E': return 'bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-200 border border-purple-200 dark:border-purple-800';
                                      case 'F': return 'bg-fuchsia-100 dark:bg-fuchsia-900 text-fuchsia-800 dark:text-fuchsia-200 border border-fuchsia-200 dark:border-fuchsia-800';
                                      case 'G': return 'bg-pink-100 dark:bg-pink-900 text-pink-800 dark:text-pink-200 border border-pink-200 dark:border-pink-800';
                                      case 'H': return 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200 border border-rose-200 dark:border-rose-800';
                                      case 'I': return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 border border-red-200 dark:border-red-800';
                                      case 'J': return 'bg-orange-100 dark:bg-orange-900 text-orange-800 dark:text-orange-200 border border-orange-200 dark:border-orange-800';
                                      case 'K': return 'bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 border border-amber-200 dark:border-amber-800';
                                      case 'L': return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200 border border-yellow-200 dark:border-yellow-800';
                                      case 'M': return 'bg-lime-100 dark:bg-lime-900 text-lime-800 dark:text-lime-200 border border-lime-200 dark:border-lime-800';
                                      default: return 'bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800';
                                    }
                                  })()}
                                  shadow-sm flex items-center`}>
                                  <span className="w-3 h-3 mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/>
                                      <circle cx="12" cy="12" r="3"/>
                                    </svg>
                                  </span>
                                  {project.breastSize} Cup
                                </span>
                                {project.isHot && (
                                  <span className="px-2 py-1 md:px-3 md:py-1.5 rounded-full text-xs md:text-sm
                                    transition-all duration-300 transform group-hover:scale-105 
                                    bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 
                                    border border-blue-200 dark:border-blue-800 shadow-sm
                                    flex items-center animate-pulse">
                                    <span className="w-3 h-3 mr-1">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                                      </svg>
                                    </span>
                                    熱門
                                  </span>
                                )}
                              </div>

                              {/* 狀態 */}
                              <div className="flex justify-end items-center">
                                <span className={`px-2 py-1 md:px-3 md:py-1.5 rounded-full text-xs md:text-sm
                                  transition-all duration-300 transform group-hover:scale-105 
                                  border shadow-sm flex items-center
                                  ${project.status === '可預約' || project.status === '尚可預約'
                                    ? 'bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-200 border-emerald-200 dark:border-emerald-800 animate-blink-slow'
                                    : project.status === '今日約滿'
                                    ? 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200 border-rose-200 dark:border-rose-800'
                                    : 'bg-zinc-300 dark:bg-zinc-700 text-zinc-700 dark:text-zinc-300 border-zinc-200 dark:border-zinc-600'
                                  }`}>
                                  <span className="w-3 h-3 mr-1">
                                    {project.status === '可預約' || project.status === '尚可預約' ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                                      </svg>
                                    ) : project.status === '今日約滿' ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59z"/>
                                      </svg>
                                    ) : (
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
                                        <path d="M7 15h10v2H7z"/>
                                      </svg>
                                    )}
                                  </span>
                                  {project.status}
                                </span>
                              </div>
                            </div>
                          </div>
                        </PixelCard>
                      ) : (
                      <div 
                        onClick={() => handleCardClick(project._id)}
                        className={`bg-white/70 dark:bg-zinc-800/80 rounded-lg shadow-card hover:shadow-hover 
                          transition-all duration-500 transform hover:-translate-y-2 hover:scale-[1.02]
                          group cursor-pointer border border-zinc-200 dark:border-zinc-700
                          relative ${clickedProjectId === project._id ? 'scale-95 pointer-events-none' : ''}`}
                      >
                        {/* 載入效果遮罩 */}
                        {clickedProjectId === project._id && (
                          <div className="absolute inset-0 bg-white/80 dark:bg-zinc-800/90 
                            backdrop-blur-sm z-50 rounded-lg flex items-center justify-center
                            transition-all duration-500 animate-pulse">
                            <div className="flex flex-col items-center transform scale-110">
                              <div className="w-10 h-10 border-4 border-rose-500 border-t-transparent 
                                rounded-full animate-spin"></div>
                              <span className="mt-4 text-zinc-600 dark:text-zinc-400 text-sm font-medium">
                                  正在載入...
                              </span>
                            </div>
                          </div>
                        )}

                        {/* 圖片容器 */}
                        <div className="relative aspect-[3/4] w-full overflow-hidden rounded-t-lg">
                          <img
                            src={!failedImages.has(project.imageUrls?.[0] || project.imageUrl) 
                              ? (project.imageUrls?.[0] || project.imageUrl) 
                              : '/placeholder.png'}
                            alt={project.name}
                            className={`w-full h-full object-cover transition-all duration-700 ease-in-out
                              group-hover:scale-110 ${clickedProjectId === project._id ? 'scale-105 blur-sm' : ''}`}
                            onError={(e) => {
                              console.error('圖片載入失敗:', project.imageUrls?.[0] || project.imageUrl);
                              setFailedImages(prev => new Set([...prev, project.imageUrls?.[0] || project.imageUrl]));
                              e.preventDefault(); // 防止瀏覽器默認的重試行為
                            }}
                          />
                          {/* 添加漸變遮罩 */}
                            <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 
                            group-hover:opacity-100 transition-opacity duration-500" />
                              
                            {/* 添加價格標籤 */}
                            <div className={`absolute top-3 right-3 px-3 py-1.5 rounded-full 
                              font-bold shadow-lg transform -rotate-3 group-hover:rotate-0 transition-transform duration-300
                              group-hover:scale-110 text-white
                              ${(() => {
                                // 根據杯型大小設置不同的顏色
                                switch(project.breastSize) {
                                  case 'A': return 'bg-sky-500/90';
                                  case 'B': return 'bg-blue-500/90';
                                  case 'C': return 'bg-indigo-500/90';
                                  case 'D': return 'bg-violet-500/90';
                                  case 'E': return 'bg-purple-500/90';
                                  case 'F': return 'bg-fuchsia-500/90';
                                  case 'G': return 'bg-pink-500/90';
                                  case 'H': return 'bg-rose-500/90';
                                  case 'I': return 'bg-red-500/90';
                                  case 'J': return 'bg-orange-500/90';
                                  case 'K': return 'bg-amber-500/90';
                                  case 'L': return 'bg-yellow-500/90';
                                  case 'M': return 'bg-lime-500/90';
                                  default: return 'bg-gray-500/90';
                                }
                              })()}`}>
                              ${project.price}
                            </div>
                        </div>

                        {/* 作品資訊 */}
                        <div className={`p-3 md:p-5 transition-all duration-500 
                          ${clickedProjectId === project._id ? 'opacity-50' : 'group-hover:transform group-hover:scale-[0.98]'}`}>
                          <h2 className={`text-lg md:text-2xl font-bold mb-2 md:mb-3 transition-colors duration-300
                            ${(() => {
                              // 根據杯型大小設置不同的顏色
                              switch(project.breastSize) {
                                case 'A': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-sky-600 dark:group-hover:text-sky-400';
                                case 'B': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-blue-600 dark:group-hover:text-blue-400';
                                case 'C': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-indigo-600 dark:group-hover:text-indigo-400';
                                case 'D': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-violet-600 dark:group-hover:text-violet-400';
                                case 'E': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-purple-600 dark:group-hover:text-purple-400';
                                case 'F': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-fuchsia-600 dark:group-hover:text-fuchsia-400';
                                case 'G': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-pink-600 dark:group-hover:text-pink-400';
                                case 'H': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-rose-600 dark:group-hover:text-rose-400';
                                case 'I': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-red-600 dark:group-hover:text-red-400';
                                case 'J': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-orange-600 dark:group-hover:text-orange-400';
                                case 'K': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-amber-600 dark:group-hover:text-amber-400';
                                case 'L': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-yellow-600 dark:group-hover:text-yellow-400';
                                case 'M': return 'text-zinc-900 dark:text-zinc-100 group-hover:text-lime-600 dark:group-hover:text-lime-400';
                                default: return 'text-zinc-900 dark:text-zinc-100 group-hover:text-gray-600 dark:group-hover:text-gray-400';
                              }
                            })()}`}>
                            {project.name}
                          </h2>
                          
                          {/* 基本資料 */}
                          <div className="grid grid-cols-2 gap-2 md:gap-3 mb-3 md:mb-4 text-sm md:text-base">
                              <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                              group-hover:text-zinc-900 dark:group-hover:text-zinc-100">
                                  <span className="w-4 h-4 mr-1 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
                                    </svg>
                                  </span>
                              {project.nationality}
                            </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                              group-hover:text-zinc-900 dark:group-hover:text-zinc-100">
                                  <span className="w-4 h-4 mr-1 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                      <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
                                      <path d="M12 6a1 1 0 0 0-1 1v5a1 1 0 0 0 .5.87l4 2.5a1 1 0 0 0 1.5-.87 1 1 0 0 0-.5-.87l-3.5-2.18V7a1 1 0 0 0-1-1z"/>
                                    </svg>
                                  </span>
                                  {project.age > 0 ? `${project.age}歲` : '暫無'}
                                </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                              group-hover:text-zinc-900 dark:group-hover:text-zinc-100">
                                  <span className="w-4 h-4 mr-1 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                      <path d="M13 5.5C13 3.57 11.43 2 9.5 2S6 3.57 6 5.5v6.25H5V5.5C5 3.01 7.01 1 9.5 1S14 3.01 14 5.5V8h-1V5.5z"/>
                                      <path d="M20 10H4c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-9c0-1.1-.9-2-2-2zm0 11H4v-9h16v9z"/>
                                    </svg>
                                  </span>
                              {project.height > 0 ? `${project.height}cm` : '暫無'}
                            </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                              group-hover:text-zinc-900 dark:group-hover:text-zinc-100">
                                  <span className="w-4 h-4 mr-1 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                      <path d="M12 3a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7z"/>
                                      <path d="M12 8a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/>
                                    </svg>
                                  </span>
                                  {project.weight > 0 ? `${project.weight}kg` : '暫無'}
                                </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                                  group-hover:text-zinc-900 dark:group-hover:text-zinc-100 min-w-0 max-w-full">
                                  <span className="w-3 h-3 mr-0.5 flex-shrink-0 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
                                      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z"/>
                                    </svg>
                                  </span>
                                  <span className="whitespace-nowrap text-2xs xs:text-xs md:text-sm font-medium truncate block" style={{fontSize: '0.65rem'}}>
                                    {project.city ? `${project.city}${project.area}` : project.area}
                                  </span>
                                </div>
                                <div className="flex items-center text-zinc-700 dark:text-zinc-200 transition-colors duration-300
                              group-hover:text-zinc-900 dark:group-hover:text-zinc-100 min-w-0 max-w-full">
                                  <span className="w-3 h-3 mr-0.5 flex-shrink-0 text-rose-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-full h-full">
                                      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"/>
                                      <path d="M7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z"/>
                                    </svg>
                                  </span>
                                  <span className="whitespace-nowrap text-2xs xs:text-xs md:text-sm font-medium truncate block" style={{fontSize: '0.65rem'}}>
                                    {project.serviceType}
                                  </span>
                                </div>
                          </div>

                          {/* 標籤 */}
                              <div className="flex flex-wrap gap-2 mb-3 md:mb-4">
                            <span className={`px-2 py-1 md:px-3 md:py-1.5 rounded-full text-xs md:text-sm 
                                  transition-all duration-300 transform group-hover:scale-105 
                                  ${project.cupSize === '人工' 
                                    ? 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200 border border-rose-200 dark:border-rose-800' 
                                    : 'bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-200 border border-emerald-200 dark:border-emerald-800'
                                  } shadow-sm flex items-center`}>
                                  <span className="w-3 h-3 mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"/>
                                      <circle cx="12" cy="12" r="4"/>
                                    </svg>
                                  </span>
                              {project.cupSize}
                            </span>
                                <span className={`px-2 py-1 md:px-3 md:py-1.5 rounded-full text-xs md:text-sm 
                                  transition-all duration-300 transform group-hover:scale-105 
                                  ${(() => {
                                    // 根據杯型大小設置不同的顏色
                                    switch(project.breastSize) {
                                      case 'A': return 'bg-sky-100 dark:bg-sky-900 text-sky-800 dark:text-sky-200 border border-sky-200 dark:border-sky-800';
                                      case 'B': return 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 border border-blue-200 dark:border-blue-800';
                                      case 'C': return 'bg-indigo-100 dark:bg-indigo-900 text-indigo-800 dark:text-indigo-200 border border-indigo-200 dark:border-indigo-800';
                                      case 'D': return 'bg-violet-100 dark:bg-violet-900 text-violet-800 dark:text-violet-200 border border-violet-200 dark:border-violet-800';
                                      case 'E': return 'bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-200 border border-purple-200 dark:border-purple-800';
                                      case 'F': return 'bg-fuchsia-100 dark:bg-fuchsia-900 text-fuchsia-800 dark:text-fuchsia-200 border border-fuchsia-200 dark:border-fuchsia-800';
                                      case 'G': return 'bg-pink-100 dark:bg-pink-900 text-pink-800 dark:text-pink-200 border border-pink-200 dark:border-pink-800';
                                      case 'H': return 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200 border border-rose-200 dark:border-rose-800';
                                      case 'I': return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 border border-red-200 dark:border-red-800';
                                      case 'J': return 'bg-orange-100 dark:bg-orange-900 text-orange-800 dark:text-orange-200 border border-orange-200 dark:border-orange-800';
                                      case 'K': return 'bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 border border-amber-200 dark:border-amber-800';
                                      case 'L': return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200 border border-yellow-200 dark:border-yellow-800';
                                      case 'M': return 'bg-lime-100 dark:bg-lime-900 text-lime-800 dark:text-lime-200 border border-lime-200 dark:border-lime-800';
                                      default: return 'bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800';
                                    }
                                  })()}
                                  shadow-sm flex items-center`}>
                                  <span className="w-3 h-3 mr-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"/>
                                      <circle cx="12" cy="12" r="3"/>
                                    </svg>
                                  </span>
                                  {project.breastSize} Cup
                                </span>
                                {project.isHot && (
                                  <span className="px-2 py-1 md:px-3 md:py-1.5 rounded-full text-xs md:text-sm
                                    transition-all duration-300 transform group-hover:scale-105 
                                    bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 
                                    border border-blue-200 dark:border-blue-800 shadow-sm
                                    flex items-center animate-pulse">
                                    <span className="w-3 h-3 mr-1">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                                      </svg>
                                    </span>
                                    熱門
                                  </span>
                                )}
                          </div>

                              {/* 狀態 */}
                              <div className="flex justify-end items-center">
                            <span className={`px-2 py-1 md:px-3 md:py-1.5 rounded-full text-xs md:text-sm
                              transition-all duration-300 transform group-hover:scale-105
                                  border shadow-sm flex items-center
                              ${project.status === '可預約' || project.status === '尚可預約'
                                    ? 'bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-200 border-emerald-200 dark:border-emerald-800 animate-blink-slow'
                                : project.status === '今日約滿'
                                    ? 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200 border-rose-200 dark:border-rose-800'
                                    : 'bg-zinc-300 dark:bg-zinc-700 text-zinc-700 dark:text-zinc-300 border-zinc-200 dark:border-zinc-600'
                                  }`}>
                                  <span className="w-3 h-3 mr-1">
                                    {project.status === '可預約' || project.status === '尚可預約' ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                                      </svg>
                                    ) : project.status === '今日約滿' ? (
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59z"/>
                                      </svg>
                                    ) : (
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
                                        <path d="M7 15h10v2H7z"/>
                                      </svg>
                                    )}
                                  </span>
                                  {project.status}
                            </span>
                          </div>
                        </div>
                      </div>
                        )}
                    </React.Fragment>
                  );
                });
              })()}
            </div>
            
            {/* 分頁控制 */}
            {totalPages > 1 && (
              <div className="flex flex-col sm:flex-row justify-center items-center gap-4 mt-8 mb-6">
                <button
                  onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                  disabled={currentPage === 1}
                  className={`w-full sm:w-auto px-6 py-2.5 rounded-lg flex items-center justify-center gap-2 transition-all duration-200 ${
                    currentPage === 1
                      ? 'bg-zinc-100 dark:bg-zinc-800 text-zinc-400 dark:text-zinc-600 cursor-not-allowed'
                      : 'bg-white dark:bg-zinc-800 text-zinc-700 dark:text-zinc-300 hover:bg-zinc-50 dark:hover:bg-zinc-700 border border-zinc-200 dark:border-zinc-700 hover:shadow-md'
                  }`}
                  aria-label="上一頁"
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                  <span>上一頁</span>
                </button>
                
                <div className="flex items-center gap-2 text-zinc-600 dark:text-zinc-400 bg-white/80 dark:bg-zinc-800/80 px-4 py-2 rounded-lg border border-zinc-200 dark:border-zinc-700">
                  <span className="hidden sm:inline">第</span>
                  <span className="font-medium text-zinc-800 dark:text-zinc-200">{currentPage}</span>
                  <span className="hidden sm:inline">頁</span>
                  <span>/</span>
                  <span className="hidden sm:inline">共</span>
                  <span className="font-medium text-zinc-800 dark:text-zinc-200">{totalPages}</span>
                  <span className="hidden sm:inline">頁</span>
          </div>

                <button
                  onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className={`w-full sm:w-auto px-6 py-2.5 rounded-lg flex items-center justify-center gap-2 transition-all duration-200 ${
                    currentPage === totalPages
                      ? 'bg-zinc-100 dark:bg-zinc-800 text-zinc-400 dark:text-zinc-600 cursor-not-allowed'
                      : 'bg-white dark:bg-zinc-800 text-zinc-700 dark:text-zinc-300 hover:bg-zinc-50 dark:hover:bg-zinc-700 border border-zinc-200 dark:border-zinc-700 hover:shadow-md'
                  }`}
                  aria-label="下一頁"
                >
                  <span>下一頁</span>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </BackgroundEffect>
  );
}

export default Projects; 