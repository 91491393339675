import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import ThemeToggle from './ThemeToggle';
import { memberApi } from '../utils/api';

// 閃爍文字效果組件 - 使用Tailwind CSS實現
const GlitchText = ({
  children,
  speed = 0.5,
  enableShadows = true,
  enableOnHover = false,
  className = "",
}) => {
  const inlineStyles = {
    "--after-duration": `${speed * 3}s`,
    "--before-duration": `${speed * 2}s`,
    "--after-shadow": enableShadows ? "-3px 0 #ff0055" : "none",
    "--before-shadow": enableShadows ? "3px 0 #00ddff" : "none",
  };

  const baseClasses =
    "text-inherit font-bold relative select-none cursor-pointer";

  // 增加文字本身的陰影，使其更加清晰
  const textShadowClass = "text-shadow-lg [text-shadow:0_0_2px_rgba(255,255,255,0.7)]";

  const pseudoClasses = !enableOnHover
    ? 
      "after:content-[attr(data-text)] after:absolute after:top-0 after:left-[5px] after:text-inherit after:bg-inherit after:overflow-hidden after:[clip-path:inset(0_0_0_0)] after:[text-shadow:var(--after-shadow)] after:animate-glitch-after " +
      "before:content-[attr(data-text)] before:absolute before:top-0 before:left-[-5px] before:text-inherit before:bg-inherit before:overflow-hidden before:[clip-path:inset(0_0_0_0)] before:[text-shadow:var(--before-shadow)] before:animate-glitch-before"
    : 
      "after:content-[''] after:absolute after:top-0 after:left-[5px] after:text-inherit after:bg-inherit after:overflow-hidden after:[clip-path:inset(0_0_0_0)] after:opacity-0 " +
      "before:content-[''] before:absolute before:top-0 before:left-[-5px] before:text-inherit before:bg-inherit before:overflow-hidden before:[clip-path:inset(0_0_0_0)] before:opacity-0 " +
      "hover:after:content-[attr(data-text)] hover:after:opacity-100 hover:after:[text-shadow:var(--after-shadow)] hover:after:animate-glitch-after " +
      "hover:before:content-[attr(data-text)] hover:before:opacity-100 hover:before:[text-shadow:var(--before-shadow)] hover:before:animate-glitch-before";

  const combinedClasses = `${baseClasses} ${textShadowClass} ${pseudoClasses} ${className}`;

  return (
    <div style={inlineStyles} data-text={children} className={combinedClasses}>
      {children}
    </div>
  );
};

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [prevLoginState, setPrevLoginState] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [navHeight, setNavHeight] = useState(isMobile ? 130 : 64);
  const navRef = React.useRef(null);
  const location = useLocation();

  // 檢測導航欄高度
  useEffect(() => {
    const updateNavHeight = () => {
      if (navRef.current) {
        const height = navRef.current.offsetHeight;
        // 為手機版添加額外間距，確保不會遮擋內容
        setNavHeight(height + (isMobile ? 15 : 0));
      }
    };
    
    // 初始加載時更新高度
    updateNavHeight();
    
    // 使用 MutationObserver 監聽導航欄內容變化
    const observer = new MutationObserver(updateNavHeight);
    
    if (navRef.current) {
      observer.observe(navRef.current, { 
        childList: true, 
        subtree: true,
        attributes: true,
        characterData: true
      });
    }
    
    // 在組件卸載時清理
    return () => {
      observer.disconnect();
    };
  }, [isMobile]);

  // 在頁面加載和窗口大小變化時更新高度
  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth < 768;
      setIsMobile(newIsMobile);
      
      // 延遲更新高度，確保DOM已經渲染完成
      setTimeout(() => {
        if (navRef.current) {
          const height = navRef.current.offsetHeight;
          // 為手機版添加更多額外間距
          setNavHeight(height + (newIsMobile ? 25 : 0));
        }
      }, 200);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);
    
    // 初始加載後再次檢查高度
    setTimeout(handleResize, 500);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);

  // 添加一個額外的效果，確保在頁面加載後更新高度
  useEffect(() => {
    // 頁面加載後多次檢查高度，確保正確計算
    const checkHeightMultipleTimes = () => {
      if (navRef.current) {
        const height = navRef.current.offsetHeight;
        setNavHeight(height + (isMobile ? 25 : 0));
      }
    };

    // 在不同時間點檢查高度，確保捕獲任何變化
    const timers = [
      setTimeout(checkHeightMultipleTimes, 100),
      setTimeout(checkHeightMultipleTimes, 500),
      setTimeout(checkHeightMultipleTimes, 1000),
      setTimeout(checkHeightMultipleTimes, 2000)
    ];

    return () => {
      timers.forEach(timer => clearTimeout(timer));
    };
  }, [isMobile, location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      
      // 只控制背景效果
      setScrolled(currentScrollPos > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // 檢查登入狀態
  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = localStorage.getItem('memberToken');
      
      if (token) {
        try {
          const response = await memberApi.get('/member/profile');
          // 如果之前未登入，現在登入了，設置動畫標誌
          if (!isLoggedIn) {
            setIsAnimating(true);
            setTimeout(() => setIsAnimating(false), 1000);
          }
          setIsLoggedIn(true);
          console.log('前台用戶登入狀態: 已登入', '用戶資料:', response.data);
        } catch (error) {
          console.error('驗證用戶狀態失敗:', error);
          // 如果之前已登入，現在登出了，設置動畫標誌
          if (isLoggedIn) {
            setIsAnimating(true);
            setTimeout(() => setIsAnimating(false), 1000);
          }
          setIsLoggedIn(false);
          localStorage.removeItem('memberToken');
        }
      } else {
        // 如果之前已登入，現在登出了，設置動畫標誌
        if (isLoggedIn) {
          setIsAnimating(true);
          setTimeout(() => setIsAnimating(false), 1000);
        }
        setIsLoggedIn(false);
        console.log('未找到token，設置為未登入狀態');
      }
      
      // 保存當前登入狀態以便下次比較
      setPrevLoginState(isLoggedIn);
    };

    checkLoginStatus();
    window.addEventListener('storage', checkLoginStatus);
    
    return () => {
      window.removeEventListener('storage', checkLoginStatus);
    };
  }, [location.pathname, isLoggedIn]);

  const buttonBaseClass = `
    text-zinc-600 dark:text-zinc-400 
    px-3 py-2 rounded 
    hover:text-zinc-900 dark:hover:text-white 
    hover:bg-zinc-100 dark:hover:bg-zinc-800 
    transition-all duration-300
    transform
  `;

  // 按鈕動畫變體
  const buttonVariants = {
    initial: { 
      scale: 1,
      backgroundColor: "rgba(255, 255, 255, 0)",
      color: ""
    },
    hover: { 
      scale: 1.05,
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
      y: -2,
      transition: { 
        type: "spring", 
        stiffness: 400, 
        damping: 10 
      }
    },
    tap: { 
      scale: 0.95,
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
      y: 0,
      transition: { 
        type: "spring", 
        stiffness: 500, 
        damping: 15,
        duration: 0.1
      }
    }
  };

  // 文字動畫變體
  const textVariants = {
    initial: {
      textShadow: "0px 0px 0px rgba(255, 255, 255, 0)",
    },
    hover: {
      textShadow: "0px 0px 8px rgba(255, 255, 255, 0.5)",
      transition: { duration: 0.3 }
    },
    tap: {
      textShadow: "0px 0px 2px rgba(255, 255, 255, 0.3)",
      scale: 0.98,
      transition: { duration: 0.1 }
    }
  };

  return (
    <>
      {/* 導航欄佔位元素，用於防止內容被固定導航欄遮擋 */}
      <div style={{ height: `${navHeight}px` }} className="w-full"></div>
      
      <nav 
        ref={navRef}
        className={`fixed top-0 left-0 right-0 z-20 transition-all duration-300
          ${scrolled 
            ? 'bg-white/90 dark:bg-zinc-900/90 backdrop-blur-sm shadow-lg' 
            : 'bg-white dark:bg-zinc-900'
          }`}
        style={{ maxHeight: isMobile ? '130px' : 'none' }}
      >
        <div className="container mx-auto px-4">
          {isMobile ? (
            // 手機版導航欄 - 分為兩行
            <div className="flex flex-col py-1">
              {/* 第一行 - 網站標題 */}
              <div className="flex justify-center items-center py-1">
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Link 
                    to="/" 
                    className="text-zinc-800 dark:text-zinc-200 text-lg font-bold hover:text-zinc-600 dark:hover:text-white 
                      transition-all duration-300"
                  >
                    <GlitchText 
                      speed={0.8} 
                      enableShadows={true} 
                      enableOnHover={false} 
                      className="text-lg"
                    >
                      海派嚴選
                    </GlitchText>
                  </Link>
                </motion.div>
              </div>
              
              {/* 第二行 - 導航按鈕 */}
              <div className="flex justify-center items-center space-x-2 py-1">
                <motion.div
                  initial="initial"
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                  className="overflow-hidden rounded"
                >
                  <NavLink 
                    to="/projects"
                    className={({ isActive }) =>
                      `text-zinc-600 dark:text-zinc-300 px-2 py-1 rounded transition-all duration-300 relative group ${
                        isActive 
                          ? 'bg-zinc-100 dark:bg-zinc-800 text-zinc-900 dark:text-white' 
                          : 'hover:bg-zinc-100 dark:hover:bg-zinc-800 hover:text-zinc-900 dark:hover:text-white'
                      }`
                    }
                  >
                    <motion.span 
                      variants={textVariants}
                      initial="initial"
                      whileHover="hover"
                      whileTap="tap" 
                      className="relative z-10 text-sm"
                    >
                      圖鑑
                      <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-500 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                    </motion.span>
                    <motion.span 
                      className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded"
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 0 }}
                      whileHover={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    ></motion.span>
                  </NavLink>
                </motion.div>
                
                <motion.div
                  initial="initial"
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                  className="overflow-hidden rounded"
                >
                  {isLoggedIn ? (
                    <Link 
                      to="/profile" 
                      className={`text-zinc-600 dark:text-zinc-400 px-2 py-1 rounded hover:text-zinc-900 dark:hover:text-white 
                        hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-all duration-300 relative group`}
                    >
                      <motion.span 
                        variants={textVariants}
                        initial="initial"
                        whileHover="hover"
                        whileTap="tap"
                        className="relative z-10 text-sm"
                        animate={isAnimating ? { 
                          scale: [1, 1.2, 1],
                          color: ['', '#3B82F6', ''],
                          transition: { duration: 0.5 }
                        } : {}}
                      >
                        我的
                        <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-500 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                      </motion.span>
                      <motion.span 
                        className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={isAnimating ? { 
                          scale: [0.8, 1.1, 1],
                          opacity: [0, 0.8, 0],
                          transition: { duration: 0.5 }
                        } : { scale: 1, opacity: 0 }}
                        whileHover={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      ></motion.span>
                    </Link>
                  ) : (
                    <Link 
                      to="/login" 
                      className={`text-zinc-600 dark:text-zinc-400 px-2 py-1 rounded hover:text-zinc-900 dark:hover:text-white 
                        hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-all duration-300 relative group`}
                    >
                      <motion.span 
                        variants={textVariants}
                        initial="initial"
                        whileHover="hover"
                        whileTap="tap"
                        className="relative z-10 text-sm"
                        animate={isAnimating ? { 
                          scale: [1, 1.2, 1],
                          color: ['', '#3B82F6', ''],
                          transition: { duration: 0.5 }
                        } : {}}
                      >
                        登入
                        <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-500 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                      </motion.span>
                      <motion.span 
                        className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={isAnimating ? { 
                          scale: [0.8, 1.1, 1],
                          opacity: [0, 0.8, 0],
                          transition: { duration: 0.5 }
                        } : { scale: 1, opacity: 0 }}
                        whileHover={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      ></motion.span>
                    </Link>
                  )}
                </motion.div>
                
                <motion.div
                  initial="initial"
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                  className="overflow-hidden rounded"
                >
                  <Link 
                    to="/contact" 
                    className="text-zinc-600 dark:text-zinc-400 px-2 py-1 rounded hover:text-zinc-900 dark:hover:text-white 
                      hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-all duration-300 relative group"
                  >
                    <motion.span 
                      variants={textVariants}
                      initial="initial"
                      whileHover="hover"
                      whileTap="tap" 
                      className="relative z-10 text-sm"
                    >
                      聯絡
                      <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-500 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                    </motion.span>
                    <motion.span 
                      className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded"
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 0 }}
                      whileHover={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    ></motion.span>
                  </Link>
                </motion.div>
                
                <ThemeToggle className="ml-1" />
              </div>
            </div>
          ) : (
            // 桌面版導航欄 - 保持原樣
            <div className={`flex justify-between items-center transition-all duration-300
              ${scrolled ? 'h-14' : 'h-16'}`}
            >
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Link 
                  to="/" 
                  className="text-zinc-800 dark:text-zinc-200 text-xl font-bold hover:text-zinc-600 dark:hover:text-white 
                    transition-all duration-300"
                >
                  <GlitchText 
                    speed={0.8} 
                    enableShadows={true} 
                    enableOnHover={false} 
                    className="text-2xl"
                  >
                    海派嚴選
                  </GlitchText>
                </Link>
              </motion.div>
              
              <div className="flex items-center space-x-4">
                <motion.div
                  initial="initial"
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                  className="overflow-hidden rounded"
                >
                  <NavLink 
                    to="/projects"
                    className={({ isActive }) =>
                      `text-zinc-600 dark:text-zinc-300 px-3 py-2 rounded transition-all duration-300 relative group ${
                        isActive 
                          ? 'bg-zinc-100 dark:bg-zinc-800 text-zinc-900 dark:text-white' 
                          : 'hover:bg-zinc-100 dark:hover:bg-zinc-800 hover:text-zinc-900 dark:hover:text-white'
                      }`
                    }
                  >
                    <motion.span 
                      variants={textVariants}
                      initial="initial"
                      whileHover="hover"
                      whileTap="tap" 
                      className="relative z-10"
                    >
                      圖鑑
                      <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-500 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                    </motion.span>
                    <motion.span 
                      className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded"
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 0 }}
                      whileHover={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    ></motion.span>
                  </NavLink>
                </motion.div>
                
                <motion.div
                  initial="initial"
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                  className="overflow-hidden rounded"
                >
                  {isLoggedIn ? (
                    <Link 
                      to="/profile" 
                      className={`text-zinc-600 dark:text-zinc-400 px-3 py-2 rounded hover:text-zinc-900 dark:hover:text-white 
                        hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-all duration-300 relative group`}
                    >
                      <motion.span 
                        variants={textVariants}
                        initial="initial"
                        whileHover="hover"
                        whileTap="tap"
                        className="relative z-10"
                        animate={isAnimating ? { 
                          scale: [1, 1.2, 1],
                          color: ['', '#3B82F6', ''],
                          transition: { duration: 0.5 }
                        } : {}}
                      >
                        我的
                        <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-500 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                      </motion.span>
                      <motion.span 
                        className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={isAnimating ? { 
                          scale: [0.8, 1.1, 1],
                          opacity: [0, 0.8, 0],
                          transition: { duration: 0.5 }
                        } : { scale: 1, opacity: 0 }}
                        whileHover={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      ></motion.span>
                    </Link>
                  ) : (
                    <Link 
                      to="/login" 
                      className={`text-zinc-600 dark:text-zinc-400 px-3 py-2 rounded hover:text-zinc-900 dark:hover:text-white 
                        hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-all duration-300 relative group`}
                    >
                      <motion.span 
                        variants={textVariants}
                        initial="initial"
                        whileHover="hover"
                        whileTap="tap"
                        className="relative z-10"
                        animate={isAnimating ? { 
                          scale: [1, 1.2, 1],
                          color: ['', '#3B82F6', ''],
                          transition: { duration: 0.5 }
                        } : {}}
                      >
                        登入
                        <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-500 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                      </motion.span>
                      <motion.span 
                        className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={isAnimating ? { 
                          scale: [0.8, 1.1, 1],
                          opacity: [0, 0.8, 0],
                          transition: { duration: 0.5 }
                        } : { scale: 1, opacity: 0 }}
                        whileHover={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.3 }}
                      ></motion.span>
                    </Link>
                  )}
                </motion.div>
                
                <motion.div
                  initial="initial"
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                  className="overflow-hidden rounded"
                >
                  <Link 
                    to="/contact" 
                    className="text-zinc-600 dark:text-zinc-400 px-3 py-2 rounded hover:text-zinc-900 dark:hover:text-white 
                      hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-all duration-300 relative group"
                  >
                    <motion.span 
                      variants={textVariants}
                      initial="initial"
                      whileHover="hover"
                      whileTap="tap" 
                      className="relative z-10"
                    >
                      聯絡
                      <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-500 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                    </motion.span>
                    <motion.span 
                      className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-indigo-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded"
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 0 }}
                      whileHover={{ scale: 1, opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    ></motion.span>
                  </Link>
                </motion.div>
                
                <ThemeToggle className="ml-2" />
              </div>
            </div>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;