import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { memberApi } from '../utils/api';
import axios from 'axios';
import { API_DOMAIN } from '../utils/api';

function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mediaType, setMediaType] = useState('image');
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showComments, setShowComments] = useState(true); // 新增評論區顯示控制狀態

  // 評論相關狀態
  const [comments, setComments] = useState([]);
  const [commentContent, setCommentContent] = useState('');
  const [currentRating, setCurrentRating] = useState(5);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState('date');
  const [replyContent, setReplyContent] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);
  const commentsPerPage = 10;
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [sortOption, setSortOption] = useState('latest');
  const [isLoadingCommentSetting, setIsLoadingCommentSetting] = useState(true);

  // 根據會員等級返回對應的顏色類
  const getMemberLevelColor = (identityGroup) => {
    if (!identityGroup) return 'from-gray-400/20 to-gray-500/20 text-gray-700 dark:text-gray-300 border-gray-400/30 dark:border-gray-500/30';
    
    // 提取等級數字
    const levelMatch = identityGroup.match(/Lv\.(\d+|[\?]+)/i);
    
    // 特殊身份處理
    if (identityGroup === '惡意評論的哥布林') {
      return 'from-gray-400/20 to-gray-500/20 text-gray-700 dark:text-gray-300 border-gray-400/30 dark:border-gray-500/30';
    }
    
    if (!levelMatch) return 'from-gray-400/20 to-gray-500/20 text-gray-700 dark:text-gray-300 border-gray-400/30 dark:border-gray-500/30';
    
    const level = levelMatch[1];
    
    // 根據等級範圍返回不同顏色
    if (level === '???' || level === '？？？') {
      return 'from-purple-400/20 to-purple-500/20 text-purple-700 dark:text-purple-300 border-purple-400/30 dark:border-purple-500/30';
    } else {
      if (level === '??') {
        return 'from-amber-400/20 to-amber-500/20 text-amber-700 dark:text-amber-300 border-amber-400/30 dark:border-amber-500/30';
      } else {
        const levelNum = parseInt(level, 10);
        if (levelNum >= 0 && levelNum <= 3) {
          return 'from-green-400/20 to-green-500/20 text-green-700 dark:text-green-300 border-green-400/30 dark:border-green-500/30';
        } else if (levelNum >= 4 && levelNum <= 7) {
          return 'from-blue-400/20 to-blue-500/20 text-blue-700 dark:text-blue-300 border-blue-400/30 dark:border-blue-500/30';
        } else if (levelNum >= 8 && levelNum <= 11) {
          return 'from-yellow-400/20 to-yellow-500/20 text-yellow-700 dark:text-yellow-300 border-yellow-400/30 dark:border-yellow-500/30';
        } else if (levelNum >= 12 && levelNum <= 15) {
          return 'from-rose-400/20 to-rose-500/20 text-rose-700 dark:text-rose-300 border-rose-400/30 dark:border-rose-500/30';
        }
      }
    }
    
    // 默認顏色
    return 'from-gray-400/20 to-gray-500/20 text-gray-700 dark:text-gray-300 border-gray-400/30 dark:border-gray-500/30';
  };

  // 獲取會員等級的文字漸變顏色
  const getMemberLevelTextGradient = (identityGroup) => {
    if (!identityGroup) return 'from-gray-500 to-gray-700 dark:from-gray-400 dark:to-gray-200';
    
    // 提取等級數字
    const levelMatch = identityGroup.match(/Lv\.(\d+|[\?]+)/i);
    
    // 特殊身份處理
    if (identityGroup === '惡意評論的哥布林') {
      return 'from-gray-500 to-gray-700 dark:from-gray-400 dark:to-gray-200';
    }
    
    if (!levelMatch) return 'from-gray-500 to-gray-700 dark:from-gray-400 dark:to-gray-200';
    
    const level = levelMatch[1];
    
    // 根據等級範圍返回不同顏色
    if (level === '???' || level === '？？？') {
      return 'from-purple-500 to-purple-700 dark:from-purple-400 dark:to-purple-200';
    } else {
      if (level === '??') {
        return 'from-amber-500 to-amber-700 dark:from-amber-400 dark:to-amber-200';
      } else {
        const levelNum = parseInt(level, 10);
        if (levelNum >= 0 && levelNum <= 3) {
          return 'from-green-500 to-green-700 dark:from-green-400 dark:to-green-200';
        } else if (levelNum >= 4 && levelNum <= 7) {
          return 'from-blue-500 to-blue-700 dark:from-blue-400 dark:to-blue-200';
        } else if (levelNum >= 8 && levelNum <= 11) {
          return 'from-yellow-500 to-yellow-700 dark:from-yellow-400 dark:to-yellow-200';
        } else if (levelNum >= 12 && levelNum <= 15) {
          return 'from-rose-500 to-rose-700 dark:from-rose-400 dark:to-rose-200';
        }
      }
    }
    
    // 默認顏色
    return 'from-gray-500 to-gray-700 dark:from-gray-400 dark:to-gray-200';
  };

  // 計算媒體總數和圖片數量
  const totalMedia = (project?.imageUrls?.length || 0) + (project?.videoUrls?.length || 0);
  const totalImages = project?.imageUrls?.length || 0;

  // 添加點讚加載狀態
  const [likingComments, setLikingComments] = useState(new Set());
  const [likingReplies, setLikingReplies] = useState(new Set());
  
  // 防抖計時器
  const likeDebounceTimers = useRef({});

  // 更新媒體類型和索引
  const updateMedia = useCallback((index) => {
    setCurrentIndex(index);
    setMediaType(index < totalImages ? 'image' : 'video');
  }, [totalImages]);

  // 處理媒體切換
  const handleMediaChange = useCallback((index) => {
    updateMedia(index);
  }, [updateMedia]);

  // 下一個媒體
  const nextMedia = useCallback(() => {
    if (totalMedia > 0) {
      const nextIndex = (currentIndex + 1) % totalMedia;
      updateMedia(nextIndex);
    }
  }, [currentIndex, totalMedia, updateMedia]);

  // 上一個媒體
  const prevMedia = useCallback(() => {
    if (totalMedia > 0) {
      const prevIndex = currentIndex === 0 ? totalMedia - 1 : currentIndex - 1;
      updateMedia(prevIndex);
    }
  }, [currentIndex, totalMedia, updateMedia]);

  // 處理觸摸事件
  const onTouchStart = useCallback((e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  }, []);

  const onTouchMove = useCallback((e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  }, []);

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const minSwipeDistance = 50;
    
    if (distance > minSwipeDistance) {
      nextMedia();
    } else if (distance < -minSwipeDistance) {
      prevMedia();
    }
  }, [touchStart, touchEnd, nextMedia, prevMedia]);

  // 處理圖片載入錯誤
  const handleImageError = useCallback((e, url) => {
    console.error('圖片載入失敗:', url);
    e.target.style.display = 'none';
    e.preventDefault();
  }, []);

  // 通用按鈕樣式
  const buttonBaseStyle = "px-4 py-2 bg-gradient-to-r from-zinc-700 to-zinc-800 hover:from-rose-500 hover:to-rose-600 text-zinc-300 hover:text-white rounded-lg transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:shadow-rose-500/20 active:scale-95";

  useEffect(() => {
    const fetchProjectDetail = async () => {
      try {
        const response = await fetch(`${API_DOMAIN}/api/projects/${id}`);
        if (!response.ok) {
          console.error(`獲取詳情失敗: HTTP ${response.status}`);
          throw new Error(`獲取詳情失敗: HTTP ${response.status}`);
        }
        
        // 嘗試解析 JSON 前先檢查響應內容
        const text = await response.text();
        let data;
        try {
          data = JSON.parse(text);
        } catch (e) {
          console.error('解析項目詳情數據失敗:', e, '原始響應:', text);
          throw new Error('解析項目詳情數據失敗');
        }

        // 確保 imageUrls 和 videoUrls 存在
        data.imageUrls = data.imageUrls || [];
        data.videoUrls = data.videoUrls || [];
        
        // 如果有舊的 imageUrl，添加到 imageUrls
        if (data.imageUrl && !data.imageUrls.includes(data.imageUrl)) {
          data.imageUrls = [data.imageUrl, ...data.imageUrls];
        }

        setProject(data);
        setMediaType(data.imageUrls.length > 0 ? 'image' : 'video');
        setCurrentIndex(0);
      } catch (error) {
        console.error('獲取詳情失敗:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetail();
  }, [id]);

  // 獲取評論顯示設置
  useEffect(() => {
    const fetchCommentSettings = async () => {
      try {
        setIsLoadingCommentSetting(true);
        const { data } = await memberApi.get('/settings/comments-display');
        console.log('評論設置:', data);
        setShowComments(data.showComments);
      } catch (error) {
        console.error('獲取評論設置失敗:', error);
        setShowComments(true); // 如果獲取失敗，預設顯示評論
      } finally {
        setIsLoadingCommentSetting(false);
      }
    };

    fetchCommentSettings();
  }, []);

  // 修改獲取評論列表的邏輯，考慮評論展示設置
  useEffect(() => {
    const fetchComments = async (retryCount = 0) => {
      // 如果評論區被禁用，不獲取評論
      if (!showComments) {
        setComments([]);
        setTotalPages(1);
        return;
      }

      try {
        // 獲取token
        const token = localStorage.getItem('memberToken');
        
        console.log('正在獲取評論，頁碼:', currentPage, '排序方式:', sortBy);
        
        const response = await memberApi.get(
          `/comments/project/${id}?page=${currentPage}&limit=${commentsPerPage}&sortBy=${sortBy}`
        );
        
        if (!response || !response.data) {
          throw new Error('無效的回應數據');
        }

        const data = response.data;
        console.log('成功獲取評論數據:', data.comments?.length || 0, '條評論');
        
        if (data.comments && Array.isArray(data.comments)) {
          // 從localStorage讀取點讚狀態
          const likedComments = JSON.parse(localStorage.getItem('likedComments') || '{}');
          const likedReplies = JSON.parse(localStorage.getItem('likedReplies') || '{}');
          
          // 合併服務器返回的點讚狀態和本地存儲的點讚狀態
          const updatedComments = data.comments.map(comment => {
            if (!comment) return null;
            
            const isLiked = comment.isLiked || likedComments[comment._id] || false;
            
            const updatedReplies = (comment.replies || []).map(reply => {
              if (!reply) return null;
              const replyLikeKey = `${comment._id}-${reply._id}`;
              const replyIsLiked = reply.isLiked || likedReplies[replyLikeKey] || false;
              return { ...reply, isLiked: replyIsLiked };
            }).filter(Boolean);
            
            return { ...comment, isLiked, replies: updatedReplies };
          }).filter(Boolean);
          
          setComments(updatedComments);
          setTotalPages(Math.max(1, Math.ceil(data.total / commentsPerPage)));
          setError(null);
        } else {
          console.log('未獲取到評論或格式不正確');
          setComments([]);
          setTotalPages(1);
        }
      } catch (error) {
        console.error('獲取評論失敗:', error);
        
        if (retryCount < 3 && (error.message?.includes('network') || error.message?.includes('500'))) {
          console.log(`嘗試重新獲取評論 (${retryCount + 1}/3)...`);
          setTimeout(() => fetchComments(retryCount + 1), 1000 * (retryCount + 1));
          return;
        }
        
        setError(error.message || '獲取評論失敗');
        setComments([]);
        setTotalPages(1);
      }
    };

    if (id) {
      fetchComments();
    }
  }, [id, currentPage, commentsPerPage, sortBy, showComments]);

  // 檢查用戶是否已登入
  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = localStorage.getItem('memberToken');
      console.log('Current memberToken:', token);
      
      if (token) {
        try {
          const response = await memberApi.get('/member/profile');
          const userData = response.data;
          console.log('User profile data:', userData);
          
          if (userData) {
            setIsLoggedIn(true);
            console.log('Login status set to true');
          } else {
            throw new Error('無效的用戶資料');
          }
        } catch (error) {
          console.error('驗證用戶狀態失敗:', error);
          setIsLoggedIn(false);
          // 只在真正的驗證失敗時才清除 token
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('memberToken');
            console.log('Token cleared due to authentication failure');
          }
        }
      } else {
        setIsLoggedIn(false);
        console.log('No token found, setting login status to false');
      }
    };
    
    checkLoginStatus();
  }, []);

  // 修改提交評論的邏輯
  const handleSubmitComment = async () => {
    if (!showComments) {
      toast.error('評論功能已關閉');
      return;
    }

    if (!commentContent.trim()) {
      return;
    }

    if (!isLoggedIn) {
      toast.error('請先登入後再發表評論');
      navigate('/login', { state: { from: `/projects/${id}` } });
      return;
    }

    setIsSubmitting(true);
    try {
      await memberApi.post(`/comments/project/${id}`, {
        content: commentContent,
        rating: currentRating
      });

      // 重新獲取評論列表
      const response = await memberApi.get(
        `/comments/project/${id}?page=1&limit=${commentsPerPage}&sortBy=${sortBy}`
      );
      
      const data = response.data;
      if (data.comments && Array.isArray(data.comments)) {
        setComments(data.comments);
        setTotalPages(Math.max(1, Math.ceil(data.total / commentsPerPage)));
      }
      
      setCurrentPage(1);
      setCommentContent('');
      setCurrentRating(5);
      setError(null);

      toast.success('評論發送成功！');
    } catch (error) {
      console.error('提交評論失敗:', error);
      setError(error.message);
      toast.error('評論發送失敗，請稍後重試');
    } finally {
      setIsSubmitting(false);
    }
  };

  // 評分組件
  const RatingStars = ({ rating, onRatingChange, interactive = false }) => {
    const [hoverRating, setHoverRating] = useState(0);
    const [isSpinning, setIsSpinning] = useState(false);
    const ratingContainerRef = useRef(null);

    const formatRating = (value) => Math.round(value);
    const displayRating = interactive ? (hoverRating || rating) : rating;

    const handleStarClick = (starPosition) => {
      if (!interactive) return;
      
      setIsSpinning(true);
      const newRating = starPosition;
      onRatingChange(newRating);
      
      setTimeout(() => {
        setIsSpinning(false);
      }, 300);
    };

    return (
      <div className="flex items-center space-x-2">
        <div 
          className={`flex space-x-1 ${isSpinning ? 'animate-bounce' : ''}`}
          ref={ratingContainerRef}
        >
          {[...Array(5)].map((_, index) => (
            <div
              key={`star-${index + 1}-${interactive ? 'interactive' : 'static'}`}
              className={`relative cursor-pointer w-8 h-8 transition-transform duration-200 
                ${interactive ? 'hover:scale-110 active:scale-95' : ''}`}
              onMouseEnter={() => interactive && setHoverRating(index + 1)}
              onMouseLeave={() => interactive && setHoverRating(0)}
              onClick={() => handleStarClick(index + 1)}
            >
              {/* 空心星星 */}
              <div className="absolute inset-0">
                <svg
                  className="w-8 h-8 transition-colors duration-200"
                  style={{
                    color: index < (hoverRating || rating) ? '#FBBF24' : '#D1D5DB'
                  }}
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                </svg>
              </div>
              {/* 實心星星 */}
              <div
                className="absolute inset-0 overflow-hidden transition-all duration-200"
                style={{
                  width: `${index < (hoverRating || rating) ? 100 : 0}%`,
                  opacity: index < (hoverRating || rating) ? 1 : 0
                }}
              >
                <svg
                  className="w-8 h-8 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                </svg>
              </div>
            </div>
          ))}
        </div>
        {interactive && (
          <span className={`text-lg font-medium ml-2 transition-all duration-200 
            ${hoverRating ? 'text-yellow-500' : 'text-zinc-700 dark:text-zinc-300'}`}>
            {formatRating(displayRating)} / 5
          </span>
        )}
      </div>
    );
  };

  // 返回按鈕組件
  const BackButton = () => (
    <button 
      onClick={() => navigate('/projects')}
      className="group px-5 py-2.5 bg-gradient-to-r from-zinc-800 to-zinc-900 hover:from-rose-500 hover:to-rose-600 text-zinc-200 hover:text-white rounded-lg transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:shadow-rose-500/20 active:scale-95 flex items-center space-x-2 border border-zinc-700 hover:border-rose-400"
    >
      <svg className="w-5 h-5 transition-transform duration-300 group-hover:-translate-x-1" 
        fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d="M10 19l-7-7m0 0l7-7m-7 7h18" />
      </svg>
      <span className="relative font-medium">
        再選一個
        <span className="absolute bottom-0 left-0 w-full h-0.5 bg-rose-400 transform 
          scale-x-0 transition-transform duration-300 origin-left group-hover:scale-x-100"></span>
      </span>
    </button>
  );

  // 點讚評論
  const handleLikeComment = useCallback(async (commentId) => {
    if (!isLoggedIn) {
      toast.error('請先登入後再點讚');
      // 保存當前頁面路徑，以便登入後返回
      navigate('/login', { state: { from: `/projects/${id}` } });
      return;
    }

    // 檢查是否正在處理中
    if (likingComments.has(commentId)) {
      return;
    }

    // 檢查防抖
    if (likeDebounceTimers.current[`comment-${commentId}`]) {
      clearTimeout(likeDebounceTimers.current[`comment-${commentId}`]);
    }

    likeDebounceTimers.current[`comment-${commentId}`] = setTimeout(async () => {
      // 設置加載狀態
      setLikingComments(prev => new Set([...prev, commentId]));

      try {
        const response = await memberApi.post(`/comments/${commentId}/like`);
        const { likes, isLiked } = response.data;
        
        // 使用 API 返回的點讚數和狀態來更新
        setComments(prevComments => prevComments.map(comment => 
          comment._id === commentId ? { ...comment, likes, isLiked } : comment
        ));
        
        // 將點讚狀態保存到localStorage
        const likedComments = JSON.parse(localStorage.getItem('likedComments') || '{}');
        if (isLiked) {
          likedComments[commentId] = true;
        } else {
          delete likedComments[commentId];
        }
        localStorage.setItem('likedComments', JSON.stringify(likedComments));
      } catch (error) {
        console.error('點讚失敗:', error);
        toast.error('點讚失敗，請稍後重試');
      } finally {
        // 清除加載狀態
        setLikingComments(prev => {
          const next = new Set(prev);
          next.delete(commentId);
          return next;
        });
      }
    }, 300); // 300ms 的防抖延遲
  }, [isLoggedIn, likingComments, navigate, id]);

  // 點讚回覆
  const handleLikeReply = useCallback(async (commentId, replyId) => {
    if (!isLoggedIn) {
      toast.error('請先登入後再點讚');
      // 保存當前頁面路徑，以便登入後返回
      navigate('/login', { state: { from: `/projects/${id}` } });
      return;
    }

    // 檢查是否正在處理中
    const likeKey = `${commentId}-${replyId}`;
    if (likingReplies.has(likeKey)) {
      return;
    }

    // 檢查防抖
    if (likeDebounceTimers.current[`reply-${likeKey}`]) {
      clearTimeout(likeDebounceTimers.current[`reply-${likeKey}`]);
    }

    likeDebounceTimers.current[`reply-${likeKey}`] = setTimeout(async () => {
      // 設置加載狀態
      setLikingReplies(prev => new Set([...prev, likeKey]));

      try {
        const response = await memberApi.post(`/comments/${commentId}/replies/${replyId}/like`);
        const { likes, isLiked } = response.data;
        
        // 使用 API 返回的點讚數和狀態來更新
        setComments(prevComments => prevComments.map(comment => {
        if (comment._id === commentId) {
          return {
            ...comment,
            replies: comment.replies.map(reply =>
              reply._id === replyId
                  ? { ...reply, likes, isLiked }
                : reply
            )
          };
        }
        return comment;
      }));
      
      // 將點讚狀態保存到localStorage
      const likedReplies = JSON.parse(localStorage.getItem('likedReplies') || '{}');
      if (isLiked) {
        likedReplies[likeKey] = true;
      } else {
        delete likedReplies[likeKey];
      }
      localStorage.setItem('likedReplies', JSON.stringify(likedReplies));
    } catch (error) {
      console.error('點讚回覆失敗:', error);
      toast.error('點讚失敗，請稍後重試');
      } finally {
        // 清除加載狀態
        setLikingReplies(prev => {
          const next = new Set(prev);
          next.delete(likeKey);
          return next;
        });
      }
    }, 300); // 300ms 的防抖延遲
  }, [isLoggedIn, likingReplies, navigate, id]);

  // 清理防抖計時器
  useEffect(() => {
    const currentTimers = likeDebounceTimers.current;
    
    return () => {
      Object.values(currentTimers).forEach(timer => {
        clearTimeout(timer);
      });
    };
  }, []);

  // 修改提交回覆的邏輯
  const handleSubmitReply = async (commentId) => {
    if (!showComments) {
      toast.error('評論功能已關閉');
      return;
    }

    if (!replyContent.trim()) {
      toast.error('請輸入回覆內容');
      return;
    }

    if (!isLoggedIn) {
      toast.error('請先登入後再回覆評論');
      navigate('/login', { state: { from: `/projects/${id}` } });
      return;
    }

    try {
      await memberApi.post(`/comments/${commentId}/reply`, {
        content: replyContent.trim()
      });

      // 重新獲取評論列表
      const commentsResponse = await memberApi.get(
        `/comments/project/${id}?page=${currentPage}&limit=${commentsPerPage}&sortBy=${sortBy}`
      );
      
      const data = commentsResponse.data;
      if (data.comments && Array.isArray(data.comments)) {
        setComments(data.comments);
        setTotalPages(Math.max(1, Math.ceil(data.total / commentsPerPage)));
      }
        
      setReplyContent('');
      setReplyingTo(null);
      toast.success('回覆成功！');
    } catch (error) {
      console.error('回覆失敗:', error);
      toast.error(error.response?.data?.message || '回覆失敗，請稍後重試');
    }
  };

  // 渲染評論區塊
  const renderComments = () => {
    if (isLoadingCommentSetting) {
      return null;
    }

    if (!showComments) {
      return null;
    }

    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-zinc-800 dark:text-zinc-200">用戶評論</h2>
          <div className="relative">
            <button
              onClick={() => setShowSortOptions(!showSortOptions)}
              className="flex items-center gap-2 px-4 py-2 bg-white dark:bg-zinc-800 border border-zinc-200 dark:border-zinc-700 rounded-lg shadow-sm hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-200"
            >
              <span className="text-zinc-700 dark:text-zinc-300">
                {sortOption === 'latest' ? '最新' : sortOption === 'mostLiked' ? '最多讚' : '最高分'}
              </span>
              <svg className={`w-4 h-4 text-zinc-500 dark:text-zinc-400 transition-transform duration-200 ${showSortOptions ? 'transform rotate-180' : ''}`} 
                fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            
            {showSortOptions && (
              <div className="absolute right-0 mt-2 w-40 bg-white dark:bg-zinc-800 border border-zinc-200 dark:border-zinc-700 rounded-lg shadow-lg overflow-hidden z-10">
                <div className="py-1">
                  <button
                    onClick={() => {
                      setSortOption('latest');
                      setShowSortOptions(false);
                    }}
                    className={`w-full px-4 py-2 text-left hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-200
                      ${sortOption === 'latest' ? 'bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400' : 'text-zinc-700 dark:text-zinc-300'}`}
                  >
                    最新
                  </button>
                  <button
                    onClick={() => {
                      setSortOption('mostLiked');
                      setShowSortOptions(false);
                    }}
                    className={`w-full px-4 py-2 text-left hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-200
                      ${sortOption === 'mostLiked' ? 'bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400' : 'text-zinc-700 dark:text-zinc-300'}`}
                  >
                    最多讚
                  </button>
                  <button
                    onClick={() => {
                      setSortOption('highestRated');
                      setShowSortOptions(false);
                    }}
                    className={`w-full px-4 py-2 text-left hover:bg-zinc-50 dark:hover:bg-zinc-700 transition-colors duration-200
                      ${sortOption === 'highestRated' ? 'bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400' : 'text-zinc-700 dark:text-zinc-300'}`}
                  >
                    最高分
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        
        {/* 評論輸入區域 */}
        <div className="space-y-4 mb-6">
          {isLoggedIn ? (
            <div key="comment-input" className="space-y-4">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 p-3 sm:p-4 bg-zinc-50 dark:bg-zinc-700/50 rounded-lg border border-zinc-200 dark:border-zinc-600">
                <span className="text-zinc-600 dark:text-zinc-400 text-sm sm:text-base">您的評分</span>
                <RatingStars rating={currentRating} onRatingChange={setCurrentRating} interactive={isLoggedIn} />
              </div>
              <textarea 
                value={commentContent}
                onChange={(e) => setCommentContent(e.target.value)}
                className="w-full min-h-[120px] p-4 rounded-lg bg-zinc-50 dark:bg-zinc-700/50 text-zinc-800 dark:text-zinc-300 placeholder-zinc-400 border border-zinc-200 dark:border-zinc-600 text-base resize-none transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-rose-500/50 focus:border-rose-500/50 hover:border-rose-500/30"
                placeholder="分享您的體驗..."
              />
              <div className="flex justify-end">
                <button 
                  onClick={handleSubmitComment}
                  disabled={isSubmitting || !commentContent.trim()}
                  className={`relative px-6 py-2.5 bg-gradient-to-r from-rose-500 to-rose-600 text-white rounded-xl transition-all duration-300 transform hover:translate-y-[-2px] hover:shadow-lg hover:shadow-rose-500/25 active:translate-y-[0px] ${
                    (isSubmitting || !commentContent.trim()) 
                      ? 'opacity-50 cursor-not-allowed' 
                      : 'hover:from-rose-600 hover:to-rose-700'
                  }`}
                >
                  <span className="flex items-center gap-2">
                    {isSubmitting ? (
                      <>
                        <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                        <span>送出中...</span>
                      </>
                    ) : (
                      <>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                        </svg>
                        <span>送出評論</span>
                      </>
                    )}
                  </span>
                </button>
              </div>
            </div>
          ) : (
            <div key="login-prompt" className="bg-gradient-to-r from-rose-50 to-rose-100 dark:from-rose-900/30 dark:to-rose-800/30 p-6 rounded-xl border border-rose-200 dark:border-rose-800 shadow-lg shadow-rose-500/5">
              <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-full bg-rose-500/10 flex items-center justify-center">
                    <svg className="w-6 h-6 text-rose-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-rose-700 dark:text-rose-300 font-medium">還沒登入嗎？</span>
                    <span className="text-rose-600/80 dark:text-rose-400/80 text-sm">登入後即可發表評論</span>
                  </div>
                </div>
                <button 
                  onClick={() => navigate('/login', { state: { from: `/projects/${id}` } })}
                  className="px-6 py-2.5 bg-gradient-to-r from-rose-500 to-rose-600 text-white rounded-xl transition-all duration-300"
                >
                  立即登入
                </button>
              </div>
            </div>
          )}
        </div>
        
        {/* 評論列表 */}
        <div className="space-y-6">
          {comments.map((comment) => (
            <div key={comment._id} className="bg-white dark:bg-zinc-800 rounded-lg shadow-sm border border-zinc-200 dark:border-zinc-700">
              {/* 評論主體 */}
              <div className="p-4">
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center gap-2">
                    <div className="w-8 h-8 rounded-full bg-gradient-to-br from-rose-500 to-rose-600 flex items-center justify-center text-white">
                      {comment.user?.[0].toUpperCase()}
                    </div>
                    <div>
                      <div className="font-medium text-zinc-800 dark:text-zinc-200">{comment.user}</div>
                      {comment.identityGroup && (
                        <span className={`inline-block px-2 py-0.5 bg-gradient-to-r ${getMemberLevelColor(comment.identityGroup)} rounded-full border text-sm`}>
                          <span className={`font-medium bg-gradient-to-r ${getMemberLevelTextGradient(comment.identityGroup)} bg-clip-text text-transparent`}>
                            {comment.identityGroup === '惡意評論的哥布林' && (
                              <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4 mr-1 text-red-600 dark:text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                              </svg>
                            )}
                            {comment.identityGroup}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center bg-yellow-400/10 dark:bg-yellow-400/20 px-3 py-1 rounded-full">
                    <span className="font-medium text-lg text-yellow-600 dark:text-yellow-400">{comment.rating}</span>
                    <span className="text-yellow-500 dark:text-yellow-400 ml-1">★</span>
                  </div>
                </div>

                <p className="text-zinc-700 dark:text-zinc-300 mb-3">{comment.content}</p>
                
                <div className="flex items-center justify-between text-sm text-zinc-500 dark:text-zinc-400">
                  <span>{new Date(comment.date).toLocaleString('zh-TW')}</span>
                  <div className="flex items-center gap-4">
                    <button
                      onClick={() => handleLikeComment(comment._id)} 
                      className={`flex items-center gap-1 transition-colors duration-200 ${
                        comment.isLiked 
                          ? 'text-rose-500 dark:text-rose-400' 
                          : 'text-zinc-500 dark:text-zinc-400 hover:text-rose-500 dark:hover:text-rose-400'
                      }`}
                      disabled={likingComments.has(comment._id)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" 
                        className={`h-5 w-5 transition-all duration-200 ${
                          comment.isLiked ? 'scale-110' : ''
                        } ${likingComments.has(comment._id) ? 'animate-pulse' : ''}`} 
                        viewBox="0 0 20 20" 
                        fill="currentColor"
                      >
                        <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                      </svg>
                      <span className={`transition-all duration-200 ${
                        likingComments.has(comment._id) ? 'animate-pulse' : ''
                      }`}>
                        {comment.likes || 0}
                      </span>
                    </button>
                    <button
                      onClick={() => setReplyingTo(replyingTo === comment._id ? null : comment._id)}
                      className="flex items-center gap-1 text-zinc-500 dark:text-zinc-400 hover:text-rose-500 dark:hover:text-rose-400 transition-colors duration-200"
                    >
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                      </svg>
                      回覆
                    </button>
                  </div>
                </div>
              </div>

              {/* 回覆列表 */}
              {comment.replies && comment.replies.length > 0 && (
                <div className="mt-2 pl-4 border-l-2 border-zinc-200 dark:border-zinc-700">
                  {comment.replies.map((reply) => (
                    <div key={reply._id} className="mb-3 bg-zinc-50 dark:bg-zinc-700/50 rounded-lg p-3">
                      <div className="flex items-center gap-2 mb-2">
                        <div className="w-6 h-6 rounded-full bg-gradient-to-br from-rose-400 to-rose-500 flex items-center justify-center text-white text-sm">
                          {reply.user?.[0].toUpperCase()}
                        </div>
                        <div>
                          <div className="font-medium text-zinc-800 dark:text-zinc-200">{reply.user}</div>
                          {reply.identityGroup && (
                            <span className={`inline-block px-2 py-0.5 bg-gradient-to-r ${getMemberLevelColor(reply.identityGroup)} rounded-full border text-xs`}>
                              <span className={`font-medium bg-gradient-to-r ${getMemberLevelTextGradient(reply.identityGroup)} bg-clip-text text-transparent`}>
                                {reply.identityGroup}
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                      <p className="text-zinc-700 dark:text-zinc-300 mb-2">{reply.content}</p>
                      <div className="flex items-center justify-between text-sm text-zinc-500 dark:text-zinc-400">
                        <span>{new Date(reply.date).toLocaleString('zh-TW')}</span>
                        <button
                          onClick={() => handleLikeReply(comment._id, reply._id)}
                          className={`flex items-center gap-1 transition-colors duration-200 ${
                            reply.isLiked 
                              ? 'text-rose-500 dark:text-rose-400' 
                              : 'text-zinc-500 dark:text-zinc-400 hover:text-rose-500 dark:hover:text-rose-400'
                          }`}
                          disabled={likingReplies.has(`${comment._id}-${reply._id}`)}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" 
                            className={`h-4 w-4 transition-all duration-200 ${
                              reply.isLiked ? 'scale-110' : ''
                            } ${likingReplies.has(`${comment._id}-${reply._id}`) ? 'animate-pulse' : ''}`} 
                            viewBox="0 0 20 20" 
                            fill="currentColor"
                          >
                            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                          </svg>
                          <span className={`transition-all duration-200 ${
                            likingReplies.has(`${comment._id}-${reply._id}`) ? 'animate-pulse' : ''
                          }`}>
                            {reply.likes || 0}
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {/* 回覆輸入框 */}
              {replyingTo === comment._id && (
                <div className="mt-3 bg-zinc-50 dark:bg-zinc-700/50 rounded-lg border border-zinc-200 dark:border-zinc-600 p-3">
                  <textarea
                    value={replyContent}
                    onChange={(e) => setReplyContent(e.target.value)}
                    placeholder="寫下你的回覆..."
                    className="w-full p-3 bg-white dark:bg-zinc-800 border border-zinc-200 dark:border-zinc-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500 focus:border-transparent text-zinc-700 dark:text-zinc-300 placeholder-zinc-400 dark:placeholder-zinc-500"
                    rows="3"
                  />
                  <div className="flex justify-end gap-2 mt-3">
                    <button
                      onClick={() => setReplyingTo(null)}
                      className="px-4 py-2 text-zinc-600 dark:text-zinc-400 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-lg transition-colors duration-200"
                    >
                      取消
                    </button>
                    <button
                      onClick={() => handleSubmitReply(comment._id)}
                      className="px-4 py-2 bg-gradient-to-r from-rose-500 to-rose-600 hover:from-rose-600 hover:to-rose-700 text-white rounded-lg transition-all duration-200"
                    >
                      發送
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        
        {/* 分頁按鈕 */}
        {totalPages > 1 && (
          <div className="flex flex-col sm:flex-row justify-center items-center gap-4 mt-8">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className={`w-full sm:w-auto px-6 py-2.5 rounded-lg flex items-center justify-center gap-2 transition-all duration-200 ${
                currentPage === 1
                  ? 'bg-zinc-100 dark:bg-zinc-800 text-zinc-400 dark:text-zinc-600 cursor-not-allowed'
                  : 'bg-white dark:bg-zinc-800 text-zinc-700 dark:text-zinc-300 hover:bg-zinc-50 dark:hover:bg-zinc-700 border border-zinc-200 dark:border-zinc-700'
              }`}
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
              <span>上一頁</span>
            </button>
            
            <div className="flex items-center gap-2 text-zinc-600 dark:text-zinc-400">
              <span>第</span>
              <span className="font-medium text-zinc-800 dark:text-zinc-200">{currentPage}</span>
              <span>頁</span>
              <span>/</span>
              <span>共</span>
              <span className="font-medium text-zinc-800 dark:text-zinc-200">{totalPages}</span>
              <span>頁</span>
            </div>

            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className={`w-full sm:w-auto px-6 py-2.5 rounded-lg flex items-center justify-center gap-2 transition-all duration-200 ${
                currentPage === totalPages
                  ? 'bg-zinc-100 dark:bg-zinc-800 text-zinc-400 dark:text-zinc-600 cursor-not-allowed'
                  : 'bg-white dark:bg-zinc-800 text-zinc-700 dark:text-zinc-300 hover:bg-zinc-50 dark:hover:bg-zinc-700 border border-zinc-200 dark:border-zinc-700'
              }`}
            >
              <span>下一頁</span>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-zinc-100 dark:bg-zinc-900 flex items-center justify-center">
        <div className="text-center space-y-4">
          <div className="w-16 h-16 border-4 border-rose-500 border-t-transparent 
            rounded-full animate-spin mx-auto"></div>
          <div className="text-zinc-600 dark:text-zinc-400 text-lg animate-pulse">
            這妹不錯你很會挑欸👍
          </div>
          <div className="text-zinc-500 dark:text-zinc-500 text-sm">
            馬上給你妹子資料🤖
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-zinc-100 dark:bg-zinc-900 flex items-center justify-center">
        <div className="text-center space-y-4">
          <div className="text-rose-500 dark:text-rose-400 text-xl">
            {error}
          </div>
          <BackButton />
        </div>
      </div>
    );
  }

  if (!project) {
    return (
      <div className="min-h-screen bg-zinc-100 dark:bg-zinc-900 flex items-center justify-center">
        <div className="text-center space-y-4">
          <div className="text-zinc-600 dark:text-zinc-400 text-xl">
            找不到該妹子
          </div>
          <BackButton />
        </div>
      </div>
    );
  }

  // 渲染媒體控制按鈕
  const MediaControlButton = ({ direction, onClick }) => (
    <button
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      className={`absolute ${direction === 'left' ? 'left-2' : 'right-2'} top-1/2 -translate-y-1/2 
        bg-black/50 hover:bg-black/70 text-white p-2 rounded-full transition-all duration-300 
        opacity-0 group-hover:opacity-100 transform hover:scale-110 active:scale-95`}
    >
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
          d={direction === 'left' ? "M15 19l-7-7 7-7" : "M9 5l7 7-7 7"} />
      </svg>
    </button>
  );

  return (
    <div className="bg-zinc-100 dark:bg-zinc-900 min-h-screen w-full overflow-x-hidden">
      <div className="container mx-auto px-4 py-8">
        <div className="mt-2 mb-6">
          <BackButton />
        </div>

        <div className="bg-white dark:bg-zinc-800 rounded-lg shadow-card p-6">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="relative group md:col-span-1">
              {/* 主媒體展示 */}
              <div 
                className="relative aspect-[3/4] overflow-hidden rounded-lg bg-zinc-100 dark:bg-zinc-800 
                  cursor-grab active:cursor-grabbing"
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
              >
                {mediaType === 'image' ? (
                  <img 
                    src={project.imageUrls[currentIndex]} 
                    alt={project.name} 
                    className="w-full h-full object-contain transition-all duration-500 ease-in-out
                      animate-fadeIn scale-100 hover:scale-105 pointer-events-none"
                    onError={(e) => handleImageError(e, project.imageUrls[currentIndex])}
                    draggable="false"
                  />
                ) : project.videoUrls?.length > 0 ? (
                  <video
                    src={project.videoUrls[currentIndex - totalImages]}
                    className="w-full h-full object-contain transition-all duration-500 ease-in-out
                      animate-fadeIn scale-100 hover:scale-105"
                    controls
                    controlsList="nodownload"
                    playsInline
                    draggable="false"
                  >
                    您的瀏覽器不支持影片播放
                  </video>
                ) : (
                  <div className="w-full h-full" />
                )}
                
                {/* 左右切換按鈕 */}
                {totalMedia > 1 && (
                  <>
                    <MediaControlButton direction="left" onClick={prevMedia} />
                    <MediaControlButton direction="right" onClick={nextMedia} />
                  </>
                )}
              </div>

              {/* 縮略圖列表 */}
              {totalMedia > 1 && (
                <div className="mt-4 grid grid-cols-5 gap-2">
                  {/* 圖片縮略圖 */}
                  {project.imageUrls.map((url, index) => (
                    <button
                      key={`image-${index}-${url.split('/').pop()}`}
                      onClick={() => handleMediaChange(index)}
                      className={`relative aspect-square rounded-lg overflow-hidden bg-zinc-100 
                        dark:bg-zinc-800 transition-all duration-300 ${
                          currentIndex === index && mediaType === 'image'
                            ? 'ring-2 ring-rose-500 ring-offset-2' 
                            : 'opacity-70 hover:opacity-100'
                        }`}
                    >
                      <img 
                        src={url} 
                        alt={`${project.name} - ${index + 1}`} 
                        className="w-full h-full object-contain"
                        onError={(e) => handleImageError(e, url)}
                      />
                    </button>
                  ))}
                  
                  {/* 影片縮略圖 */}
                  {project.videoUrls.map((url, index) => (
                    <button
                      key={`video-${index}-${url.split('/').pop()}`}
                      onClick={() => handleMediaChange(index + totalImages)}
                      className={`relative aspect-square rounded-lg overflow-hidden bg-zinc-100 
                        dark:bg-zinc-800 transition-all duration-300 ${
                          currentIndex === (index + totalImages) && mediaType === 'video'
                            ? 'ring-2 ring-rose-500 ring-offset-2' 
                            : 'opacity-70 hover:opacity-100'
                        }`}
                    >
                      <div className="absolute inset-0 flex items-center justify-center bg-zinc-800/50">
                        <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <video
                        src={url}
                        className="w-full h-full object-cover"
                      />
                    </button>
                  ))}
                </div>
              )}
            </div>
            
            <div className="space-y-8 md:col-span-2">
              <div className="bg-zinc-100 dark:bg-zinc-700 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <h2 className={`text-4xl font-bold mb-6 pb-2 inline-block relative
                  ${(() => {
                    // 根據杯型大小設置不同的顏色
                    switch(project.breastSize) {
                      case 'A': return 'text-sky-800 dark:text-sky-300 border-b-4 border-sky-500 dark:border-sky-400';
                      case 'B': return 'text-blue-800 dark:text-blue-300 border-b-4 border-blue-500 dark:border-blue-400';
                      case 'C': return 'text-indigo-800 dark:text-indigo-300 border-b-4 border-indigo-500 dark:border-indigo-400';
                      case 'D': return 'text-violet-800 dark:text-violet-300 border-b-4 border-violet-500 dark:border-violet-400';
                      case 'E': return 'text-purple-800 dark:text-purple-300 border-b-4 border-purple-500 dark:border-purple-400';
                      case 'F': return 'text-fuchsia-800 dark:text-fuchsia-300 border-b-4 border-fuchsia-500 dark:border-fuchsia-400';
                      case 'G': return 'text-pink-800 dark:text-pink-300 border-b-4 border-pink-500 dark:border-pink-400';
                      case 'H': return 'text-rose-800 dark:text-rose-300 border-b-4 border-rose-500 dark:border-rose-400';
                      case 'I': return 'text-red-800 dark:text-red-300 border-b-4 border-red-500 dark:border-red-400';
                      case 'J': return 'text-orange-800 dark:text-orange-300 border-b-4 border-orange-500 dark:border-orange-400';
                      case 'K': return 'text-amber-800 dark:text-amber-300 border-b-4 border-amber-500 dark:border-amber-400';
                      case 'L': return 'text-yellow-800 dark:text-yellow-300 border-b-4 border-yellow-500 dark:border-yellow-400';
                      case 'M': return 'text-lime-800 dark:text-lime-300 border-b-4 border-lime-500 dark:border-lime-400';
                      default: return 'text-zinc-800 dark:text-zinc-300 border-b-4 border-gray-500 dark:border-gray-400';
                    }
                  })()}`}>
                  {project.name}
                  <span className={`absolute -bottom-1 left-0 w-full h-1 
                    ${(() => {
                      // 根據杯型大小設置不同的漸變顏色
                      switch(project.breastSize) {
                        case 'A': return 'bg-gradient-to-r from-sky-500 to-blue-500';
                        case 'B': return 'bg-gradient-to-r from-blue-500 to-indigo-500';
                        case 'C': return 'bg-gradient-to-r from-indigo-500 to-violet-500';
                        case 'D': return 'bg-gradient-to-r from-violet-500 to-purple-500';
                        case 'E': return 'bg-gradient-to-r from-purple-500 to-fuchsia-500';
                        case 'F': return 'bg-gradient-to-r from-fuchsia-500 to-pink-500';
                        case 'G': return 'bg-gradient-to-r from-pink-500 to-rose-500';
                        case 'H': return 'bg-gradient-to-r from-rose-500 to-red-500';
                        case 'I': return 'bg-gradient-to-r from-red-500 to-orange-500';
                        case 'J': return 'bg-gradient-to-r from-orange-500 to-amber-500';
                        case 'K': return 'bg-gradient-to-r from-amber-500 to-yellow-500';
                        case 'L': return 'bg-gradient-to-r from-yellow-500 to-lime-500';
                        case 'M': return 'bg-gradient-to-r from-lime-500 to-green-500';
                        default: return 'bg-gradient-to-r from-gray-500 to-zinc-500';
                      }
                    })()}`}></span>
                </h2>
                <div className="grid grid-cols-2 gap-6 mb-8">
                  {[
                    ['國籍', project.nationality],
                    ['年齡', project.age > 0 ? `${project.age}歲` : '暫無'],
                    ['身高', project.height > 0 ? `${project.height}cm` : '暫無'],
                    ['體重', project.weight > 0 ? `${project.weight}kg` : '暫無'],
                    ['城市', project.city || '未提供'],
                    ['地區', project.area],
                    ['類型', <span className="whitespace-nowrap flex items-center">{project.serviceType}</span>]
                  ].map(([label, value]) => (
                    <div key={label} className="flex flex-col p-3 bg-white/50 dark:bg-zinc-600/50 rounded-lg hover:bg-white/80 dark:hover:bg-zinc-600/80 transition-colors duration-300">
                      <span className="text-zinc-500 dark:text-zinc-400 text-sm mb-1">{label}</span>
                      <span className="text-zinc-800 dark:text-zinc-200 font-medium">{value}</span>
                  </div>
                  ))}
                </div>

                <div className="flex gap-4 flex-wrap">
                  <span className={`px-4 py-2 rounded-full text-sm font-medium ${
                    project.cupSize === '人工' 
                      ? 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200 border border-rose-200 dark:border-rose-800' 
                      : 'bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-200 border border-emerald-200 dark:border-emerald-800'
                  } shadow-sm hover:shadow-md transition-shadow duration-300 transform hover:scale-105 transition-transform duration-300`}>
                    {project.cupSize}
                  </span>
                  <span className={`px-4 py-2 rounded-full text-sm font-medium 
                    ${(() => {
                      // 根據杯型大小設置不同的顏色
                      switch(project.breastSize) {
                        case 'A': return 'bg-sky-100 dark:bg-sky-900 text-sky-800 dark:text-sky-200 border border-sky-200 dark:border-sky-800';
                        case 'B': return 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 border border-blue-200 dark:border-blue-800';
                        case 'C': return 'bg-indigo-100 dark:bg-indigo-900 text-indigo-800 dark:text-indigo-200 border border-indigo-200 dark:border-indigo-800';
                        case 'D': return 'bg-violet-100 dark:bg-violet-900 text-violet-800 dark:text-violet-200 border border-violet-200 dark:border-violet-800';
                        case 'E': return 'bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-200 border border-purple-200 dark:border-purple-800';
                        case 'F': return 'bg-fuchsia-100 dark:bg-fuchsia-900 text-fuchsia-800 dark:text-fuchsia-200 border border-fuchsia-200 dark:border-fuchsia-800';
                        case 'G': return 'bg-pink-100 dark:bg-pink-900 text-pink-800 dark:text-pink-200 border border-pink-200 dark:border-pink-800';
                        case 'H': return 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200 border border-rose-200 dark:border-rose-800';
                        case 'I': return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200 border border-red-200 dark:border-red-800';
                        case 'J': return 'bg-orange-100 dark:bg-orange-900 text-orange-800 dark:text-orange-200 border border-orange-200 dark:border-orange-800';
                        case 'K': return 'bg-amber-100 dark:bg-amber-900 text-amber-800 dark:text-amber-200 border border-amber-200 dark:border-amber-800';
                        case 'L': return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200 border border-yellow-200 dark:border-yellow-800';
                        case 'M': return 'bg-lime-100 dark:bg-lime-900 text-lime-800 dark:text-lime-200 border border-lime-200 dark:border-lime-800';
                        default: return 'bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-800';
                      }
                    })()}
                    shadow-sm hover:shadow-md transition-shadow duration-300 transform hover:scale-105 transition-transform duration-300`}>
                    {project.breastSize} Cup
                  </span>
                  <span className={`px-4 py-2 rounded-full text-sm font-medium ${
                    project.status === '可預約' || project.status === '尚可預約' 
                      ? 'bg-emerald-100 dark:bg-emerald-900 text-emerald-800 dark:text-emerald-200 border border-emerald-200 dark:border-emerald-800 animate-blink-slow'
                      : project.status === '今日約滿'
                      ? 'bg-rose-100 dark:bg-rose-900 text-rose-800 dark:text-rose-200 border border-rose-200 dark:border-rose-800'
                      : 'bg-zinc-200 dark:bg-zinc-700 text-zinc-800 dark:text-zinc-300 border border-zinc-300 dark:border-zinc-600'
                  } shadow-sm hover:shadow-md transition-shadow duration-300 transform hover:scale-105 transition-transform duration-300`}>
                    {project.status}
                  </span>
                </div>
              </div>

              <div className="bg-zinc-100 dark:bg-zinc-700 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <div className="space-y-8">
                  {(!project.servicePackages && 
                    !project.serviceItems && 
                    !project.additionalServices && 
                    !project.packageDeals && 
                    !project.notes) && (
                    <p className="text-zinc-500 dark:text-zinc-400 text-center">暫無詳細資訊</p>
                  )}
                  
                  {[
                    ['服務方案', project.servicePackages],
                    ['服務項目', project.serviceItems],
                    ['加值服務', project.additionalServices],
                    ['方案優惠', project.packageDeals],
                    ['備註', project.notes]
                  ].map(([title, content], index) => content && (
                    <div key={`service-${index}-${title}`} className="bg-white/50 dark:bg-zinc-600/50 p-4 rounded-lg hover:bg-white/80 dark:hover:bg-zinc-600/80 transition-colors duration-300 shadow-sm hover:shadow-md transition-shadow duration-300">
                      <div className="relative mb-4">
                        <h2 className="text-lg font-bold text-zinc-800 dark:text-zinc-300 border-b-2 border-rose-400 dark:border-rose-500 pb-2 inline-block">
                          {title}
                          <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-rose-400 to-purple-400"></span>
                        </h2>
                      </div>
                      <p className="text-zinc-700 dark:text-zinc-300 whitespace-pre-line leading-relaxed">
                        {content}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* 客服連結按鈕 */}
              <div className="bg-zinc-100 dark:bg-zinc-700 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <div className="relative mb-4">
                  <h2 className="text-lg font-bold text-zinc-800 dark:text-zinc-300 border-b-2 border-rose-400 dark:border-rose-500 pb-2 inline-block">
                    聯絡預約
                    <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-gradient-to-r from-rose-400 to-purple-400"></span>
                  </h2>
                </div>
                <p className="text-zinc-700 dark:text-zinc-300 mb-4">
                  想約她嗎？馬上聯絡客服：
                </p>
                
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                  <a 
                    href="http://localhost:5000/api/settings/links"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 
                      rounded-lg transition-all duration-300 transform hover:scale-105 text-center"
                    onClick={(e) => {
                      e.preventDefault();
                      fetch('http://localhost:5000/api/settings/links')
                        .then(response => response.json())
                        .then(data => {
                          window.open(data.tg, '_blank');
                        })
                        .catch(error => {
                          console.error('獲取TG連結失敗:', error);
                        });
                    }}
                  >
                    <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.248-1.97 9.296c-.146.658-.537.818-1.084.51l-3-2.21-1.446 1.394c-.16.16-.295.295-.605.295l.213-3.053 5.56-5.023c.242-.213-.054-.334-.373-.121l-6.871 4.326-2.962-.924c-.643-.204-.657-.643.136-.953l11.57-4.461c.534-.197 1.001.13.832.924z"/>
                    </svg>
                    Telegram 預約
                  </a>
                  
                  <a 
                    href="http://localhost:5000/api/settings/links"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white px-6 py-3 
                      rounded-lg transition-all duration-300 transform hover:scale-105 text-center"
                    onClick={(e) => {
                      e.preventDefault();
                      fetch('http://localhost:5000/api/settings/links')
                        .then(response => response.json())
                        .then(data => {
                          window.open(data.line, '_blank');
                        })
                        .catch(error => {
                          console.error('獲取LINE連結失敗:', error);
                        });
                    }}
                  >
                    <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                      <path d="M272.1 204.2v71.1c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.1 0-2.1-.6-2.6-1.3l-32.6-44v42.2c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.8 0-3.2-1.4-3.2-3.2v-71.1c0-1.8 1.4-3.2 3.2-3.2H219c1.1 0 2.1.6 2.6 1.3l32.6 44v-42.2c0-1.8 1.4-3.2 3.2-3.2h11.4c1.8-.1 3.3 1.4 3.3 3.1zm-82-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 1.8 1.4 3.2 3.2 3.2h11.4c1.8 0 3.2-1.4 3.2-3.2v-71.1c0-1.7-1.4-3.2-3.2-3.2zm-27.5 59.6h-31.1v-56.4c0-1.8-1.4-3.2-3.2-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 .9.3 1.6.9 2.2.6.5 1.3.9 2.2.9h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.7-1.4-3.2-3.1-3.2zM332.1 201h-45.7c-1.7 0-3.2 1.4-3.2 3.2v71.1c0 1.7 1.4 3.2 3.2 3.2h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2V234c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2v-11.4c-.1-1.7-1.5-3.2-3.2-3.2zM448 113.7V399c-.1 44.8-36.8 81.1-81.7 81H81c-44.8-.1-81.1-36.9-81-81.7V113c.1-44.8 36.9-81.1 81.7-81H367c44.8.1 81.1 36.8 81 81.7zm-61.6 122.6c0-73-73.2-132.4-163.1-132.4-89.9 0-163.1 59.4-163.1 132.4 0 65.4 58 120.2 136.4 130.6 19.1 4.1 16.9 11.1 12.6 36.8-.7 4.1-3.3 16.1 14.1 8.8 17.4-7.3 93.9-55.3 128.2-94.7 23.6-26 34.9-52.3 34.9-81.5z"/>
                    </svg>
                    LINE 預約
                  </a>
                </div>
                
                <p className="text-zinc-500 dark:text-zinc-400 text-sm mt-4 text-center">
                  預約時請告知妹妹名稱：<span className={`font-bold 
                    ${(() => {
                      // 根據杯型大小設置不同的顏色
                      switch(project.breastSize) {
                        case 'A': return 'text-sky-700 dark:text-sky-300';
                        case 'B': return 'text-blue-700 dark:text-blue-300';
                        case 'C': return 'text-indigo-700 dark:text-indigo-300';
                        case 'D': return 'text-violet-700 dark:text-violet-300';
                        case 'E': return 'text-purple-700 dark:text-purple-300';
                        case 'F': return 'text-fuchsia-700 dark:text-fuchsia-300';
                        case 'G': return 'text-pink-700 dark:text-pink-300';
                        case 'H': return 'text-rose-700 dark:text-rose-300';
                        case 'I': return 'text-red-700 dark:text-red-300';
                        case 'J': return 'text-orange-700 dark:text-orange-300';
                        case 'K': return 'text-amber-700 dark:text-amber-300';
                        case 'L': return 'text-yellow-700 dark:text-yellow-300';
                        case 'M': return 'text-lime-700 dark:text-lime-300';
                        default: return 'text-zinc-700 dark:text-zinc-300';
                      }
                    })()}`}>{project.name}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* 評論區塊 */}
        <div className="mt-8" id="comments">
          {renderComments()}
        </div>
      </div>
    </div>
  );
}

export default ProjectDetail; 