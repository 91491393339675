import React, { createContext, useContext, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// 創建主題上下文
const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {},
  isChangingTheme: false
});

// 使用主題的自定義鉤子
export const useTheme = () => useContext(ThemeContext);

// 主題提供者組件
export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isChangingTheme, setIsChangingTheme] = useState(false);

  // 初始化主題
  useEffect(() => {
    const theme = localStorage.getItem('theme');
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    // 如果有保存的主題設置，使用它；否則，使用系統偏好
    const initialDarkMode = theme 
      ? theme === 'dark' 
      : prefersDark;
    
    setIsDarkMode(initialDarkMode);
    
    if (initialDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  // 切換主題
  const toggleTheme = () => {
    if (isChangingTheme) return; // 防止動畫過程中重複切換
    
    setIsChangingTheme(true);
    
    // 創建過渡遮罩
    const overlay = document.createElement('div');
    overlay.className = 'fixed inset-0 bg-black pointer-events-none';
    overlay.style.zIndex = '9999';
    overlay.style.opacity = '0';
    overlay.style.transition = 'opacity 0.5s ease';
    document.body.appendChild(overlay);
    
    // 淡入過渡效果
    setTimeout(() => {
      overlay.style.opacity = '0.15';
      
      // 切換主題
      setTimeout(() => {
        setIsDarkMode(prev => !prev);
        
        if (isDarkMode) {
          document.documentElement.classList.remove('dark');
          localStorage.setItem('theme', 'light');
        } else {
          document.documentElement.classList.add('dark');
          localStorage.setItem('theme', 'dark');
        }
        
        // 淡出過渡效果
        setTimeout(() => {
          overlay.style.opacity = '0';
          setTimeout(() => {
            document.body.removeChild(overlay);
            setIsChangingTheme(false);
          }, 500);
        }, 300);
      }, 200);
    }, 10);
  };

  // 提供主題上下文
  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme, isChangingTheme }}>
      {children}
      
      {/* 主題切換時的全局過渡效果 */}
      <AnimatePresence>
        {isChangingTheme && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="fixed inset-0 bg-black pointer-events-none"
            style={{ zIndex: 9998 }}
          />
        )}
      </AnimatePresence>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider; 