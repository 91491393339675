import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from './ThemeProvider';

function ThemeToggle({ className = '' }) {
  const { isDarkMode, toggleTheme, isChangingTheme } = useTheme();

  // 圖標動畫變體
  const iconVariants = {
    initial: { scale: 0.6, rotate: -30, opacity: 0 },
    animate: { scale: 1, rotate: 0, opacity: 1, transition: { duration: 0.5, type: "spring", stiffness: 200 } },
    exit: { scale: 0.6, rotate: 30, opacity: 0, transition: { duration: 0.3 } }
  };

  return (
    <motion.button
      onClick={toggleTheme}
      className={`p-2 rounded-lg transition-all duration-500 overflow-hidden relative
        ${isDarkMode 
          ? 'bg-zinc-700 hover:bg-zinc-600 text-zinc-300' 
          : 'bg-zinc-200 hover:bg-zinc-300 text-zinc-700'
        } ${className}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      disabled={isChangingTheme}
    >
      {/* 背景動畫效果 */}
      <motion.div 
        className={`absolute inset-0 ${isDarkMode ? 'bg-blue-500/10' : 'bg-amber-500/10'}`}
        initial={false}
        animate={{ 
          opacity: [0, 0.5, 0],
          scale: [0.8, 1.2, 1]
        }}
        transition={{ duration: 1, repeat: 0 }}
        key={isDarkMode ? 'dark' : 'light'}
      />
      
      <AnimatePresence mode="wait">
        {isDarkMode ? (
          <motion.div
            key="sun"
            variants={iconVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="w-5 h-5"
          >
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" 
              />
            </svg>
          </motion.div>
        ) : (
          <motion.div
            key="moon"
            variants={iconVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="w-5 h-5"
          >
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" 
              />
            </svg>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.button>
  );
}

export default ThemeToggle; 