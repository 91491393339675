import { useRef, useEffect, useState } from 'react';
import { useSpringRef, useTrail, animated } from '@react-spring/web';

const BlurText = ({
  text = '',
  delay = 200,
  className = '',
  animateBy = 'words', // 'words' or 'letters'
  direction = 'top', // 'top' or 'bottom'
  threshold = 0.1,
  rootMargin = '0px',
  animationFrom,
  animationTo,
  easing = 'easeOutCubic',
  onAnimationComplete,
}) => {
  const elements = animateBy === 'words' ? text.split(' ') : text.split('');
  const [inView, setInView] = useState(false);
  const ref = useRef();
  const springRef = useSpringRef();

  const defaultFrom =
    direction === 'top'
      ? { filter: 'blur(10px)', opacity: 0, transform: 'translate3d(0,-50px,0)' }
      : { filter: 'blur(10px)', opacity: 0, transform: 'translate3d(0,50px,0)' };

  const defaultTo = { filter: 'blur(0px)', opacity: 1, transform: 'translate3d(0,0,0)' };

  // 將文本拆分為字符數組
  const items = Array.from(text);
  
  // 使用useTrail為每個元素創建動畫
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    ref: springRef,
    from: animationFrom || defaultFrom,
    to: inView ? (animationTo || defaultTo) : (animationFrom || defaultFrom),
    delay: index => index * delay,
    onRest: index => {
      if (index === elements.length - 1 && onAnimationComplete) {
        onAnimationComplete();
      }
    },
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.unobserve(ref.current);
        }
      },
      { threshold, rootMargin }
    );

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [threshold, rootMargin]);

  // 當元素進入視圖時啟動動畫
  useEffect(() => {
    if (inView) {
      springRef.start();
    }
  }, [inView, springRef]);

  return (
    <p ref={ref} className={`blur-text ${className}`}>
      {trail.map((props, index) => (
        <animated.span
          key={index}
          style={{
            ...props,
            display: 'inline-block',
            willChange: 'transform, filter, opacity',
          }}
        >
          {elements[index] === ' ' ? '\u00A0' : elements[index]}
          {animateBy === 'words' && index < elements.length - 1 && '\u00A0'}
        </animated.span>
      ))}
    </p>
  );
};

export default BlurText; 