import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { memberApi } from '../utils/api';

function Profile() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [Username, setUsername] = useState('');
  const [isResetingPassword, setIsResetingPassword] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  // 根據會員等級返回對應的顏色類
  const getMemberLevelColor = (identityGroup) => {
    if (!identityGroup) return 'from-gray-400/20 to-gray-500/20 text-gray-700 dark:text-gray-300 border-gray-400/30 dark:border-gray-500/30';
    
    // 提取等級數字
    const levelMatch = identityGroup.match(/Lv\.(\d+|[\?]+)/i);
    
    // 特殊身份處理
    if (identityGroup === '惡意評論的哥布林') {
      return 'from-gray-400/20 to-gray-500/20 text-gray-700 dark:text-gray-300 border-gray-400/30 dark:border-gray-500/30';
    }
    
    if (!levelMatch) return 'from-gray-400/20 to-gray-500/20 text-gray-700 dark:text-gray-300 border-gray-400/30 dark:border-gray-500/30';
    
    const level = levelMatch[1];
    
    // 根據等級範圍返回不同顏色
    if (level === '???' || level === '？？？') {
      return 'from-purple-400/20 to-purple-500/20 text-purple-700 dark:text-purple-300 border-purple-400/30 dark:border-purple-500/30';
    } else {
      const levelNum = parseInt(level, 10);
      if (level === '??') {
        return 'from-amber-400/20 to-amber-500/20 text-amber-700 dark:text-amber-300 border-amber-400/30 dark:border-amber-500/30';
      } else if (levelNum >= 0 && levelNum <= 3) {
        return 'from-green-400/20 to-green-500/20 text-green-700 dark:text-green-300 border-green-400/30 dark:border-green-500/30';
      } else if (levelNum >= 4 && levelNum <= 7) {
        return 'from-blue-400/20 to-blue-500/20 text-blue-700 dark:text-blue-300 border-blue-400/30 dark:border-blue-500/30';
      } else if (levelNum >= 8 && levelNum <= 11) {
        return 'from-yellow-400/20 to-yellow-500/20 text-yellow-700 dark:text-yellow-300 border-yellow-400/30 dark:border-yellow-500/30';
      } else if (levelNum >= 12 && levelNum <= 15) {
        return 'from-rose-400/20 to-rose-500/20 text-rose-700 dark:text-rose-300 border-rose-400/30 dark:border-rose-500/30';
      }
    }
    
    // 默認顏色
    return 'from-gray-400/20 to-gray-500/20 text-gray-700 dark:text-gray-300 border-gray-400/30 dark:border-gray-500/30';
  };

  // 獲取會員等級的文字漸變顏色
  const getMemberLevelTextGradient = (identityGroup) => {
    if (!identityGroup) return 'from-gray-500 to-gray-700 dark:from-gray-400 dark:to-gray-200';
    
    // 提取等級數字
    const levelMatch = identityGroup.match(/Lv\.(\d+|[\?]+)/i);
    
    // 特殊身份處理
    if (identityGroup === '惡意評論的哥布林') {
      return 'from-gray-500 to-gray-700 dark:from-gray-400 dark:to-gray-200';
    }
    
    if (!levelMatch) return 'from-gray-500 to-gray-700 dark:from-gray-400 dark:to-gray-200';
    
    const level = levelMatch[1];
    
    // 根據等級範圍返回不同顏色
    if (level === '???' || level === '？？？') {
      return 'from-purple-500 to-purple-700 dark:from-purple-400 dark:to-purple-200';
    } else {
      const levelNum = parseInt(level, 10);
      if (level === '??') {
        return 'from-amber-500 to-amber-700 dark:from-amber-400 dark:to-amber-200';
      } else if (levelNum >= 0 && levelNum <= 3) {
        return 'from-green-500 to-green-700 dark:from-green-400 dark:to-green-200';
      } else if (levelNum >= 4 && levelNum <= 7) {
        return 'from-blue-500 to-blue-700 dark:from-blue-400 dark:to-blue-200';
      } else if (levelNum >= 8 && levelNum <= 11) {
        return 'from-yellow-500 to-yellow-700 dark:from-yellow-400 dark:to-yellow-200';
      } else if (levelNum >= 12 && levelNum <= 15) {
        return 'from-rose-500 to-rose-700 dark:from-rose-400 dark:to-rose-200';
      }
    }
    
    // 默認顏色
    return 'from-gray-500 to-gray-700 dark:from-gray-400 dark:to-gray-200';
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('memberToken');
        if (!token) {
          navigate('/login');
          return;
        }

        const response = await memberApi.get('/member/profile');
        setUserData(response.data);
        setUsername(response.data.Username || '');
      } catch (error) {
        console.error('獲取個人資料失敗:', error);
        toast.error('獲取個人資料失敗');
        if (error.response?.status === 401) {
          localStorage.removeItem('memberToken');
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('memberToken');
    toast.success('已登出');
    navigate('/login');
  };

  const handleSave = async () => {
    let loadingToast = null;
    try {
      const token = localStorage.getItem('memberToken');
      if (!token) {
        navigate('/login');
        return;
      }

      if (!Username.trim()) {
        toast.error('用戶名不能為空');
        return;
      }

      loadingToast = toast.loading('正在保存...');
      const response = await memberApi.put('/member/profile', { Username: Username.trim() });
      
      setUserData(response.data);
      setIsEditing(false);
      toast.success('保存成功！', { id: loadingToast });
    } catch (error) {
      console.error('更新個人資料失敗:', error);
      if (loadingToast) {
        toast.error(error.message || '更新個人資料失敗', { id: loadingToast });
      } else {
        toast.error(error.message || '更新個人資料失敗');
      }
      if (error.response?.status === 401) {
        localStorage.removeItem('memberToken');
        navigate('/login');
      }
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // 在输入时验证密码长度
    const passwordLengthError = document.getElementById('passwordLengthError');
    if (name === 'newPassword' && value.length > 0 && value.length < 6) {
      passwordLengthError.textContent = '新密碼長度必須至少為6個字符';
      passwordLengthError.classList.remove('text-gray-500', 'dark:text-gray-400');
      passwordLengthError.classList.add('text-red-500', 'dark:text-red-400');
    } else if (name === 'newPassword') {
      passwordLengthError.textContent = '密碼長度必須至少為6個字符';
      passwordLengthError.classList.remove('text-red-500', 'dark:text-red-400');
      passwordLengthError.classList.add('text-gray-500', 'dark:text-gray-400');
    }
    
    // 验证两次密码是否一致
    const passwordMatchError = document.getElementById('passwordMatchError');
    if (name === 'confirmPassword' || name === 'newPassword') {
      if ((name === 'confirmPassword' && value !== passwordData.newPassword && value) || 
          (name === 'newPassword' && value !== passwordData.confirmPassword && passwordData.confirmPassword)) {
        passwordMatchError.textContent = '兩次輸入的新密碼不一致';
        passwordMatchError.classList.remove('text-gray-500', 'dark:text-gray-400', 'hidden');
        passwordMatchError.classList.add('text-red-500', 'dark:text-red-400');
      } else {
        passwordMatchError.textContent = '';
        passwordMatchError.classList.add('hidden');
      }
    }
  };

  const handleResetPassword = async () => {
    let loadingToast = null;
    try {
      const token = localStorage.getItem('memberToken');
      if (!token) {
        navigate('/login');
        return;
      }

      // 驗證密碼
      if (!passwordData.currentPassword) {
        toast.error('請輸入當前密碼');
        return;
      }
      if (!passwordData.newPassword) {
        toast.error('請輸入新密碼');
        return;
      }
      if (passwordData.newPassword.length < 6) {
        // 不使用toast，因為已經在輸入框下方顯示了錯誤信息
        return;
      }
      if (passwordData.newPassword !== passwordData.confirmPassword) {
        // 不使用toast，因為已經在輸入框下方顯示了錯誤信息
        return;
      }

      loadingToast = toast.loading('正在重置密碼...');
      await memberApi.put('/member/reset-password', {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword
      });

      setIsResetingPassword(false);
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
      toast.success('密碼重置成功！', { id: loadingToast });
    } catch (error) {
      console.error('重置密碼失敗:', error);
      if (loadingToast) {
        toast.error(error.message || '重置密碼失敗', { id: loadingToast });
      } else {
        toast.error(error.message || '重置密碼失敗');
      }
      if (error.response?.status === 401) {
        localStorage.removeItem('memberToken');
        navigate('/login');
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-zinc-100 dark:bg-zinc-900 pt-20">
        <div className="container mx-auto px-4">
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-zinc-100 dark:bg-zinc-900 pt-20 pb-10">
      <style>
        {`
          @keyframes gradient {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
          
          .animate-gradient-text {
            background-size: 200% auto;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: gradient 3s ease-in-out infinite;
          }
        `}
      </style>
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto bg-white dark:bg-zinc-800 rounded-lg shadow-md overflow-hidden">
          {/* 頂部標題區域 */}
          <div className="bg-gradient-to-r from-blue-600 to-indigo-700 p-6">
            <h1 className="text-2xl font-bold text-white mb-1">個人資料</h1>
            <p className="text-blue-100 text-sm">管理您的帳號資訊和安全設置</p>
          </div>
          
          <div className="p-6 space-y-6">
            {/* 帳號資訊卡片 */}
            <div className="bg-gray-50 dark:bg-zinc-700 rounded-lg p-5 shadow-sm">
              <h2 className="text-lg font-medium text-gray-800 dark:text-gray-200 mb-4 border-b border-gray-200 dark:border-gray-600 pb-2">帳號資訊</h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* 帳號 */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    帳號編號
                  </label>
                  <div className="flex items-center">
                    <div className="text-lg text-zinc-900 dark:text-zinc-100 font-mono bg-gray-100 dark:bg-zinc-600 px-3 py-1 rounded">
                      {userData?._id ? userData._id.substring(0, 8) : '-'}
                    </div>
                  </div>
                </div>
                
                {/* 帳號 */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    帳號
                  </label>
                  <div className="flex items-center">
                    <div className="text-lg text-zinc-900 dark:text-zinc-100 font-semibold">
                      {userData?.Useraccount || '-'}
                    </div>
                  </div>
                </div>
                
                {/* 身分組 */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    身分組
                  </label>
                  <div className="flex items-center">
                    <div className={`text-lg font-semibold px-3 py-1 rounded-full bg-gradient-to-r ${getMemberLevelColor(userData?.identityGroup)} border`}>
                      <span className={`bg-gradient-to-r ${getMemberLevelTextGradient(userData?.identityGroup)} animate-gradient-text`}>
                        {userData?.identityGroup === '惡意評論的哥布林' && (
                          <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4 mr-1 text-red-600 dark:text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                          </svg>
                        )}
                        {userData?.identityGroup || 'Lv.1初階哥布林'}
                      </span>
                    </div>
                  </div>
                </div>
                
                {/* 用戶名 */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    用戶名
                  </label>
                  {isEditing ? (
                    <div className="flex flex-col space-y-2">
                      <input
                        type="text"
                        value={Username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="block w-full px-3 py-2 border border-zinc-300 dark:border-zinc-600 rounded-md shadow-sm bg-white dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="請輸入用戶名"
                        maxLength={50}
                      />
                      <div className="flex space-x-2">
                        <button
                          onClick={() => {
                            setIsEditing(false);
                            setUsername(userData?.Username || '');
                          }}
                          className="flex-1 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 text-sm font-medium"
                        >
                          取消
                        </button>
                        <button
                          onClick={handleSave}
                          className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-sm font-medium"
                        >
                          保存
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-between">
                      <div className="text-lg text-zinc-900 dark:text-zinc-100">
                        {userData?.Username || '-'}
                      </div>
                      <button
                        onClick={() => setIsEditing(true)}
                        className="px-3 py-1 text-sm text-blue-600 hover:text-blue-700 bg-blue-50 dark:bg-blue-900 dark:text-blue-300 hover:bg-blue-100 dark:hover:bg-blue-800 rounded-md focus:outline-none"
                      >
                        編輯
                      </button>
                    </div>
                  )}
                </div>
                
                {/* 註冊時間 */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    註冊時間
                  </label>
                  <div className="text-lg text-zinc-900 dark:text-zinc-100">
                    {userData?.createdAt ? new Date(userData.createdAt).toLocaleDateString('zh-TW', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }) : '-'}
                  </div>
                </div>
              </div>
            </div>
            
            {/* 密碼設置卡片 */}
            <div className="bg-gray-50 dark:bg-zinc-700 rounded-lg p-5 shadow-sm">
              <div className="flex items-center justify-between mb-4 border-b border-gray-200 dark:border-gray-600 pb-2">
                <h2 className="text-lg font-medium text-gray-800 dark:text-gray-200">密碼設置</h2>
                {!isResetingPassword && (
                  <button
                    onClick={() => setIsResetingPassword(true)}
                    className="px-4 py-2 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    重置密碼
                  </button>
                )}
              </div>
              
              {isResetingPassword ? (
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300 mb-1">
                      當前密碼
                    </label>
                    <input
                      type="password"
                      name="currentPassword"
                      value={passwordData.currentPassword}
                      onChange={handlePasswordChange}
                      className="block w-full px-3 py-2 border border-zinc-300 dark:border-zinc-600 rounded-md shadow-sm bg-white dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="請輸入當前密碼"
                    />
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300 mb-1">
                      新密碼
                    </label>
                    <input
                      type="password"
                      name="newPassword"
                      value={passwordData.newPassword}
                      onChange={handlePasswordChange}
                      className="block w-full px-3 py-2 border border-zinc-300 dark:border-zinc-600 rounded-md shadow-sm bg-white dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="請輸入新密碼"
                    />
                    <p id="passwordLengthError" className="mt-1 text-xs text-gray-500 dark:text-gray-400">密碼長度必須至少為6個字符</p>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300 mb-1">
                      確認新密碼
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={passwordData.confirmPassword}
                      onChange={handlePasswordChange}
                      className="block w-full px-3 py-2 border border-zinc-300 dark:border-zinc-600 rounded-md shadow-sm bg-white dark:bg-zinc-700 text-zinc-900 dark:text-zinc-100 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="請再次輸入新密碼"
                    />
                    <p id="passwordMatchError" className="mt-1 text-xs text-gray-500 dark:text-gray-400 hidden">兩次輸入的新密碼不一致</p>
                  </div>
                  
                  <div className="flex justify-end space-x-2 pt-2">
                    <button
                      onClick={handleResetPassword}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      確認重置
                    </button>
                    <button
                      onClick={() => {
                        setIsResetingPassword(false);
                        setPasswordData({
                          currentPassword: '',
                          newPassword: '',
                          confirmPassword: ''
                        });
                      }}
                      className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      取消
                    </button>
                  </div>
                </div>
              ) : (
                <p className="text-gray-600 dark:text-gray-400 text-sm">
                  為了保護您的帳號安全，建議定期更換密碼，並使用包含字母、數字和特殊符號的強密碼。
                </p>
              )}
            </div>
            
            {/* 登出按鈕 */}
            <button
              onClick={handleLogout}
              className="w-full px-4 py-3 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 font-medium"
            >
              登出
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile; 