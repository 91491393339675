import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function AdminLayout({ children }) {
  const navigate = useNavigate();

  // 確保管理員頁面使用淺色主題
  useEffect(() => {
    // 移除深色主題類
    document.documentElement.classList.remove('dark');
    
    // 清理函數，當組件卸載時執行
    return () => {
      // 恢復用戶之前的主題設置
      const theme = localStorage.getItem('theme');
      if (theme === 'dark') {
        document.documentElement.classList.add('dark');
      }
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin/login');
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* 側邊欄 */}
      <div className="fixed inset-y-0 left-0 w-48 bg-gray-800 shadow-lg z-10">
        <div className="flex items-center justify-center h-14 bg-gray-900">
          <h2 className="text-white text-lg font-bold">後台管理系統</h2>
        </div>
        <nav className="mt-4">
          <div className="px-2">
            <Link 
              to="/admin/dashboard" 
              className="flex items-center px-3 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
              </svg>
              儀表板
            </Link>
            <Link 
              to="/admin/home-components" 
              className="flex items-center px-3 py-2 mt-1 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path>
              </svg>
              首頁組件管理
            </Link>
            <Link 
              to="/admin/projects" 
              className="flex items-center px-3 py-2 mt-1 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
              </svg>
              作品管理
            </Link>
            <Link 
              to="/admin/users" 
              className="flex items-center px-3 py-2 mt-1 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
              </svg>
              用戶管理
            </Link>
            <Link 
              to="/admin/comments" 
              className="flex items-center px-3 py-2 mt-1 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path>
              </svg>
              評論管理
            </Link>
            <button
              onClick={handleLogout}
              className="flex items-center w-full px-3 py-2 mt-1 text-sm text-gray-300 hover:bg-gray-700 hover:text-white rounded transition-colors duration-200"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
              </svg>
              登出
            </button>
          </div>
        </nav>
      </div>

      {/* 主要內容區 */}
      <div className="ml-48 p-6 bg-gray-100 min-h-screen">
        {children}
      </div>
    </div>
  );
}

export default AdminLayout;