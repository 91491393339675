import { useRef, useEffect } from "react";

const Squares = ({
  direction = "right",
  speed = 1,
  borderColor = "#999",
  squareSize = 40,
  hoverFillColor = "#222",
  pattern = "diagonal",
}) => {
  const canvasRef = useRef(null);
  const requestRef = useRef(null);
  const numSquaresX = useRef(0);
  const numSquaresY = useRef(0);
  const gridOffset = useRef({ x: 0, y: 0 });
  const hoveredSquareRef = useRef(null);
  const isDarkMode = useRef(false);

  useEffect(() => {
    // 檢測當前主題模式
    const checkDarkMode = () => {
      isDarkMode.current = document.documentElement.classList.contains('dark');
    };
    
    // 初始檢查
    checkDarkMode();
    
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");

    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      numSquaresX.current = Math.ceil(canvas.width / squareSize) + 1;
      numSquaresY.current = Math.ceil(canvas.height / squareSize) + 1;
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    const drawGrid = () => {
      if (!ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const startX = Math.floor(gridOffset.current.x / squareSize) * squareSize;
      const startY = Math.floor(gridOffset.current.y / squareSize) * squareSize;

      // 根據主題模式選擇不同的顏色
      const gridBorderColor = isDarkMode.current 
        ? borderColor 
        : "rgba(200, 200, 200, 0.15)"; // 淺色主題下更柔和的邊框顏色
      
      const gridHoverFillColor = isDarkMode.current 
        ? hoverFillColor 
        : "rgba(180, 180, 180, 0.1)"; // 淺色主題下更柔和的懸停顏色

      if (pattern === "square") {
        // 方塊背景模式
        for (let x = startX; x < canvas.width + squareSize; x += squareSize) {
          for (let y = startY; y < canvas.height + squareSize; y += squareSize) {
            const squareX = x - (gridOffset.current.x % squareSize);
            const squareY = y - (gridOffset.current.y % squareSize);
            
            // 棋盤格效果 - 淺色主題下使用更柔和的顏色
            if ((Math.floor(x / squareSize) + Math.floor(y / squareSize)) % 2 === 0) {
              ctx.fillStyle = isDarkMode.current 
                ? "rgba(240, 240, 240, 0.05)" 
                : "rgba(180, 180, 180, 0.03)"; // 淺色主題下更柔和的填充顏色
              ctx.fillRect(squareX, squareY, squareSize, squareSize);
            }

            if (
              hoveredSquareRef.current &&
              Math.floor((x - startX) / squareSize) === hoveredSquareRef.current.x &&
              Math.floor((y - startY) / squareSize) === hoveredSquareRef.current.y
            ) {
              ctx.fillStyle = gridHoverFillColor;
              ctx.fillRect(squareX, squareY, squareSize, squareSize);
            }

            ctx.strokeStyle = gridBorderColor;
            ctx.strokeRect(squareX, squareY, squareSize, squareSize);
          }
        }
      } else {
        // 原有的背景模式
        for (let x = startX; x < canvas.width + squareSize; x += squareSize) {
          for (let y = startY; y < canvas.height + squareSize; y += squareSize) {
            const squareX = x - (gridOffset.current.x % squareSize);
            const squareY = y - (gridOffset.current.y % squareSize);

            if (
              hoveredSquareRef.current &&
              Math.floor((x - startX) / squareSize) === hoveredSquareRef.current.x &&
              Math.floor((y - startY) / squareSize) === hoveredSquareRef.current.y
            ) {
              ctx.fillStyle = gridHoverFillColor;
              ctx.fillRect(squareX, squareY, squareSize, squareSize);
            }

            ctx.strokeStyle = gridBorderColor;
            ctx.strokeRect(squareX, squareY, squareSize, squareSize);
          }
        }
      }

      // 根據主題調整漸變顏色
      const gradient = ctx.createRadialGradient(
        canvas.width / 2,
        canvas.height / 2,
        0,
        canvas.width / 2,
        canvas.height / 2,
        Math.sqrt(canvas.width ** 2 + canvas.height ** 2) / 2
      );
      
      if (isDarkMode.current) {
        gradient.addColorStop(0, "rgba(0, 0, 0, 0)");
        gradient.addColorStop(1, "#060606");
      } else {
        gradient.addColorStop(0, "rgba(255, 255, 255, 0)");
        gradient.addColorStop(1, "rgba(245, 245, 245, 0.7)"); // 淺色主題下更柔和的背景色
      }

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    };

    const updateAnimation = () => {
      // 檢查主題模式變化
      checkDarkMode();
      
      const effectiveSpeed = Math.max(speed, 0.1);
      switch (direction) {
        case "right":
          gridOffset.current.x =
            (gridOffset.current.x - effectiveSpeed + squareSize) % squareSize;
          break;
        case "left":
          gridOffset.current.x =
            (gridOffset.current.x + effectiveSpeed + squareSize) % squareSize;
          break;
        case "up":
          gridOffset.current.y =
            (gridOffset.current.y + effectiveSpeed + squareSize) % squareSize;
          break;
        case "down":
          gridOffset.current.y =
            (gridOffset.current.y - effectiveSpeed + squareSize) % squareSize;
          break;
        case "diagonal":
          gridOffset.current.x =
            (gridOffset.current.x - effectiveSpeed + squareSize) % squareSize;
          gridOffset.current.y =
            (gridOffset.current.y - effectiveSpeed + squareSize) % squareSize;
          break;
        default:
          break;
      }

      drawGrid();
      requestRef.current = requestAnimationFrame(updateAnimation);
    };

    const handleMouseMove = (event) => {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      const startX = Math.floor(gridOffset.current.x / squareSize) * squareSize;
      const startY = Math.floor(gridOffset.current.y / squareSize) * squareSize;

      const hoveredSquareX = Math.floor(
        (mouseX + gridOffset.current.x - startX) / squareSize
      );
      const hoveredSquareY = Math.floor(
        (mouseY + gridOffset.current.y - startY) / squareSize
      );

      if (
        !hoveredSquareRef.current ||
        hoveredSquareRef.current.x !== hoveredSquareX ||
        hoveredSquareRef.current.y !== hoveredSquareY
      ) {
        hoveredSquareRef.current = { x: hoveredSquareX, y: hoveredSquareY };
      }
    };

    const handleMouseLeave = () => {
      hoveredSquareRef.current = null;
    };

    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseleave", handleMouseLeave);
    requestRef.current = requestAnimationFrame(updateAnimation);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [direction, speed, borderColor, hoverFillColor, squareSize, pattern]);

  return (
    <canvas
      ref={canvasRef}
      className="w-full h-full border-none block"
    ></canvas>
  );
};

export default Squares; 