import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import AdminLayout from '../../layouts/AdminLayout';
import { adminApi } from '../../utils/api';

function CommentManagement() {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useState({
    projectName: '',
    username: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [deletingCommentId, setDeletingCommentId] = useState(null);
  const [deletingReplyId, setDeletingReplyId] = useState(null);
  const [isReplyDelete, setIsReplyDelete] = useState(false);
  const [isCommentsEnabled, setIsCommentsEnabled] = useState(true);
  const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);

  const fetchComments = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams({
        page: currentPage,
        limit: 10
      });

      if (searchParams.projectName?.trim()) {
        queryParams.append('projectName', searchParams.projectName.trim());
      }
      if (searchParams.username?.trim()) {
        queryParams.append('username', searchParams.username.trim());
      }

      console.log('Search params:', queryParams.toString());

      const { data } = await adminApi.get(`/comments?${queryParams}`);
      console.log('從後端接收到的數據:', data);
      const processedComments = data.comments.map(comment => ({
        ...comment,
        projectName: comment.projectId?.name || '未知作品'
      }));
      console.log('處理後的評論數據:', processedComments);

      setComments(processedComments);
      setTotalPages(Math.max(1, data.totalPages));
    } catch (error) {
      console.error('獲取評論失敗:', error);
      setError(error.message);
      toast.error(error.message);
      setComments([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  }, [currentPage, searchParams]);

  // 獲取評論設置
  const fetchCommentSettings = useCallback(async () => {
    try {
      const { data } = await adminApi.get('/settings/comments-display');
      setIsCommentsEnabled(data.showComments);
    } catch (error) {
      console.error('獲取評論設置失敗:', error);
      toast.error('獲取評論設置失敗');
    }
  }, []);

  // 更新評論設置
  const updateCommentSettings = async (enabled) => {
    try {
      setIsUpdatingSettings(true);
      const loadingToast = toast.loading('正在更新設置...');
      
      const { data } = await adminApi.put('/settings/comments-display', {
        showComments: enabled
      });

      setIsCommentsEnabled(data.showComments);
      toast.success('設置已更新！', { id: loadingToast });
    } catch (error) {
      console.error('更新設置失敗:', error);
      toast.error(error.message || '更新設置失敗');
    } finally {
      setIsUpdatingSettings(false);
    }
  };

  // 初始加載
  useEffect(() => {
    fetchComments();
    fetchCommentSettings();
  }, [fetchComments, fetchCommentSettings]);

  // 添加防抖處理
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchComments();
    }, 300); // 減少延遲時間到300ms

    return () => clearTimeout(timer);
  }, [fetchComments, searchParams]); // 添加searchParams作為依賴

  const handleDeleteClick = useCallback((commentId, replyId = null) => {
    setDeletingCommentId(commentId);
    setDeletingReplyId(replyId);
    setIsReplyDelete(!!replyId);
    setIsConfirmOpen(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!deletingCommentId) return;

    const loadingToast = toast.loading('正在刪除...');
    try {
      if (isReplyDelete && deletingReplyId) {
        // 刪除回覆
        await adminApi.delete(`/comments/${deletingCommentId}/replies/${deletingReplyId}`);
        toast.success('回覆已成功刪除', { id: loadingToast });
      } else {
        // 刪除主評論
        await adminApi.delete(`/comments/${deletingCommentId}`);
        toast.success('評論已成功刪除', { id: loadingToast });
      }
      await fetchComments();
    } catch (error) {
      console.error('刪除失敗:', error);
      toast.error(error.message, { id: loadingToast });
    } finally {
      setIsConfirmOpen(false);
      setDeletingCommentId(null);
      setDeletingReplyId(null);
      setIsReplyDelete(false);
    }
  }, [deletingCommentId, deletingReplyId, isReplyDelete, fetchComments]);

  // 添加鍵盤事件監聽
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (isConfirmOpen && e.key === 'Enter') {
        handleDelete();
      }
    };

    if (isConfirmOpen) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isConfirmOpen, handleDelete]);

  const handleSearch = useCallback((field, value) => {
    console.log('Search field:', field, 'value:', value);
    setSearchParams(prev => ({
      ...prev,
      [field]: value
    }));
    setCurrentPage(1); // 重置頁碼到第一頁
  }, []);

  // 表格內容渲染
  const renderTableContent = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan="6" className="text-center py-4">
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          </td>
        </tr>
      );
    }

    if (comments.length === 0) {
      return (
        <tr>
          <td colSpan="6" className="text-center py-4">
            暫無評論數據
          </td>
        </tr>
      );
    }

    return comments.map((comment) => (
      <div key={comment._id} className="bg-white rounded-lg shadow-sm border border-zinc-200">
        {/* 評論主體 */}
        <div className="p-4">
          {/* 作品名稱 */}
          <div className="mb-3 flex items-center gap-2">
            <span className="text-zinc-500">作品:</span>
            <span className="font-medium text-zinc-800">{comment.projectName}</span>
          </div>
          
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-full bg-gradient-to-br from-rose-500 to-rose-600 flex items-center justify-center text-white">
                {comment.user?.[0].toUpperCase()}
              </div>
              <div>
                <div className="font-medium text-zinc-800">{comment.user}</div>
                <span className="inline-block px-2 py-0.5 bg-gradient-to-r from-yellow-400/20 to-yellow-500/20 text-yellow-700 rounded-full border border-yellow-400/30 text-sm">
                  {comment.identityGroup}
                </span>
              </div>
            </div>
            <div className="flex items-center bg-yellow-400/10 px-3 py-1 rounded-full">
              <span className="font-medium text-lg text-yellow-600">{comment.rating}</span>
              <span className="text-yellow-500 ml-1">★</span>
            </div>
          </div>
          
          <p className="text-zinc-700 mb-3">{comment.content}</p>
          
          <div className="flex items-center justify-between text-sm text-zinc-500">
            <span>{new Date(comment.date).toLocaleString('zh-TW')}</span>
            <button
              onClick={() => handleDeleteClick(comment._id)}
              className="text-red-600 hover:text-red-900 transition-colors duration-200 hover:bg-red-50 px-3 py-1.5 rounded-lg border border-red-200 text-sm"
            >
              刪除
            </button>
          </div>
        </div>

        {/* 回覆列表 */}
        {comment.replies && comment.replies.length > 0 && (
          <div className="border-t border-zinc-200">
            {comment.replies.map((reply, index) => (
              <div key={index} className="p-4 bg-zinc-50 border-b border-zinc-200 last:border-b-0">
                <div className="flex items-center gap-2 mb-2">
                  <div className="w-6 h-6 rounded-full bg-gradient-to-br from-zinc-400 to-zinc-600 flex items-center justify-center text-white text-sm">
                    {reply.user?.[0].toUpperCase()}
                  </div>
                  <div>
                    <div className="font-medium text-sm text-zinc-800">{reply.user}</div>
                    <span className="inline-block px-2 py-0.5 bg-gradient-to-r from-yellow-400/20 to-yellow-500/20 text-yellow-700 rounded-full border border-yellow-400/30 text-xs">
                      {reply.identityGroup}
                    </span>
                  </div>
                </div>
                <p className="text-zinc-700 text-sm mb-2">{reply.content}</p>
                <div className="flex items-center justify-between">
                  <span className="text-xs text-zinc-500">
                    {new Date(reply.date).toLocaleString('zh-TW')}
                  </span>
                  <button
                    onClick={() => handleDeleteClick(comment._id, reply._id)}
                    className="text-red-600 hover:text-red-900 transition-colors duration-200 hover:bg-red-50 px-2 py-1 rounded-lg border border-red-200 text-xs"
                  >
                    刪除回覆
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    ));
  };

  return (
    <AdminLayout>
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">評論管理</h1>
          
          {/* 評論開關 */}
          <div className="flex items-center gap-3">
            <span className="text-zinc-600 dark:text-zinc-400">
              評論功能
            </span>
            <button
              onClick={() => updateCommentSettings(!isCommentsEnabled)}
              disabled={isUpdatingSettings}
              className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-300 focus:outline-none ${
                isCommentsEnabled ? 'bg-green-500' : 'bg-zinc-400'
              }`}
            >
              <span
                className={`inline-block h-4 w-4 transform rounded-full bg-white shadow-lg transition-transform duration-300 ${
                  isCommentsEnabled ? 'translate-x-6' : 'translate-x-1'
                }`}
              />
            </button>
          </div>
        </div>
        
        {error && (
          <div className="mx-4 lg:mx-6 mt-4 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        <div className="p-4 lg:p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
              <input
                type="text"
                placeholder="搜索作品名稱..."
                value={searchParams.projectName}
                onChange={(e) => setSearchParams(prev => ({ ...prev, projectName: e.target.value }))}
                className="w-full border border-gray-200 rounded-lg pl-10 pr-4 py-2.5
                  bg-white text-zinc-800
                  focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                  placeholder-zinc-400"
              />
              <button
                onClick={() => setSearchParams(prev => ({ ...prev, projectName: '' }))}
                className={`absolute inset-y-0 right-0 pr-3 flex items-center ${
                  searchParams.projectName ? 'text-gray-500 hover:text-gray-700' : 'hidden'
                } p-1 rounded-full hover:bg-zinc-100`}
              >
                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <input
                type="text"
                placeholder="搜索用戶名稱..."
                value={searchParams.username}
                onChange={(e) => setSearchParams(prev => ({ ...prev, username: e.target.value }))}
                className="w-full border border-gray-200 rounded-lg pl-10 pr-4 py-2.5
                  bg-white text-zinc-800
                  focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                  placeholder-zinc-400"
              />
              <button
                onClick={() => setSearchParams(prev => ({ ...prev, username: '' }))}
                className={`absolute inset-y-0 right-0 pr-3 flex items-center ${
                  searchParams.username ? 'text-gray-500 hover:text-gray-700' : 'hidden'
                } p-1 rounded-full hover:bg-zinc-100`}
              >
                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center py-12">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
              <span className="ml-3 text-gray-600">搜尋中...</span>
            </div>
          ) : comments.length === 0 ? (
            <div className="text-center py-12">
              <div className="text-gray-500 text-lg">
                {(searchParams.projectName || searchParams.username) ? 
                  '沒有找到符合條件的評論' : 
                  '暫無評論數據'}
              </div>
            </div>
          ) : (
            <>
              {/* 評論列表 */}
              <div className="space-y-6">
                {renderTableContent()}
              </div>
            </>
          )}

          {!loading && !error && comments.length > 0 && (
            <div className="flex flex-col lg:flex-row justify-center items-center gap-4 mt-6 bg-white p-4 rounded-lg shadow-sm">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className={`w-full lg:w-auto px-4 py-2 rounded-lg flex items-center justify-center gap-2 ${
                  currentPage === 1
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
                }`}
              >
                <span>←</span>
                <span>上一頁</span>
              </button>
              <div className="flex items-center gap-2">
                <span className="text-gray-600">第</span>
                <span className="font-medium text-gray-800">{currentPage}</span>
                <span className="text-gray-600">頁</span>
                <span className="text-gray-400">/</span>
                <span className="text-gray-600">共</span>
                <span className="font-medium text-gray-800">{totalPages}</span>
                <span className="text-gray-600">頁</span>
              </div>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className={`w-full lg:w-auto px-4 py-2 rounded-lg flex items-center justify-center gap-2 ${
                  currentPage === totalPages
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'
                }`}
              >
                <span>下一頁</span>
                <span>→</span>
              </button>
            </div>
          )}

          {/* 確認對話框 */}
          {isConfirmOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
              <div className="bg-white rounded-lg p-6 w-full max-w-sm">
                <h3 className="text-xl font-bold mb-4">確認刪除</h3>
                <p className="text-gray-600 mb-6">
                  確定要刪除此{isReplyDelete ? '回覆' : '評論'}嗎？此操作無法撤銷。
                  <br />
                  <span className="text-sm text-gray-500">（按下 Enter 鍵確認刪除）</span>
                </p>
                <div className="flex flex-col lg:flex-row justify-end gap-3 lg:gap-4">
                  <button
                    onClick={() => {
                      setIsConfirmOpen(false);
                      setDeletingCommentId(null);
                      setDeletingReplyId(null);
                      setIsReplyDelete(false);
                    }}
                    className="w-full lg:w-auto px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
                  >
                    取消
                  </button>
                  <button
                    onClick={handleDelete}
                    className="w-full lg:w-auto px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200"
                  >
                    確定刪除
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AdminLayout>
  );
}

export default CommentManagement; 