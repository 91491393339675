import React, { useState, useEffect } from 'react';

import AdminLayout from '../../layouts/AdminLayout';

import { toast } from 'react-hot-toast';

import { adminApi } from '../../utils/api';



function HomeComponentsManager() {

  const [components, setComponents] = useState({

    circularGallery: {

      enabled: true,

      autoRotate: true,

      rotationSpeed: 5000,

      items: [

        { image: `https://picsum.photos/seed/1/800/600?grayscale`, text: 'A' },

        { image: `https://picsum.photos/seed/2/800/600?grayscale`, text: 'B' },

        { image: `https://picsum.photos/seed/3/800/600?grayscale`, text: 'C' },

        { image: `https://picsum.photos/seed/4/800/600?grayscale`, text: 'D' },

        { image: `https://picsum.photos/seed/5/800/600?grayscale`, text: 'E' },

        { image: `https://picsum.photos/seed/16/800/600?grayscale`, text: 'F' },

        { image: `https://picsum.photos/seed/17/800/600?grayscale`, text: 'G' },

        { image: `https://picsum.photos/seed/8/800/600?grayscale`, text: 'H' },

        { image: `https://picsum.photos/seed/9/800/600?grayscale`, text: 'I' },

        { image: `https://picsum.photos/seed/10/800/600?grayscale`, text: 'J' },

        { image: `https://picsum.photos/seed/21/800/600?grayscale`, text: 'K' },

        { image: `https://picsum.photos/seed/12/800/600?grayscale`, text: 'L' }

      ],

      bend: 1,

      textColor: "#000000",

      darkTextColor: "#e0e0e0",

      borderRadius: 0.05,

      font: "bold 30px 'Noto Sans TC', sans-serif"

    }

  });

  

  const [loading, setLoading] = useState(false);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const [newItem, setNewItem] = useState({ image: '', text: '', url: '' });

  const [editingIndex, setEditingIndex] = useState(null);

  const [deleteConfirmIndex, setDeleteConfirmIndex] = useState(null);

  const [draggedItemIndex, setDraggedItemIndex] = useState(null);

  const [dropTargetIndex, setDropTargetIndex] = useState(null);



  // 獲取首頁組件設置

  useEffect(() => {

    const fetchHomeComponents = async () => {

      setLoading(true);

      try {

        const response = await adminApi.get('/settings/home-components');

        if (response.data && response.data.circularGallery) {

          setComponents({

            circularGallery: {

              ...components.circularGallery,

              ...response.data.circularGallery

            }

          });

        }

      } catch (error) {

        console.error('獲取圓形畫廊設置失敗:', error);

        toast.error('獲取設置失敗');

      } finally {

        setLoading(false);

      }

    };



    fetchHomeComponents();

  }, []);



  // 處理表單提交

  const handleSubmit = (e) => {

    e.preventDefault();

    setIsConfirmOpen(true);

  };



  // 確認更新

  const handleConfirm = async () => {

    setLoading(true);

    try {

      const response = await adminApi.put('/settings/home-components', components);

      if (response.data) {

        setComponents({

          circularGallery: {

            ...components.circularGallery,

            ...response.data.circularGallery

          }

        });

        toast.success('設置已更新！');

      }

    } catch (error) {

      console.error('更新圓形畫廊設置失敗:', error);

      toast.error('更新設置失敗');

    } finally {

      setLoading(false);

      setIsConfirmOpen(false);

    }

  };



  // 處理輸入變更

  const handleChange = (field, value) => {

    setComponents(prev => ({

      ...prev,

      circularGallery: {

        ...prev.circularGallery,

        [field]: value

      }

    }));

  };



  // 自動保存函數

  const autoSave = async (updatedComponents) => {

    setLoading(true);

    try {

      const response = await adminApi.put('/settings/home-components', updatedComponents || components);

      if (response.data) {

        setComponents({

          circularGallery: {

            ...(updatedComponents || components).circularGallery,

            ...response.data.circularGallery

          }

        });

        toast.success('設置已自動保存！');

      }

    } catch (error) {

      console.error('自動保存設置失敗:', error);

      toast.error('自動保存失敗');

    } finally {

      setLoading(false);

    }

  };



  // 添加新項目

  const handleAddItem = () => {

    // 驗證必填字段

    if (!newItem.image.trim() || !newItem.text.trim()) {

      toast.error('圖片URL和文字描述為必填項');

      return;

    }



    // 創建新的組件列表

    let updatedComponents = { ...components };

    

    if (editingIndex !== null) {

      // 更新現有項目

      updatedComponents.circularGallery.items[editingIndex] = {

        ...updatedComponents.circularGallery.items[editingIndex],

        image: newItem.image,

        text: newItem.text,

        url: newItem.url

      };

      toast.success('項目已更新');

      setEditingIndex(null);

    } else {

      // 添加新項目

      updatedComponents.circularGallery.items.push({

        image: newItem.image,

        text: newItem.text,

        url: newItem.url

      });

      toast.success('項目已添加');

    }

    

    // 重置表單

    setNewItem({ image: '', text: '', url: '' });

    

    // 更新狀態

    setComponents(updatedComponents);

    

    // 自動保存

    autoSave(updatedComponents);

  };



  // 編輯項目

  const handleEditItem = (index) => {

    const item = components.circularGallery.items[index];

    setNewItem({ 

      image: item.image, 

      text: item.text,

      url: item.url || ''

    });

    setEditingIndex(index);

  };



  // 刪除項目

  const handleDeleteItem = (index) => {

    const updatedItems = components.circularGallery.items.filter((_, i) => i !== index);

    const updatedComponents = {

      ...components,

      circularGallery: {

        ...components.circularGallery,

        items: updatedItems

      }

    };

    

    setComponents(updatedComponents);

    setDeleteConfirmIndex(null);

    

    // 自動保存

    autoSave(updatedComponents);

  };



  // 確認刪除項目

  const confirmDeleteItem = (index) => {

    setDeleteConfirmIndex(index);

  };



  // 取消刪除

  const cancelDelete = () => {

    setDeleteConfirmIndex(null);

  };



  // 移動項目順序

  const handleMoveItem = (index, direction) => {

    if (

      (direction === 'up' && index === 0) || 

      (direction === 'down' && index === components.circularGallery.items.length - 1)

    ) {

      return;

    }



    const newIndex = direction === 'up' ? index - 1 : index + 1;

    const updatedItems = [...components.circularGallery.items];

    [updatedItems[index], updatedItems[newIndex]] = [updatedItems[newIndex], updatedItems[index]];

    

    const updatedComponents = {

      ...components,

      circularGallery: {

        ...components.circularGallery,

        items: updatedItems

      }

    };

    

    setComponents(updatedComponents);

    

    // 自動保存

    autoSave(updatedComponents);

  };



  // 移動項目到頂部

  const handleMoveToTop = (index) => {

    if (index === 0) return;

    

    const updatedItems = [...components.circularGallery.items];

    const itemToMove = updatedItems[index];

    updatedItems.splice(index, 1);

    updatedItems.unshift(itemToMove);

    

    const updatedComponents = {

      ...components,

      circularGallery: {

        ...components.circularGallery,

        items: updatedItems

      }

    };

    

    setComponents(updatedComponents);

    

    // 自動保存

    autoSave(updatedComponents);

  };



  // 移動項目到底部

  const handleMoveToBottom = (index) => {

    if (index === components.circularGallery.items.length - 1) return;

    

    const updatedItems = [...components.circularGallery.items];

    const itemToMove = updatedItems[index];

    updatedItems.splice(index, 1);

    updatedItems.push(itemToMove);

    

    const updatedComponents = {

      ...components,

      circularGallery: {

        ...components.circularGallery,

        items: updatedItems

      }

    };

    

    setComponents(updatedComponents);

    

    // 自動保存

    autoSave(updatedComponents);

  };



  // 拖拽開始

  const handleDragStart = (index) => {

    setDraggedItemIndex(index);

  };



  // 拖拽結束

  const handleDragEnd = () => {

    setDraggedItemIndex(null);

    setDropTargetIndex(null);

  };



  // 拖拽進入

  const handleDragEnter = (index) => {

    if (draggedItemIndex === null || draggedItemIndex === index) return;

    setDropTargetIndex(index);

  };



  // 放置

  const handleDrop = () => {

    if (draggedItemIndex === null || dropTargetIndex === null || draggedItemIndex === dropTargetIndex) {

      setDraggedItemIndex(null);

      setDropTargetIndex(null);

      return;

    }

    

    const updatedItems = [...components.circularGallery.items];

    const itemToMove = updatedItems[draggedItemIndex];

    updatedItems.splice(draggedItemIndex, 1);

    updatedItems.splice(dropTargetIndex, 0, itemToMove);

    

    const updatedComponents = {

      ...components,

      circularGallery: {

        ...components.circularGallery,

        items: updatedItems

      }

    };

    

    setComponents(updatedComponents);

    

    // 自動保存

    autoSave(updatedComponents);

    

    setDraggedItemIndex(null);

    setDropTargetIndex(null);

  };



  return (

    <AdminLayout>

      <div className="bg-white rounded-lg shadow p-6">

        <h1 className="text-2xl font-bold mb-6">首頁圓形畫廊管理</h1>

        

        {loading ? (

          <div className="flex justify-center items-center h-64">

            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>

          </div>

        ) : (

          <form onSubmit={handleSubmit}>

            {/* 圓形畫廊設置 */}

            <div className="mb-8 p-6 border border-gray-200 rounded-lg">

              <div className="flex justify-between items-center mb-4">

                <h2 className="text-xl font-semibold">圓形畫廊設置</h2>

                <label className="relative inline-flex items-center cursor-pointer">

                  <input 

                    type="checkbox" 

                    className="sr-only peer" 

                    checked={components.circularGallery.enabled}

                    onChange={(e) => handleChange('enabled', e.target.checked)}

                  />

                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                  <span className="ml-3 text-sm font-medium text-gray-900">啟用</span>

                </label>

              </div>

              

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">

                <div>

                  <label className="block text-sm font-medium mb-2">自動旋轉</label>

                  <label className="relative inline-flex items-center cursor-pointer">

                    <input 

                      type="checkbox" 

                      className="sr-only peer" 

                      checked={components.circularGallery.autoRotate}

                      onChange={(e) => handleChange('autoRotate', e.target.checked)}

                      disabled={!components.circularGallery.enabled}

                    />

                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                  </label>

                </div>

                <div>

                  <label className="block text-sm font-medium mb-2">旋轉速度 (毫秒)</label>

                  <input

                    type="number"

                    min="1000"

                    step="500"

                    value={components.circularGallery.rotationSpeed}

                    onChange={(e) => handleChange('rotationSpeed', parseInt(e.target.value))}

                    className="w-full p-2 border rounded"

                    disabled={!components.circularGallery.enabled || !components.circularGallery.autoRotate}

                  />

                </div>

                <div>

                  <label className="block text-sm font-medium mb-2">彎曲程度</label>

                  <input

                    type="range"

                    min="-2"

                    max="2"

                    step="0.1"

                    value={components.circularGallery.bend}

                    onChange={(e) => handleChange('bend', parseFloat(e.target.value))}

                    className="w-full"

                    disabled={!components.circularGallery.enabled}

                  />

                  <div className="text-sm text-gray-500 mt-1">

                    當前值: {components.circularGallery.bend}

                  </div>

                </div>

                <div>

                  <label className="block text-sm font-medium mb-2">圓角半徑</label>

                  <input

                    type="range"

                    min="0"

                    max="0.2"

                    step="0.01"

                    value={components.circularGallery.borderRadius}

                    onChange={(e) => handleChange('borderRadius', parseFloat(e.target.value))}

                    className="w-full"

                    disabled={!components.circularGallery.enabled}

                  />

                  <div className="text-sm text-gray-500 mt-1">

                    當前值: {components.circularGallery.borderRadius}

                  </div>

                </div>

                <div>

                  <label className="block text-sm font-medium mb-2">文字顏色 (淺色主題)</label>

                  <input

                    type="color"

                    value={components.circularGallery.textColor}

                    onChange={(e) => handleChange('textColor', e.target.value)}

                    className="w-full h-10"

                    disabled={!components.circularGallery.enabled}

                  />

                </div>

                <div>

                  <label className="block text-sm font-medium mb-2">文字顏色 (深色主題)</label>

                  <input

                    type="color"

                    value={components.circularGallery.darkTextColor || "#e0e0e0"}

                    onChange={(e) => handleChange('darkTextColor', e.target.value)}

                    className="w-full h-10"

                    disabled={!components.circularGallery.enabled}

                  />

                </div>

                <div>

                  <label className="block text-sm font-medium mb-2">字體大小</label>

                  <input

                    type="range"

                    min="16"

                    max="48"

                    step="1"

                    value={parseInt(components.circularGallery.font?.match(/\d+/)?.[0] || "30", 10)}

                    onChange={(e) => {

                      const fontSize = e.target.value;

                      const currentFont = components.circularGallery.font || "bold 30px 'Noto Sans TC', sans-serif";

                      const newFont = currentFont.replace(/\d+px/, `${fontSize}px`);

                      handleChange('font', newFont);

                    }}

                    className="w-full"

                    disabled={!components.circularGallery.enabled}

                  />

                  <div className="text-sm text-gray-500 mt-1">

                    當前值: {parseInt(components.circularGallery.font?.match(/\d+/)?.[0] || "30", 10)}px

                  </div>

                </div>

                <div>

                  <label className="block text-sm font-medium mb-2">字體選擇</label>

                  <select

                    value={components.circularGallery.font?.includes("'Noto Sans TC'") ? "noto" : 

                           components.circularGallery.font?.includes("'DM Sans'") ? "dm" : 

                           components.circularGallery.font?.includes("monospace") ? "mono" : "sans"}

                    onChange={(e) => {

                      const fontFamily = e.target.value === "noto" ? "'Noto Sans TC', sans-serif" : 

                                        e.target.value === "dm" ? "'DM Sans', sans-serif" : 

                                        e.target.value === "mono" ? "monospace" : "sans-serif";

                      const fontSize = components.circularGallery.font?.match(/\d+/)?.[0] || "30";

                      const newFont = `bold ${fontSize}px ${fontFamily}`;

                      handleChange('font', newFont);

                    }}

                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-300"

                    disabled={!components.circularGallery.enabled}

                  >

                    <option value="noto">Noto Sans TC</option>

                    <option value="dm">DM Sans</option>

                    <option value="mono">Monospace</option>

                    <option value="sans">Sans-serif</option>

                  </select>

                </div>

              </div>



              {/* 畫廊項目管理 */}

              <div className="mt-6">

                <h3 className="text-lg font-medium mb-4">畫廊項目管理</h3>

                

                {/* 添加/編輯項目表單 */}

                <div className="mb-6 p-4 bg-gray-50 rounded-lg">

                  <h4 className="text-md font-medium mb-3">{editingIndex !== null ? '編輯項目' : '添加新項目'}</h4>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">

                    <div>

                      <label className="block text-sm font-medium mb-2">圖片URL <span className="text-red-500">*</span></label>

                      <input

                        type="text"

                        value={newItem.image}

                        onChange={(e) => setNewItem({...newItem, image: e.target.value})}

                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-300"

                        placeholder="輸入圖片URL"

                        disabled={!components.circularGallery.enabled}

                      />

                      {newItem.image && (

                        <div className="mt-2">

                          <img 

                            src={newItem.image} 

                            alt="預覽" 

                            className="h-16 w-auto object-cover rounded border"

                            onError={(e) => {

                              e.target.onerror = null;

                              e.target.src = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IiNFNUU3RUIiLz48cGF0aCBkPSJNOCAxMEEyIDIgMCAxIDAgOCA2QTIgMiAwIDAgMCA4IDEwWiIgZmlsbD0iIzk0QTNCOCIvPjxwYXRoIGQ9Ik0xMiAxMi4wMDFIMTBWMTAuMDAxSDZWMTIuMDAxSDRWNi4wMDFDNCA0Ljg5NiA0Ljg5NiA0LjAwMSA2IDQuMDAxSDEwQzExLjEwNCA0LjAwMSAxMiA0Ljg5NiAxMiA2LjAwMVYxMi4wMDFaIiBmaWxsPSIjOTRBM0I4Ii8+PC9zdmc+';

                              e.target.classList.add('error-image');

                            }}

                          />

                        </div>

                      )}

                    </div>

                    <div>

                      <label className="block text-sm font-medium mb-2">文字描述 <span className="text-red-500">*</span></label>

                      <input

                        type="text"

                        value={newItem.text}

                        onChange={(e) => setNewItem({...newItem, text: e.target.value})}

                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-300"

                        placeholder="輸入文字描述"

                        disabled={!components.circularGallery.enabled}

                      />

                    </div>

                    <div className="md:col-span-2">

                      <label className="block text-sm font-medium mb-2">超鏈接URL</label>

                      <input

                        type="text"

                        value={newItem.url || ''}

                        onChange={(e) => setNewItem({...newItem, url: e.target.value})}

                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-300 focus:border-blue-300"

                        placeholder="輸入超鏈接URL（可選）"

                        disabled={!components.circularGallery.enabled}

                      />

                    </div>

                  </div>

                  <div className="flex space-x-2">

                    <button

                      type="button"

                      onClick={handleAddItem}

                      className={`px-4 py-2 rounded text-white transition-colors duration-200 ${

                        editingIndex !== null 

                          ? 'bg-blue-500 hover:bg-blue-600' 

                          : 'bg-green-500 hover:bg-green-600'

                      }`}

                      disabled={!components.circularGallery.enabled}

                    >

                      {editingIndex !== null ? '更新項目' : '添加項目'}

                    </button>

                    {editingIndex !== null && (

                      <button

                        type="button"

                        onClick={() => {

                          setNewItem({ image: '', text: '', url: '' });

                          setEditingIndex(null);

                        }}

                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors duration-200"

                      >

                        取消編輯

                      </button>

                    )}

                  </div>

                </div>

                

                {/* 項目列表 */}

                <div className="border rounded-lg overflow-hidden">

                  <table className="min-w-full divide-y divide-gray-200 table-fixed">

                    <thead className="bg-gray-50">

                      <tr>

                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/5">圖片</th>

                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-2/5">文字</th>

                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">操作</th>

                      </tr>

                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">

                      {components.circularGallery.items.map((item, index) => (

                        <tr 

                          key={index}

                          draggable={components.circularGallery.enabled}

                          onDragStart={() => handleDragStart(index)}

                          onDragEnd={handleDragEnd}

                          onDragEnter={() => handleDragEnter(index)}

                          onDragOver={(e) => e.preventDefault()}

                          onDrop={handleDrop}

                          className={`${draggedItemIndex === index ? 'opacity-50 bg-blue-50' : ''} 
                                     ${dropTargetIndex === index ? 'border-t-2 border-blue-500' : ''} 
                                     hover:bg-gray-50 transition-colors duration-150 cursor-move`}
                        >

                          <td className="px-6 py-4 whitespace-nowrap">

                            <div className="flex items-center">

                              <div className="flex-shrink-0 h-10 w-10">

                                <img className="h-10 w-10 rounded-full object-cover" src={item.image} alt={item.text} />

                              </div>

                              <div className="ml-4 truncate max-w-[200px] text-sm">

                                {item.image}

                              </div>

                            </div>

                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">

                            <div className="flex flex-col">

                              <span className="text-sm">{item.text}</span>

                              {item.url && (

                                <a 

                                  href={item.url} 

                                  target="_blank" 

                                  rel="noopener noreferrer" 

                                  className="text-blue-500 hover:text-blue-700 text-xs truncate max-w-[200px]"

                                >

                                  {item.url}

                                </a>

                              )}

                            </div>

                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">

                            <div className="flex justify-end items-center space-x-2">

                              <div className="flex flex-col">

                                <button

                                  type="button"

                                  onClick={() => handleMoveToTop(index)}

                                  className="text-indigo-600 hover:text-indigo-900 mb-1"

                                  disabled={index === 0 || !components.circularGallery.enabled}

                                  title="移到頂部"

                                >

                                  ⤒

                                </button>

                                <button

                                  type="button"

                                  onClick={() => handleMoveItem(index, 'up')}

                                  className="text-indigo-600 hover:text-indigo-900"

                                  disabled={index === 0 || !components.circularGallery.enabled}

                                  title="上移一位"

                                >

                                  ↑

                                </button>

                              </div>

                              <div className="flex flex-col">

                                <button

                                  type="button"

                                  onClick={() => handleMoveItem(index, 'down')}

                                  className="text-indigo-600 hover:text-indigo-900"

                                  disabled={index === components.circularGallery.items.length - 1 || !components.circularGallery.enabled}

                                  title="下移一位"

                                >

                                  ↓

                                </button>

                                <button

                                  type="button"

                                  onClick={() => handleMoveToBottom(index)}

                                  className="text-indigo-600 hover:text-indigo-900 mt-1"

                                  disabled={index === components.circularGallery.items.length - 1 || !components.circularGallery.enabled}

                                  title="移到底部"

                                >

                                  ⤓

                                </button>

                              </div>

                              <button

                                type="button"

                                onClick={() => handleEditItem(index)}

                                className="text-blue-600 hover:text-blue-900 ml-2"

                                disabled={!components.circularGallery.enabled}

                              >

                                編輯

                              </button>

                              <button

                                type="button"

                                onClick={() => confirmDeleteItem(index)}

                                className="text-red-600 hover:text-red-900"

                                disabled={!components.circularGallery.enabled}

                              >

                                刪除

                              </button>

                            </div>

                          </td>

                        </tr>

                      ))}

                    </tbody>

                  </table>

                </div>

                <div className="mt-2 text-sm text-gray-500">

                  <p>提示：您可以直接拖放項目來調整順序</p>

                </div>

              </div>

            </div>

            

            <div className="flex justify-end">

              <button

                type="submit"

                className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition-colors duration-200"

                disabled={loading}

              >

                {loading ? '保存中...' : '保存設置'}

              </button>

            </div>

          </form>

        )}

        

        {/* 確認對話框 */}

        {isConfirmOpen && (

          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">

            <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">

              <h3 className="text-xl font-bold mb-4">確認更新</h3>

              <p className="text-gray-600 mb-6">

                確定要更新圓形畫廊設置嗎？

              </p>

              <div className="flex justify-end space-x-4">

                <button

                  onClick={() => setIsConfirmOpen(false)}

                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors duration-200"

                >

                  取消

                </button>

                <button

                  onClick={handleConfirm}

                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200"

                >

                  確定更新

                </button>

              </div>

            </div>

          </div>

        )}

        

        {/* 刪除確認對話框 */}

        {deleteConfirmIndex !== null && (

          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">

            <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">

              <h3 className="text-xl font-bold mb-4">確認刪除</h3>

              <p className="text-gray-600 mb-6">

                確定要刪除「{components.circularGallery.items[deleteConfirmIndex].text}」項目嗎？

              </p>

              <div className="flex justify-end space-x-4">

                <button

                  onClick={cancelDelete}

                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors duration-200"

                >

                  取消

                </button>

                <button

                  onClick={() => handleDeleteItem(deleteConfirmIndex)}

                  className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200"

                >

                  確定刪除

                </button>

              </div>

            </div>

          </div>

        )}

      </div>

    </AdminLayout>

  );

}



export default HomeComponentsManager;
