import React, { useState, useEffect, useCallback } from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import { toast } from 'react-hot-toast';
import { adminApi } from '../../utils/api';
import './UserManagement.css'; // 添加 CSS 文件引用

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isLoginRecordModalOpen, setIsLoginRecordModalOpen] = useState(false);
  const [selectedUserLoginRecords, setSelectedUserLoginRecords] = useState(null);
  const [deletingUserId, setDeletingUserId] = useState(null);
  const [editingUser, setEditingUser] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [allowRegister, setAllowRegister] = useState(true);
  const [formData, setFormData] = useState({
    Useraccount: '',
    password: '',
    role: '會員',
    Username: '',
    identityGroup: 'Lv.0弱小哥布林',
    adminNote: '',
    isRestricted: false
  });
  const [searchParams, setSearchParams] = useState({
    username: '',
    identityGroup: '',
    ip: '',
    location: '',
    timeRange: ''
  });

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await adminApi.get('/users');
      
      if (!response.data) {
        throw new Error('未收到數據');
      }
      
      const processedData = Array.isArray(response.data) ? response.data.map(user => ({
        ...user,
        identityGroup: user.identityGroup || 'Lv.0弱小哥布林'
      })) : [];
      
      setUsers(processedData);
    } catch (error) {
      console.error('獲取用戶列表失敗:', error);
      const errorMessage = error.response?.data?.message || '獲取用戶列表失敗';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  // 獲取註冊設置
  const fetchRegisterSettings = useCallback(async () => {
    try {
      const response = await adminApi.get('/settings/register');
      setAllowRegister(response.data.allowRegister);
    } catch (error) {
      console.error('獲取註冊設置失敗:', error);
      toast.error('獲取註冊設置失敗');
    }
  }, []);

  // 更新註冊設置
  const updateRegisterSetting = async (value) => {
    try {
      const loadingToast = toast.loading('正在更新設置...');
      const response = await adminApi.put('/settings/register', { allowRegister: value });
      setAllowRegister(response.data.allowRegister);
      toast.success('設置已更新！', { id: loadingToast });
    } catch (error) {
      console.error('更新設置失敗:', error);
      toast.error(error.message || '更新設置失敗');
    }
  };

  const handleDelete = useCallback(async () => {
    if (!deletingUserId) return;

    try {
      await adminApi.delete(`/users/${deletingUserId}`);
      toast.success('用戶已刪除');
      fetchUsers(); // 重新獲取用戶列表
      setIsConfirmOpen(false);
      setDeletingUserId(null);
    } catch (error) {
      console.error('刪除用戶失敗:', error);
      const errorMessage = error.response?.data?.message || '刪除用戶失敗';
      setError(errorMessage);
      toast.error(errorMessage);
    }
  }, [deletingUserId, fetchUsers]);

  // 添加鍵盤事件監聽
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (isConfirmOpen && e.key === 'Enter') {
        handleDelete();
      }
    };

    if (isConfirmOpen) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isConfirmOpen, handleDelete]);

  useEffect(() => {
    fetchUsers();
    fetchRegisterSettings();
  }, [fetchUsers, fetchRegisterSettings]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const url = editingUser ? `/users/${editingUser._id}` : '/users';
      const method = editingUser ? 'put' : 'post';
      
      const response = await adminApi[method](url, formData);
      
      if (!response.data) {
        throw new Error('操作失敗：未收到響應數據');
      }

      fetchUsers(); // 重新獲取用戶列表
      setIsModalOpen(false);
      setEditingUser(null);
      setFormData({
        Useraccount: '',
        password: '',
        role: '會員',
        Username: '',
        identityGroup: 'Lv.0弱小哥布林',
        adminNote: '',
        isRestricted: false
      });
      toast.success(editingUser ? '用戶更新成功' : '用戶創建成功');
    } catch (error) {
      console.error('保存用戶失敗:', error);
      const errorMessage = error.response?.data?.message || '操作失敗';
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleToggleRestriction = async (userId, currentStatus) => {
    try {
      const loadingToast = toast.loading(currentStatus ? '正在解除限制...' : '正在限制用戶...');
      
      if (!userId) {
        throw new Error('無效的用戶ID');
      }
      
      const response = await adminApi.put(`/users/${userId}/toggle-restriction`);
      
      if (!response.data) {
        throw new Error('操作失敗：未收到響應數據');
      }
      
      toast.success(response.data.message, { id: loadingToast });
      fetchUsers(); // 重新獲取用戶列表
    } catch (error) {
      console.error('切換用戶限制狀態失敗:', error);
      const errorMessage = error.response?.data?.message || error.message || '操作失敗';
      toast.error(errorMessage);
    }
  };

  // 獲取用戶登入記錄
  const fetchUserLoginRecords = async (userId) => {
    try {
      console.log('正在獲取用戶登入記錄:', userId);
      const response = await adminApi.get(`/users/${userId}/login-records`);
      if (!response.data) {
        throw new Error('未收到數據');
      }
      console.log('獲取到的登入記錄:', response.data);
      
      // 如果沒有登入記錄，設置一個空的記錄
      const loginData = {
        lastLoginIp: response.data.lastLoginIp || '無記錄',
        lastLoginTime: response.data.lastLoginTime || null,
        loginRecords: response.data.loginRecords || []
      };
      
      setSelectedUserLoginRecords(loginData);
      setIsLoginRecordModalOpen(true);
    } catch (error) {
      console.error('獲取登入記錄失敗:', error);
      const errorMessage = error.response?.data?.message || '獲取登入記錄失敗';
      toast.error(errorMessage);
    }
  };

  // 過濾用戶列表
  const filteredUsers = users.filter(user => {
    const usernameMatch = user.Username?.toLowerCase().includes(searchParams.username.toLowerCase()) ||
                         user.Useraccount?.toLowerCase().includes(searchParams.username.toLowerCase());
    const identityGroupMatch = user.identityGroup?.toLowerCase().includes(searchParams.identityGroup.toLowerCase());
    const ipMatch = !searchParams.ip || user.lastLoginIp?.includes(searchParams.ip);
    
    return (!searchParams.username || usernameMatch) && 
           (!searchParams.identityGroup || identityGroupMatch) &&
           (!searchParams.ip || ipMatch);
  });

  return (
    <AdminLayout>
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-2xl font-bold">用戶管理</h1>
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <span className="mr-2">允許註冊:</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={allowRegister}
                    onChange={() => updateRegisterSetting(!allowRegister)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <button 
                onClick={() => {
                  setIsModalOpen(true);
                  setEditingUser(null);
                  setFormData({
                    Useraccount: '',
                    password: '',
                    role: '會員',
                    Username: '',
                    identityGroup: 'Lv.0弱小哥布林',
                    adminNote: '',
                    isRestricted: false
                  });
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                新增用戶
              </button>
            </div>
          </div>

          {/* 搜尋欄位 */}
          <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="relative">
              <input
                type="text"
                placeholder="搜尋用戶名..."
                value={searchParams.username}
                onChange={(e) => setSearchParams(prev => ({ ...prev, username: e.target.value }))}
                className="w-full p-2 pl-8 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <svg className="w-4 h-4 absolute left-2 top-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <div className="relative">
              <input
                type="text"
                placeholder="搜尋身分組..."
                value={searchParams.identityGroup}
                onChange={(e) => setSearchParams(prev => ({ ...prev, identityGroup: e.target.value }))}
                className="w-full p-2 pl-8 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <svg className="w-4 h-4 absolute left-2 top-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
            </div>
            <div className="relative">
              <input
                type="text"
                placeholder="搜尋 IP 地址..."
                value={searchParams.ip}
                onChange={(e) => setSearchParams(prev => ({ ...prev, ip: e.target.value }))}
                className="w-full p-2 pl-8 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <svg className="w-4 h-4 absolute left-2 top-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg>
            </div>
          </div>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
              {error}
            </div>
          )}

          {loading ? (
            <div className="text-center py-4">載入中...</div>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full table-fixed">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-32">用戶帳號</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-28">用戶名</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-20">角色</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-28">身分組</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-28">最後登入 IP</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-28">最後登入時間</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-32">管理員備註</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase w-28">操作</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredUsers.map((user) => (
                    <tr key={user._id} className={user.isRestricted ? 'bg-red-50' : ''}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">{user.Useraccount}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">{user.Username || '-'}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          user.role === 'admin' 
                            ? 'bg-purple-100 text-purple-800' 
                            : 'bg-green-100 text-green-800'
                        }`}>
                          {user.role}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full text-xs border border-yellow-300">
                          {user.identityGroup === '惡意評論的哥布林' ? (
                            <>
                              <span className="inline-block mr-1">⚠️</span>
                              {user.identityGroup}
                            </>
                          ) : (
                            user.identityGroup
                          )}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {user.lastLoginIp || '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {user.lastLoginTime ? new Date(user.lastLoginTime).toLocaleString() : '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm truncate max-w-[150px]">
                        {user.adminNote || '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex flex-wrap gap-2">
                          <button 
                            onClick={() => {
                              setEditingUser(user);
                              setFormData({
                                Useraccount: user.Useraccount,
                                password: '',
                                role: user.role,
                                Username: user.Username || '',
                                identityGroup: user.identityGroup,
                                adminNote: user.adminNote || '',
                                isRestricted: user.isRestricted
                              });
                              setIsModalOpen(true);
                            }}
                            className="text-blue-600 hover:text-blue-900 text-sm"
                          >
                            編輯
                          </button>
                          <button 
                            onClick={() => fetchUserLoginRecords(user._id)}
                            className="text-gray-600 hover:text-gray-900 text-sm"
                          >
                            登入記錄
                          </button>
                          <button 
                            onClick={() => handleToggleRestriction(user._id, user.isRestricted)}
                            className={user.isRestricted ? "text-green-600 hover:text-green-900 text-sm" : "text-orange-600 hover:text-orange-900 text-sm"}
                          >
                            {user.isRestricted ? '解除限制' : '限制'}
                          </button>
                          <button 
                            onClick={() => {
                              setDeletingUserId(user._id);
                              setIsConfirmOpen(true);
                            }}
                            className="text-red-600 hover:text-red-900 text-sm"
                          >
                            刪除
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto py-6">
              <div className="bg-white rounded-lg w-full max-w-md mx-4 my-8">
                <div className="p-6 max-h-[90vh] overflow-y-auto modal-scrollable">
                  <h2 className="text-2xl font-bold mb-4 sticky top-0 bg-white py-2 modal-sticky-header">
                    {editingUser ? '編輯用戶' : '新增用戶'}
                  </h2>
                  
                  {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                      {error}
                    </div>
                  )}

                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        用戶帳號
                      </label>
                      <input
                        type="text"
                        value={formData.Useraccount}
                        onChange={(e) => setFormData({...formData, Useraccount: e.target.value})}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                        disabled={editingUser}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        密碼 {editingUser && '(留空表示不修改)'}
                      </label>
                      <input
                        type="password"
                        value={formData.password}
                        onChange={(e) => setFormData({...formData, password: e.target.value})}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required={!editingUser}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        用戶名
                      </label>
                      <input
                        type="text"
                        value={formData.Username}
                        onChange={(e) => setFormData({...formData, Username: e.target.value})}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        角色
                      </label>
                      <select
                        value={formData.role}
                        onChange={(e) => setFormData({...formData, role: e.target.value})}
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option value="會員">會員</option>
                        <option value="admin">管理員</option>
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        身分組
                      </label>
                      <select
                        value={formData.identityGroup}
                        onChange={(e) => setFormData({...formData, identityGroup: e.target.value})}
                        className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option value="Lv.0弱小哥布林">Lv.0弱小哥布林</option>
                        <option value="Lv.1初階哥布林">Lv.1初階哥布林</option>
                        <option value="Lv.2中階哥布林">Lv.2中階哥布林</option>
                        <option value="Lv.3高階哥布林">Lv.3高階哥布林</option>
                        <option value="Lv.4超級哥布林">Lv.4超級哥布林</option>
                        <option value="Lv.5哥布林戰士">Lv.5哥布林戰士</option>
                        <option value="Lv.6哥布林狂戰士">Lv.6哥布林狂戰士</option>
                        <option value="Lv.7哥布林勇士">Lv.7哥布林勇士</option>
                        <option value="Lv.8哥布林長老">Lv.8哥布林長老</option>
                        <option value="Lv.9哥布林祭司">Lv.9哥布林祭司</option>
                        <option value="Lv.10哥布林指揮官">Lv.10哥布林指揮官</option>
                        <option value="Lv.11哥布林元帥">Lv.11哥布林元帥</option>
                        <option value="Lv.12哥布林副首領">Lv.12哥布林副首領</option>
                        <option value="Lv.13哥布林聖騎士">Lv.13哥布林聖騎士</option>
                        <option value="Lv.14哥布林首領">Lv.14哥布林首領</option>
                        <option value="Lv.15哥布林大王">Lv.15哥布林大王</option>
                        <option value="Lv.99哥布林商人">Lv.99哥布林商人</option>
                        <option value="惡意評論的哥布林">
                          <span className="text-red-600">⚠️</span> 惡意評論的哥布林
                        </option>
                        <option value="Lv.???始祖哥布林(管理員)">Lv.???始祖哥布林(管理員)</option>
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        管理員備註
                      </label>
                      <textarea
                        value={formData.adminNote}
                        onChange={(e) => setFormData({...formData, adminNote: e.target.value})}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        rows="3"
                      />
                    </div>

                    {editingUser && (
                      <div className="mb-4">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={formData.isRestricted}
                            onChange={(e) => setFormData({...formData, isRestricted: e.target.checked})}
                            className="mr-2"
                          />
                          <span className="text-gray-700">限制用戶</span>
                        </label>
                      </div>
                    )}

                    <div className="flex justify-end sticky bottom-0 bg-white pt-4 modal-sticky-footer">
                      <button
                        type="button"
                        onClick={() => {
                          setIsModalOpen(false);
                          setEditingUser(null);
                          setError('');
                        }}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 mr-2"
                      >
                        取消
                      </button>
                      <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                      >
                        保存
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {/* 確認對話框 */}
          {isConfirmOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 confirm-dialog-backdrop overflow-y-auto py-6">
              <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 my-8 confirm-dialog">
                <h3 className="text-xl font-bold mb-4">確認刪除</h3>
                <p className="text-gray-600 mb-6">
                  確定要刪除此用戶嗎？此操作無法撤銷。
                  <br />
                  <span className="text-sm text-gray-500">（按下 Enter 鍵確認刪除）</span>
                </p>
                <div className="flex justify-end space-x-4 confirm-dialog-buttons">
                  <button
                    onClick={() => {
                      setIsConfirmOpen(false);
                      setDeletingUserId(null);
                    }}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors duration-200"
                  >
                    取消
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200 confirm-btn"
                  >
                    確定刪除
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* 登入記錄彈窗 */}
          {isLoginRecordModalOpen && selectedUserLoginRecords && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto py-6">
              <div className="bg-white rounded-lg w-full max-w-4xl mx-4 my-8">
                <div className="p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">登入記錄</h2>
                    <button
                      onClick={() => {
                        setIsLoginRecordModalOpen(false);
                        setSelectedUserLoginRecords(null);
                      }}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  
                  <div className="mb-4">
                    <p className="text-gray-600">
                      最後登入時間：{selectedUserLoginRecords.lastLoginTime ? new Date(selectedUserLoginRecords.lastLoginTime).toLocaleString() : '無記錄'}
                    </p>
                    <p className="text-gray-600">
                      最後登入 IP：{selectedUserLoginRecords.lastLoginIp || '無記錄'}
                      {selectedUserLoginRecords.lastLoginLocation && (
                        <span className="ml-2 text-blue-600">
                          ({selectedUserLoginRecords.lastLoginLocation.country} {selectedUserLoginRecords.lastLoginLocation.region} {selectedUserLoginRecords.lastLoginLocation.city})
                        </span>
                      )}
                    </p>
                  </div>

                  {/* 搜尋和篩選區域 */}
                  <div className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="搜尋 IP 地址..."
                        value={searchParams.ip || ''}
                        onChange={(e) => setSearchParams(prev => ({ ...prev, ip: e.target.value }))}
                        className="w-full p-2 pl-8 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <svg className="w-4 h-4 absolute left-2 top-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                      </svg>
                    </div>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="搜尋地區..."
                        value={searchParams.location || ''}
                        onChange={(e) => setSearchParams(prev => ({ ...prev, location: e.target.value }))}
                        className="w-full p-2 pl-8 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <svg className="w-4 h-4 absolute left-2 top-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </div>
                    <div className="relative">
                      <select
                        value={searchParams.timeRange || ''}
                        onChange={(e) => setSearchParams(prev => ({ ...prev, timeRange: e.target.value }))}
                        className="w-full p-2 pl-8 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="">所有時間</option>
                        <option value="24h">最近24小時</option>
                        <option value="7d">最近7天</option>
                        <option value="30d">最近30天</option>
                      </select>
                      <svg className="w-4 h-4 absolute left-2 top-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                  </div>

                  <div className="overflow-y-auto max-h-96">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">時間</th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">IP 地址</th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">地理位置</th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">瀏覽器資訊</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {selectedUserLoginRecords.loginRecords
                          .filter(record => {
                            const matchIP = !searchParams.ip || record.ip.includes(searchParams.ip);
                            const matchLocation = !searchParams.location || 
                              (record.location && (
                                record.location.country?.toLowerCase().includes(searchParams.location.toLowerCase()) ||
                                record.location.region?.toLowerCase().includes(searchParams.location.toLowerCase()) ||
                                record.location.city?.toLowerCase().includes(searchParams.location.toLowerCase())
                              ));
                            const matchTime = !searchParams.timeRange || (() => {
                              const recordDate = new Date(record.timestamp);
                              const now = new Date();
                              const diff = now - recordDate;
                              switch(searchParams.timeRange) {
                                case '24h': return diff <= 24 * 60 * 60 * 1000;
                                case '7d': return diff <= 7 * 24 * 60 * 60 * 1000;
                                case '30d': return diff <= 30 * 24 * 60 * 60 * 1000;
                                default: return true;
                              }
                            })();
                            return matchIP && matchLocation && matchTime;
                          })
                          .map((record, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {new Date(record.timestamp).toLocaleString()}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {record.ip}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {record.location ? (
                                  `${record.location.country} ${record.location.region} ${record.location.city}`
                                ) : '未知'}
                              </td>
                              <td className="px-6 py-4 text-sm text-gray-500 break-all">
                                {record.userAgent}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AdminLayout>
  );
}

export default UserManagement; 