import axios from 'axios';

// 定義 API 域名，方便日後修改
export const API_DOMAIN = ''; // 使用 HTTPS 協議

const baseConfig = {
  baseURL: `${API_DOMAIN}/api`,
  headers: {
    'Content-Type': 'application/json'
  }
};

const createErrorHandler = (type) => (error) => {
  console.error(`${type} API錯誤:`, error.response || error);
  
  if (error.response?.status === 401) {
    localStorage.removeItem(`${type}Token`);
    if (type === 'admin') {
      window.location.href = '/admin/login';
    }
  }
  
  const errorMessage = error.response?.data?.message || error.message || '發生錯誤，請稍後再試';
  return Promise.reject({ message: errorMessage });
};

const createApi = (type) => {
  const instance = axios.create(baseConfig);
  
  instance.interceptors.request.use(
    config => {
      const token = localStorage.getItem(`${type}Token`);
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      if (config.method === 'post' && config.data) {
        config.data = JSON.stringify(config.data);
      }
      return config;
    },
    error => Promise.reject(error)
  );
  
  instance.interceptors.response.use(
    response => response,
    createErrorHandler(type)
  );
  
  return instance;
};

const api = createApi('');
const adminApi = createApi('admin');
const memberApi = createApi('member');

export { api, adminApi, memberApi }; 
