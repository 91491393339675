import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Projects from './pages/Projects';
import UserLogin from './pages/UserLogin';
import Contact from './pages/Contact';
import Login from './pages/Admin/Login';
import Dashboard from './pages/Admin/Dashboard';
import ProjectManagement from './pages/Admin/ProjectManagement';
import UserManagement from './pages/Admin/UserManagement';
import CommentManagement from './pages/Admin/CommentManagement';
import HomeComponentsManager from './pages/Admin/HomeComponentsManager';
import ProjectDetail from './pages/ProjectDetail';
import Profile from './pages/Profile';
import ClickSpark from './components/ClickSpark';
import AgeVerificationPopup from './components/AgeVerificationPopup';
import PageTransition from './components/PageTransition';
import ThemeProvider from './components/ThemeProvider';

// 包裝路由的組件，提供頁面過渡效果
const AnimatedRoutes = () => {
  const location = useLocation();
  
  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={
          <PageTransition>
            <Home />
          </PageTransition>
        } />
        <Route path="/projects" element={
          <PageTransition>
            <Projects />
          </PageTransition>
        } />
        <Route path="/projects/:id" element={
          <PageTransition>
            <ProjectDetail />
          </PageTransition>
        } />
        <Route path="/login" element={
          <PageTransition>
            <UserLogin />
          </PageTransition>
        } />
        <Route path="/profile" element={
          <PageTransition>
            <Profile />
          </PageTransition>
        } />
        <Route path="/contact" element={
          <PageTransition>
            <Contact />
          </PageTransition>
        } />
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className="min-h-screen bg-zinc-100 dark:bg-zinc-900 pb-20 transition-colors duration-500">
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 3000,
              style: {
                background: '#333',
                color: '#fff',
                padding: '16px',
                borderRadius: '8px',
                transition: 'all 0.3s ease',
              },
              success: {
                style: {
                  background: 'rgba(0, 128, 0, 0.9)',
                  transition: 'all 0.3s ease',
                },
                iconTheme: {
                  primary: 'white',
                  secondary: 'green',
                },
              },
              error: {
                style: {
                  background: 'rgba(220, 38, 38, 0.9)',
                  transition: 'all 0.3s ease',
                },
                iconTheme: {
                  primary: 'white',
                  secondary: 'red',
                },
              },
            }}
          />
          
          <ClickSpark />
          
          <Routes>
            {/* 管理員路由 */}
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/home-components" element={<HomeComponentsManager />} />
            <Route path="/admin/projects" element={<ProjectManagement />} />
            <Route path="/admin/users" element={<UserManagement />} />
            <Route path="/admin/comments" element={<CommentManagement />} />
            
            {/* 公開路由 */}
            <Route
              path="/*"
              element={
                <>
                  <Navbar />
                  {/* 年齡驗證彈窗只在前台顯示 */}
                  <AgeVerificationPopup />
                  <motion.main 
                    className="container mx-auto px-4 py-8 transition-all duration-500"
                    initial={{ opacity: 0.8 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <AnimatedRoutes />
                  </motion.main>
                </>
              }
            />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
