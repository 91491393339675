// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes ClickSpark_sparkFlyOut__hYjx3 {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) translate(0px, 0px) scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) translate(var(--translate-x, 50px), var(--translate-y, 50px)) scale(1);
  }
}

.ClickSpark_sparkContainer__-9SGh {
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 9999;
}

.ClickSpark_sparkItem__Qy\\+jh {
  position: absolute;
  border-radius: 9999px;
  pointer-events: none;
  opacity: 0;
  animation: ClickSpark_sparkFlyOut__hYjx3 0.6s ease-out forwards;
} `, "",{"version":3,"sources":["webpack://./src/components/ClickSpark.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;IACV,6DAA6D;EAC/D;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;IACV,uGAAuG;EACzG;AACF;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,oBAAoB;EACpB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,oBAAoB;EACpB,UAAU;EACV,+DAA6C;AAC/C","sourcesContent":["@keyframes sparkFlyOut {\r\n  0% {\r\n    opacity: 1;\r\n    transform: translate(-50%, -50%) translate(0px, 0px) scale(0);\r\n  }\r\n  50% {\r\n    opacity: 1;\r\n  }\r\n  100% {\r\n    opacity: 0;\r\n    transform: translate(-50%, -50%) translate(var(--translate-x, 50px), var(--translate-y, 50px)) scale(1);\r\n  }\r\n}\r\n\r\n.sparkContainer {\r\n  position: fixed;\r\n  inset: 0;\r\n  pointer-events: none;\r\n  z-index: 9999;\r\n}\r\n\r\n.sparkItem {\r\n  position: absolute;\r\n  border-radius: 9999px;\r\n  pointer-events: none;\r\n  opacity: 0;\r\n  animation: sparkFlyOut 0.6s ease-out forwards;\r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sparkContainer": `ClickSpark_sparkContainer__-9SGh`,
	"sparkItem": `ClickSpark_sparkItem__Qy+jh`,
	"sparkFlyOut": `ClickSpark_sparkFlyOut__hYjx3`
};
export default ___CSS_LOADER_EXPORT___;
