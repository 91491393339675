import React, { useState, useEffect, useLayoutEffect } from 'react';
import BackgroundEffect from '../components/BackgroundEffect';
import BlurText from "../components/BlurText";
import CircularGallery from '../components/CircularGallery';
import { api } from '../utils/api';

function Home() {
  const [gallerySettings, setGallerySettings] = useState({
    enabled: true,
    autoRotate: true,
    rotationSpeed: 5000,
    items: [
      { image: `https://picsum.photos/seed/1/800/600?grayscale`, text: 'A', url: '' },
      { image: `https://picsum.photos/seed/2/800/600?grayscale`, text: 'B', url: '' },
      { image: `https://picsum.photos/seed/3/800/600?grayscale`, text: 'C', url: '' },
      { image: `https://picsum.photos/seed/4/800/600?grayscale`, text: 'D', url: '' },
      { image: `https://picsum.photos/seed/5/800/600?grayscale`, text: 'E', url: '' },
      { image: `https://picsum.photos/seed/16/800/600?grayscale`, text: 'F', url: '' },
      { image: `https://picsum.photos/seed/17/800/600?grayscale`, text: 'G', url: '' },
      { image: `https://picsum.photos/seed/8/800/600?grayscale`, text: 'H', url: '' },
      { image: `https://picsum.photos/seed/9/800/600?grayscale`, text: 'I', url: '' },
      { image: `https://picsum.photos/seed/10/800/600?grayscale`, text: 'J', url: '' },
      { image: `https://picsum.photos/seed/21/800/600?grayscale`, text: 'K', url: '' },
      { image: `https://picsum.photos/seed/12/800/600?grayscale`, text: 'L', url: '' }
    ],
    bend: 1,
    textColor: "#000000",
    darkTextColor: "#e0e0e0",
    borderRadius: 0.05,
    font: "bold 30px 'Noto Sans TC', sans-serif"
  });
  const [loading, setLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleAnimationComplete = () => {
    console.log('Animation completed!');
  };

  // 检测当前主题模式
  useEffect(() => {
    const detectDarkMode = () => {
      const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      setIsDarkMode(isDark);
    };
    
    detectDarkMode();
    
    // 监听系统主题变化
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const darkModeChangeHandler = (e) => setIsDarkMode(e.matches);
    
    if (darkModeMediaQuery.addEventListener) {
      darkModeMediaQuery.addEventListener('change', darkModeChangeHandler);
    } else if (darkModeMediaQuery.addListener) {
      // 兼容旧版浏览器
      darkModeMediaQuery.addListener(darkModeChangeHandler);
    }
    
    return () => {
      if (darkModeMediaQuery.removeEventListener) {
        darkModeMediaQuery.removeEventListener('change', darkModeChangeHandler);
      } else if (darkModeMediaQuery.removeListener) {
        darkModeMediaQuery.removeListener(darkModeChangeHandler);
      }
    };
  }, []);

  // 從後端獲取圓形畫廊設置
  useEffect(() => {
    const fetchGallerySettings = async () => {
      try {
        const response = await api.get('/settings/home-components');
        if (response.data && response.data.circularGallery) {
          // 確保每個項目都有 url 字段
          if (response.data.circularGallery.items) {
            response.data.circularGallery.items = response.data.circularGallery.items.map(item => ({
              ...item,
              url: item.url || ''
            }));
          }
          
          // 確保有深色主題文字顏色設置
          if (!response.data.circularGallery.darkTextColor) {
            response.data.circularGallery.darkTextColor = "#e0e0e0";
          }
          
          setGallerySettings(response.data.circularGallery);
        }
      } catch (error) {
        console.error('獲取圓形畫廊設置失敗:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGallerySettings();
  }, []);

  // 添加一個useLayoutEffect鉤子來在組件加載時禁用滾動條，卸載時恢復
  useLayoutEffect(() => {
    // 保存原始的overflow值
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // 禁用滾動條
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    document.documentElement.classList.add('h-screen', 'overflow-hidden');
    
    // 清理函數 - 組件卸載時恢復滾動條
    return () => {
      document.body.style.overflow = originalStyle;
      document.documentElement.style.overflow = originalStyle;
      document.documentElement.classList.remove('h-screen', 'overflow-hidden');
    };
  }, []);

  return (
    <BackgroundEffect direction="diagonal" speed={0.5}>
      <div className="h-screen max-h-screen overflow-hidden flex items-start justify-center pt-10"
           style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
        <div className="container mx-auto px-4 flex flex-col items-center h-full overflow-hidden pt-0 mt-0">
          <BlurText
            text="哥布林。團結。強大"
            delay={50}
            animateBy="letters"
            direction="top"
            onAnimationComplete={handleAnimationComplete}
            className="text-3xl font-bold mb-2 text-zinc-800 dark:text-zinc-300 overflow-hidden mt-0 pt-0"
          />
          
          {/* CircularGallery組件 */}
          {!loading && gallerySettings.enabled && (
            <div style={{ 
              height: 'calc(100vh - 240px)', 
              position: 'relative', 
              width: '100%', 
              maxHeight: '480px',
              overflow: 'hidden',
              marginTop: '-10px'
            }}>
              <CircularGallery 
                bend={gallerySettings.bend} 
                textColor={gallerySettings.textColor}
                darkTextColor={gallerySettings.darkTextColor}
                borderRadius={gallerySettings.borderRadius} 
                font={gallerySettings.font}
                items={gallerySettings.items}
                autoRotate={gallerySettings.autoRotate}
                rotationSpeed={gallerySettings.rotationSpeed}
                isDarkMode={isDarkMode}
              />
            </div>
          )}
        </div>
      </div>
    </BackgroundEffect>
  );
}

export default Home; 