import React, { useState, useEffect } from 'react';
import '../styles/AgeVerificationPopup.css';
import StarBorder from './StarBorder';

const AgeVerificationPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // 檢查是否已經顯示過彈窗
    const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    
    // 如果沒有顯示過彈窗，則顯示彈窗
    if (!hasSeenPopup) {
      setIsVisible(true);
      // 添加模糊效果
      document.body.classList.add('popup-active');
    }
  }, []);

  const handleConfirm = () => {
    setIsVisible(false);
    // 設置本地存儲，標記用戶已經看過彈窗
    localStorage.setItem('hasSeenPopup', 'true');
    // 移除模糊效果
    document.body.classList.remove('popup-active');
  };

  // 如果彈窗不可見，則不渲染任何內容
  if (!isVisible) return null;

  return (
    <div className="age-popup-overlay">
      <StarBorder
        as="div"
        className="age-popup-star-border"
        color="#00c8ff"
        speed="5s"
      >
        <div className="age-popup-container">
          <div className="age-popup-content">
            <h2 className="age-popup-title">網路內容分級</h2>
            
            <p>依『網際網路分級辦法』</p>
            <p>本站分類為限制級<span role="img" aria-label="18+" className="age-emoji">🔞</span></p>
            <p>限定為年滿18歲</p>
            <p>已具有完整行為能力，</p>
            <p>且接受本站內影音內容及所有條款</p>
            <p>方可進行瀏覽。</p>
            <p>建議您可進行網路分級基金會</p>
            <p>TICRF分級服務的設定。</p>
            <p>依網路內容分級法，</p>
            <p>未滿18歲不得瀏覽。</p>
            
            <button 
              className="age-confirm-button"
              onClick={handleConfirm}
            >
              已年滿18歲 繼續瀏覽
            </button>
          </div>
        </div>
      </StarBorder>
    </div>
  );
};

export default AgeVerificationPopup; 