import React, { useState, useEffect } from 'react';
import BackgroundEffect from '../components/BackgroundEffect';
import { motion } from 'framer-motion';
import { API_DOMAIN } from '../utils/api';

function Contact() {
  const [links, setLinks] = useState({
    tg: '#',
    line: '#'
  });
  
  const [contactSettings, setContactSettings] = useState({
    faqs: [],
    businessHours: {
      weekdays: '',
      customerService: ''
    }
  });
  
  const [activeQuestion, setActiveQuestion] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 獲取客服連結
        const linksResponse = await fetch(`${API_DOMAIN}/api/settings/links`);
        const linksData = await linksResponse.json();
        setLinks(linksData);
        
        // 獲取聯絡頁面設置
        const contactResponse = await fetch(`${API_DOMAIN}/api/settings/contact`);
        const contactData = await contactResponse.json();
        
        // 確保contactData包含所有必要的屬性
        const safeContactData = {
          faqs: Array.isArray(contactData.faqs) ? contactData.faqs : [],
          businessHours: {
            weekdays: contactData.businessHours?.weekdays || '',
            customerService: contactData.businessHours?.customerService || ''
          }
        };
        
        setContactSettings(safeContactData);
      } catch (error) {
        console.error('獲取數據失敗:', error);
      }
    };
    fetchData();
  }, []);

  const toggleQuestion = (index) => {
    if (activeQuestion === index) {
      setActiveQuestion(null);
    } else {
      setActiveQuestion(index);
    }
  };

  return (
    <BackgroundEffect direction="up" speed={0.4}>
      <div className="min-h-screen py-12 px-4">
        <div className="container mx-auto max-w-4xl">
          <div className="bg-white/80 dark:bg-zinc-800/80 rounded-lg shadow-card p-8 mb-8">
            <h1 className="text-3xl font-bold mb-6 text-zinc-800 dark:text-zinc-200 border-b-4 border-blue-500 dark:border-blue-400 pb-2 inline-block relative">
              聯絡我們
              <span className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500"></span>
            </h1>            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <a 
                href={links.tg}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white px-6 py-4 
                  rounded-lg transition-all duration-300 transform hover:scale-105 text-center"
              >
                <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.562 8.248-1.97 9.296c-.146.658-.537.818-1.084.51l-3-2.21-1.446 1.394c-.16.16-.295.295-.605.295l.213-3.053 5.56-5.023c.242-.213-.054-.334-.373-.121l-6.871 4.326-2.962-.924c-.643-.204-.657-.643.136-.953l11.57-4.461c.534-.197 1.001.13.832.924z"/>
                </svg>
                Telegram 客服
              </a>

              <a 
                href={links.line}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white px-6 py-4 
                  rounded-lg transition-all duration-300 transform hover:scale-105 text-center"
              >
                <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="M272.1 204.2v71.1c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.1 0-2.1-.6-2.6-1.3l-32.6-44v42.2c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.8 0-3.2-1.4-3.2-3.2v-71.1c0-1.8 1.4-3.2 3.2-3.2H219c1.1 0 2.1.6 2.6 1.3l32.6 44v-42.2c0-1.8 1.4-3.2 3.2-3.2h11.4c1.8-.1 3.3 1.4 3.3 3.1zm-82-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 1.8 1.4 3.2 3.2 3.2h11.4c1.8 0 3.2-1.4 3.2-3.2v-71.1c0-1.7-1.4-3.2-3.2-3.2zm-27.5 59.6h-31.1v-56.4c0-1.8-1.4-3.2-3.2-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 .9.3 1.6.9 2.2.6.5 1.3.9 2.2.9h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.7-1.4-3.2-3.1-3.2zM332.1 201h-45.7c-1.7 0-3.2 1.4-3.2 3.2v71.1c0 1.7 1.4 3.2 3.2 3.2h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2V234c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2v-11.4c-.1-1.7-1.5-3.2-3.2-3.2zM448 113.7V399c-.1 44.8-36.8 81.1-81.7 81H81c-44.8-.1-81.1-36.9-81-81.7V113c.1-44.8 36.9-81.1 81.7-81H367c44.8.1 81.1 36.8 81 81.7zm-61.6 122.6c0-73-73.2-132.4-163.1-132.4-89.9 0-163.1 59.4-163.1 132.4 0 65.4 58 120.2 136.4 130.6 19.1 4.1 16.9 11.1 12.6 36.8-.7 4.1-3.3 16.1 14.1 8.8 17.4-7.3 93.9-55.3 128.2-94.7 23.6-26 34.9-52.3 34.9-81.5z"/>
                </svg>
                LINE 客服
              </a>
            </div>
            
            <div className="mt-12">
              <h2 className="text-3xl font-bold mb-6 text-zinc-800 dark:text-zinc-200 border-b-4 border-blue-500 dark:border-blue-400 pb-2 inline-block relative">
                常見問題
                <span className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500"></span>
              </h2>
              
              <div className="space-y-4">
                {contactSettings.faqs && contactSettings.faqs.length > 0 ? (
                  contactSettings.faqs.map((faq, index) => (
                    <div 
                      key={index} 
                      className="border border-zinc-200 dark:border-zinc-700 rounded-lg overflow-hidden"
                    >
                      <button
                        className="w-full px-6 py-4 text-left bg-zinc-100/50 dark:bg-zinc-700/50 hover:bg-zinc-200/50 dark:hover:bg-zinc-600/50 transition-colors flex justify-between items-center"
                        onClick={() => toggleQuestion(index)}
                      >
                        <span className="font-semibold text-lg text-zinc-800 dark:text-zinc-100 flex items-center">
                          <span className="text-blue-500 dark:text-blue-400 mr-2">Q:</span>
                          {faq.question}
                        </span>
                        <svg 
                          className={`w-5 h-5 transform transition-transform ${activeQuestion === index ? 'rotate-180' : ''}`} 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24" 
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                        </svg>
                      </button>
                      
                      <motion.div 
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ 
                          height: activeQuestion === index ? 'auto' : 0,
                          opacity: activeQuestion === index ? 1 : 0
                        }}
                        transition={{ duration: 0.3 }}
                        className="overflow-hidden"
                      >
                        <div className="px-6 py-4 bg-white/50 dark:bg-zinc-800/50 text-zinc-600 dark:text-zinc-400 border-l-4 border-blue-400 dark:border-blue-500">
                          <div className="flex">
                            <span className="text-blue-500 dark:text-blue-400 font-semibold mr-2">A:</span>
                            <div>{faq.answer}</div>
                          </div>
                        </div>
                      </motion.div>
                    </div>
                  ))
                ) : (
                  <p className="text-zinc-500 dark:text-zinc-400 text-center">暫無常見問題</p>
                )}
              </div>
            </div>
            
            <div className="mt-12 p-6 bg-zinc-100/50 dark:bg-zinc-700/50 rounded-lg">
              <h3 className="text-xl font-bold mb-4 text-zinc-800 dark:text-zinc-200 border-b-4 border-blue-500 dark:border-blue-400 pb-2 inline-block relative">
                營業時間
                <span className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500"></span>
              </h3>
              <p className="text-zinc-600 dark:text-zinc-400 mb-2">{contactSettings.businessHours?.weekdays || '暫無營業時間信息'}</p>
              <p className="text-zinc-600 dark:text-zinc-400">{contactSettings.businessHours?.customerService || '暫無客服時間信息'}</p>
            </div>
          </div>
        </div>
      </div>
    </BackgroundEffect>
  );
}

export default Contact; 