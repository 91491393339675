import React, { useState, useEffect, useCallback } from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import { toast } from 'react-hot-toast';
import { API_DOMAIN } from '../../utils/api';

function Dashboard() {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('links');
  const [formData, setFormData] = useState({
    tg: '',
    line: ''
  });
  const [contactData, setContactData] = useState({
    faqs: [
      { question: '', answer: '' }
    ],
    businessHours: {
      weekdays: '',
      customerService: ''
    }
  });

  // 跑馬燈設置狀態
  const [marqueeData, setMarqueeData] = useState({
    messages: [
      { id: 1, content: '', active: true }
    ],
    speed: 20,
    autoplay: true
  });

  const fetchLinks = async () => {
    try {
      console.log('發送 GET 請求');
      const response = await fetch(`${API_DOMAIN}/api/settings/links`);
      console.log('GET 響應狀態:', response.status);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('GET 響應數據:', data);
      setFormData(data);
    } catch (error) {
      console.error('獲取連結失敗:', error);
      toast.error('獲取設置失敗');
    }
  };

  const fetchContactSettings = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/api/settings/contact`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setContactData(data);
    } catch (error) {
      console.error('獲取聯絡頁面設置失敗:', error);
      toast.error('獲取聯絡頁面設置失敗');
    }
  };

  // 獲取跑馬燈設置
  const fetchMarqueeSettings = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/api/settings/marquee`);
      
      if (!response.ok) {
        // 如果API尚未實現，使用默認數據
        if (response.status === 404) {
          setMarqueeData({
            messages: [
              { id: 1, content: '🔥 熱門消息：本站新增多名優質妹妹，歡迎預約！', active: true },
              { id: 2, content: '📢 新客驗證：新加入的哥布林必須驗證！', active: true },
              { id: 3, content: '🌟 營業公告：每週一至週日營業時間 10:00-24:00', active: true }
            ],
            speed: 20,
            autoplay: true
          });
          return;
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setMarqueeData(data);
    } catch (error) {
      console.error('獲取跑馬燈設置失敗:', error);
      // 使用默認數據
      setMarqueeData({
        messages: [
          { id: 1, content: '🔥 熱門消息：本站新增多名優質妹妹，歡迎預約！', active: true },
          { id: 2, content: '📢 新客驗證：新加入的哥布林必須驗證！', active: true },
          { id: 3, content: '🌟 營業公告：每週一至週日營業時間 10:00-24:00', active: true }
        ],
        speed: 20,
        autoplay: true
      });
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setIsConfirmOpen(true);
  };

  const handleConfirm = useCallback(async () => {
    setIsConfirmOpen(false);
    
    try {
      let response;
      
      if (activeTab === 'links') {
        response = await fetch(`${API_DOMAIN}/api/settings/links`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Admin-Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          },
          body: JSON.stringify(formData)
        });
      } else if (activeTab === 'contact') {
        response = await fetch(`${API_DOMAIN}/api/settings/contact`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Admin-Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          },
          body: JSON.stringify(contactData)
        });
      } else if (activeTab === 'marquee') {
        response = await fetch(`${API_DOMAIN}/api/settings/marquee`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Admin-Authorization': `Bearer ${localStorage.getItem('adminToken')}`
          },
          body: JSON.stringify(marqueeData)
        });
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toast.success('設置已保存');
    } catch (error) {
      console.error('保存設置失敗:', error);
      toast.error('保存設置失敗');
    }
  }, [activeTab, formData, contactData, marqueeData]);

  // 添加FAQ
  const addFaq = () => {
    setContactData({
      ...contactData,
      faqs: [...contactData.faqs, { question: '', answer: '' }]
    });
  };

  // 刪除FAQ
  const removeFaq = (index) => {
    // 確保至少保留一個FAQ
    if (contactData.faqs.length <= 1) {
      setContactData({
        ...contactData,
        faqs: [{ question: '', answer: '' }]
      });
      return;
    }
    
    const newFaqs = [...contactData.faqs];
    newFaqs.splice(index, 1);
    setContactData({
      ...contactData,
      faqs: newFaqs
    });
  };

  // 更新FAQ
  const updateFaq = (index, field, value) => {
    const newFaqs = [...contactData.faqs];
    if (newFaqs[index]) {
      newFaqs[index][field] = value;
      setContactData({
        ...contactData,
        faqs: newFaqs
      });
    }
  };

  // 更新營業時間
  const updateBusinessHours = (field, value) => {
    setContactData(prev => ({
      ...prev,
      businessHours: {
        ...prev.businessHours,
        [field]: value
      }
    }));
  };

  // 添加跑馬燈消息
  const addMarqueeMessage = () => {
    setMarqueeData(prev => ({
      ...prev,
      messages: [
        ...prev.messages,
        { id: Date.now(), content: '', active: true }
      ]
    }));
  };

  // 移除跑馬燈消息
  const removeMarqueeMessage = (id) => {
    setMarqueeData(prev => ({
      ...prev,
      messages: prev.messages.filter(message => message.id !== id)
    }));
  };

  // 更新跑馬燈消息
  const updateMarqueeMessage = (id, field, value) => {
    setMarqueeData(prev => ({
      ...prev,
      messages: prev.messages.map(message => 
        message.id === id ? { ...message, [field]: value } : message
      )
    }));
  };

  // 更新跑馬燈設置
  const updateMarqueeSettings = (field, value) => {
    setMarqueeData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // 保存跑馬燈設置
  const saveMarqueeSettings = async () => {
    try {
      console.log('正在保存跑馬燈設置...');
      console.log('發送數據:', JSON.stringify(marqueeData, null, 2));
      
      const response = await fetch(`${API_DOMAIN}/api/settings/marquee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Admin-Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        },
        body: JSON.stringify(marqueeData)
      });

      console.log('響應狀態:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('錯誤響應內容:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      console.log('響應數據:', data);
      
      toast.success('跑馬燈設置已保存');
    } catch (error) {
      console.error('保存跑馬燈設置失敗:', error);
      toast.error(`保存跑馬燈設置失敗: ${error.message}`);
    }
  };

  // 添加鍵盤事件監聽
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (isConfirmOpen && e.key === 'Enter') {
        handleConfirm();
      }
    };

    if (isConfirmOpen) {
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isConfirmOpen, handleConfirm]);

  useEffect(() => {
    fetchLinks();
    fetchContactSettings();
    fetchMarqueeSettings();
  }, []);

  return (
    <AdminLayout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-6">儀表板</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="bg-blue-100 p-4 rounded-lg">
            <h3 className="font-bold">總作品數</h3>
            <p className="text-2xl">0</p>
          </div>
          <div className="bg-green-100 p-4 rounded-lg">
            <h3 className="font-bold">本月訪問量</h3>
            <p className="text-2xl">0</p>
          </div>
          <div className="bg-yellow-100 p-4 rounded-lg">
            <h3 className="font-bold">待處理事項</h3>
            <p className="text-2xl">0</p>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6 mb-6 mt-6">
          <div className="flex border-b mb-6">
            <button 
              className={`px-4 py-2 ${activeTab === 'links' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('links')}
            >
              客服連結設置
            </button>
            <button 
              className={`px-4 py-2 ${activeTab === 'contact' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('contact')}
            >
              聯絡頁面設置
            </button>
            <button 
              className={`px-4 py-2 ${activeTab === 'marquee' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('marquee')}
            >
              跑馬燈設置
            </button>
          </div>

          {activeTab === 'links' && (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-2">
                  TG客服連結
                </label>
                <input
                  type="text"
                  value={formData.tg}
                  onChange={(e) => setFormData(prev => ({ ...prev, tg: e.target.value }))}
                  className="w-full p-2 border rounded"
                  placeholder="請輸入 TG 連結"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium mb-2">
                  LINE客服連結
                </label>
                <input
                  type="text"
                  value={formData.line}
                  onChange={(e) => setFormData(prev => ({ ...prev, line: e.target.value }))}
                  className="w-full p-2 border rounded"
                  placeholder="請輸入 LINE 連結"
                />
              </div>

              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
              >
                保存設置
              </button>
            </form>
          )}

          {activeTab === 'contact' && contactData && (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-4">常見問題設置</h3>
                
                {contactData.faqs && contactData.faqs.map((faq, index) => (
                  <div key={index} className="mb-6 p-4 border rounded-lg bg-gray-50">
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="font-medium">問題 #{index + 1}</h4>
                      <button
                        type="button"
                        onClick={() => removeFaq(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        刪除
                      </button>
                    </div>
                    
                    <div className="mb-3">
                      <label className="block text-sm font-medium mb-1">問題</label>
                      <input
                        type="text"
                        value={faq.question || ''}
                        onChange={(e) => updateFaq(index, 'question', e.target.value)}
                        className="w-full p-2 border rounded"
                        placeholder="輸入問題"
                      />
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium mb-1">回答</label>
                      <textarea
                        value={faq.answer || ''}
                        onChange={(e) => updateFaq(index, 'answer', e.target.value)}
                        className="w-full p-2 border rounded"
                        rows="3"
                        placeholder="輸入回答"
                      ></textarea>
                    </div>
                  </div>
                ))}
                
                <button
                  type="button"
                  onClick={addFaq}
                  className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-200"
                >
                  添加問題
                </button>
              </div>
              
              <div className="mt-8">
                <h3 className="text-lg font-medium mb-4">營業時間設置</h3>
                
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">營業時間</label>
                  <input
                    type="text"
                    value={contactData.businessHours?.weekdays || ''}
                    onChange={(e) => updateBusinessHours('weekdays', e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="例如：週一至週日: 12:00 - 00:00"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium mb-1">客服時間</label>
                  <input
                    type="text"
                    value={contactData.businessHours?.customerService || ''}
                    onChange={(e) => updateBusinessHours('customerService', e.target.value)}
                    className="w-full p-2 border rounded"
                    placeholder="例如：客服服務時間: 10:00 - 02:00"
                  />
                </div>
              </div>
              
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
              >
                保存設置
              </button>
            </form>
          )}

          {activeTab === 'marquee' && (
            <div className="space-y-6">
              <div className="bg-blue-50 p-4 rounded-lg mb-4">
                <h3 className="text-lg font-medium text-blue-800 mb-2">跑馬燈設置說明</h3>
                <p className="text-blue-700">
                  在此設置網站首頁的跑馬燈公告內容。您可以添加多條消息，並設置動畫速度。
                  <br />
                  使用表情符號可以增加視覺效果，例如：🔥 📢 🌟 💖 ⚠️ 🎉
                </p>
              </div>

              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium">跑馬燈消息</h3>
                  <button
                    type="button"
                    onClick={addMarqueeMessage}
                    className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 transition-colors duration-200 text-sm flex items-center"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                    添加消息
                  </button>
                </div>

                {marqueeData.messages.map((message, index) => (
                  <div key={message.id} className="bg-white p-4 rounded-lg border shadow-sm">
                    <div className="flex justify-between items-start mb-2">
                      <h4 className="font-medium">消息 #{index + 1}</h4>
                      <button
                        type="button"
                        onClick={() => removeMarqueeMessage(message.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                    <div className="space-y-3">
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          消息內容
                        </label>
                        <input
                          type="text"
                          value={message.content}
                          onChange={(e) => updateMarqueeMessage(message.id, 'content', e.target.value)}
                          className="w-full p-2 border rounded"
                          placeholder="輸入跑馬燈消息內容"
                        />
                      </div>
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id={`active-${message.id}`}
                          checked={message.active}
                          onChange={(e) => updateMarqueeMessage(message.id, 'active', e.target.checked)}
                          className="mr-2"
                        />
                        <label htmlFor={`active-${message.id}`} className="text-sm">
                          啟用此消息
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="bg-white p-4 rounded-lg border shadow-sm">
                <h3 className="font-medium mb-3">動畫設置</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">
                      滾動速度 (秒)
                    </label>
                    <input
                      type="number"
                      min="5"
                      max="60"
                      value={marqueeData.speed}
                      onChange={(e) => updateMarqueeSettings('speed', parseInt(e.target.value))}
                      className="w-full p-2 border rounded"
                    />
                    <p className="text-sm text-gray-500 mt-1">
                      數值越小滾動越快，建議值：15-30
                    </p>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="autoplay"
                      checked={marqueeData.autoplay}
                      onChange={(e) => updateMarqueeSettings('autoplay', e.target.checked)}
                      className="mr-2"
                    />
                    <label htmlFor="autoplay" className="text-sm">
                      自動播放
                    </label>
                  </div>
                </div>
              </div>

              <div className="bg-yellow-50 p-4 rounded-lg mb-4">
                <h3 className="text-lg font-medium text-yellow-800 mb-2">預覽</h3>
                <div className="bg-gradient-to-r from-rose-500/90 to-purple-500/90 p-2 rounded-lg overflow-hidden border border-rose-300">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 px-2 py-1 bg-white/20 rounded-md mr-3">
                      <span className="text-white font-bold text-sm">最新公告</span>
                    </div>
                    <div className="overflow-hidden whitespace-nowrap flex-1">
                      {marqueeData.autoplay ? (
                        <div className="inline-block text-white font-medium" style={{
                          animation: `marquee ${marqueeData.speed}s linear infinite`
                        }}>
                          {marqueeData.messages.filter(m => m.active).map((message, index) => (
                            <span key={index}>{message.content || '(空白消息)'} &nbsp;&nbsp;&nbsp; </span>
                          ))}
                        </div>
                      ) : (
                        <div className="inline-block text-white font-medium">
                          {marqueeData.messages.filter(m => m.active).map((message, index) => (
                            <span key={index}>{message.content || '(空白消息)'} &nbsp;&nbsp;&nbsp; </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="button"
                onClick={saveMarqueeSettings}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200"
              >
                保存設置
              </button>
            </div>
          )}
        </div>

        {/* 確認對話框 */}
        {isConfirmOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 confirm-dialog-backdrop">
            <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 confirm-dialog">
              <h3 className="text-xl font-bold mb-4">確認更新</h3>
              <p className="text-gray-600 mb-6">
                確定要更新{activeTab === 'links' ? '客服連結' : activeTab === 'contact' ? '聯絡頁面' : '跑馬燈設置'}設置嗎？
                <br />
                <span className="text-sm text-gray-500">（按下 Enter 鍵確認更新）</span>
              </p>
              <div className="flex justify-end space-x-4 confirm-dialog-buttons">
                <button
                  onClick={() => setIsConfirmOpen(false)}
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors duration-200"
                >
                  取消
                </button>
                <button
                  onClick={handleConfirm}
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-200 confirm-btn"
                >
                  確定更新
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
}

export default Dashboard; 